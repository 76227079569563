// Blog nav
.blog-nav {
  padding: 20px 0;
  border-bottom: 2px solid $brand-primary;

  // .btn-lg
  .btn-home {
    float: left;
    text-decoration: none;
    padding-left: 0;
    padding-right: 0;

    &.btn-svg {
      margin: 0;
      padding: 0;
    }

    i {
      vertical-align: bottom;
    }

    > svg {
      width: 50px;
      height: 50px;
      vertical-align: middle;
      transition: all 200ms ease-in-out;

      // path {
      //   fill: $link-color;
      // }
    }

    span {
      font-weight: 700;
    }

    &:hover,
    &:focus {

      > svg {
        opacity: 0.6;
      }

      span {
        text-decoration: underline;
      }
    }
  }

  ul {
    float: right;
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      float: left;

      .btn {
        padding-left: 0;
        padding-right: 0;

        &:hover,
        &:focus {

          > svg {
            transform: translate(0);
            opacity: 0.6;
          }
        }
      }
    }
  }
}




// Tabs
.nav-tabs-primary {

  > li {

    > a {
      font-family: $font-family-sans-alt;
      font-weight: 800;
      font-size: 27px;
      line-height: 30px;
      margin-right: 2px;
      padding: 25px 20px;
      color: $brand-primary;
      background-color: #EFEFEF;
      transition: all 200ms ease-in-out, padding 0, border 0;

      .underline-effect {
        position: relative;
        display: inline-block;

        &:after {
          content: '';
          display: block;
          width: 100%;
          height: 3px;
          position: absolute;
          bottom: -6px;
          background-color: $link-color;
          transform: translate(100%);
          opacity: 0;
          transition: all 200ms ease-in-out;
        }
      }

      &:hover,
      &:focus {
        color: $brand-primary;
        background-color: #EFEFEF;

        .underline-effect {

          &:after {
            transform: translate(0);
            opacity: 1;
          }
        }
      }
    }

    &.active > a {
      &,
      &:hover,
      &:focus {
        padding-bottom: 18px;
        border-bottom: 7px solid $brand-primary;
        background-color: #FFF;
        color: $brand-primary;
        cursor: default;

        .underline-effect {

          &:after {
            display: none;
          }
        }
      }
    }
  }
}


// nav-links
// .nav-links {
  // @extend .clearfix;
  // padding: 30px 0;

  // a {
  //   @extend .btn;
  //   @extend .btn-md;
  //   @extend .btn-primary;
  //   text-transform: none;
  // }

  // .nav-previous {
  //   float: left;

  //   a {

  //     &:before {
  //       content: '\00ab';
  //       margin-right: 5px;
  //     }
  //   }
  // }

  // .nav-next {
  //   float: right;

  //   a {

  //     &:after {
  //       content: '\00bb';
  //       margin-left: 5px;
  //     }
  //   }
  // }

  // .nav-previous,
  // .nav-next {

  //   @media screen and (max-width: $screen-xs-max) {
  //     float: none;

  //     &:last-child {
  //       margin-top: 15px;
  //     }

  //     a {
  //       display: block;
  //     }
  //   }
  // }
// } // .nav-links




// Post pagination
.nav-buttons {
  margin: $line-height-computed 0;
  text-align: center;

  @media screen and (min-width: $screen-md-min) {
    text-align: right;
  }

  .btn {
    padding: 7px 12px;
    border: none;
    background-color: $gray-lighter;
    color: $text-color;
    margin-right: 10px;

    @media screen and (min-width: $screen-sm-min) {
      padding: 9px 15px 12px;
      font-size: 30px;
      line-height: 1.3333333;
      margin-right: 15px;
    }

    span {
      @extend .text-xsmall;
      vertical-align: inherit;
    }

    &:focus {
      outline: none;
    }

    &.btn-home {
      width: 40px;

      @media screen and (min-width: $screen-sm-min) {
        width: 60px;

        .fa-home {
          font-size: 75%;
        }
      }
    }
  }

  .btn-prev {
    margin-right: 10px;

    @media screen and (min-width: $screen-md-min) {
      margin-right: 15px;
    }
  }
} // .nav-buttons
