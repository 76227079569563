// Noticias
// --------------------------------------------------


// ----------------------------------------------------------------------------
// Module Standard
// ----------------------------------------------------------------------------
.module-standard {
  position: relative;
  margin-bottom: 30px;
  transition: all 300ms $bezier;

  .module-image {
    position: relative;
    overflow: hidden;
    height: 0 !important;
    padding-bottom: (100% / 16) * 9; // 5:4

    .module-image-img {
      @include absolute-full();
      background-size: cover;
      background-position: 50% 50%;
      background-repeat: no-repeat;
      background-color: $gris-blanco;
      transition: all 300ms $bezier;
    }
  } // .module-image


  .module-type {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 10px;
    background-color: $brand-primary;

    > p {
      position: absolute;
      top: 0;
      left: 0;
      margin: 0;
      padding: 10px 15px;
      font-size: 11px;
      font-weight: 800;
      text-transform: uppercase;
      letter-spacing: 0.05em;
      background-color: $brand-primary;
      color: #fff;
    }
  } // .module-type


  .module-content {
    position: relative;
    margin-top: 20px;
    padding-bottom: 60px; // .btn-xl
    border-top: 1px solid #000;

    .entry-title {
      margin: 20px 0;
      font-size: $font-size-h3;
      font-weight: 700;
      color: #000;

      @media screen and (min-width: 481px) {
        font-size: $font-size-h2;
      }

      // ellipsis
      &.ellipsis {
        overflow: hidden;
        max-height: ( ($font-size-h3 * $headings-line-height) * 4 );

        @media screen and (min-width: 481px) {
          max-height: ( ($font-size-h2 * $headings-line-height) * 4 );
        }
      }
    } // .entry-title

    .btn-block {
      position: absolute;
      z-index: 1;
      bottom: 0;

      + .tooltip {
        width: 100%;

        &.in {
          opacity: 1;
        }

        .tooltip-inner {
          max-width: inherit;
          padding: 10px 15px;
          text-align: left;
        }
      }
    }
  } // .module-content


  .module-link {
    @include absolute-full();
    outline: 0;
  } // .module-link


  // .module:hover
  &:hover,
  &:focus {

    .module-image {

      .module-image-img {
        opacity: 0.5;
      }
    } // .module-image
  } // :hover, :focus
} // .module-standard




// ----------------------------------------------------------------------------
// Module Horizontal
// ----------------------------------------------------------------------------

.module-horizontal {
  position: relative;
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 30px 0;
  border-bottom: 1px solid $gris;
  transition: all 300ms $bezier;

  @media screen and (min-width: $screen-sm-min) {
    flex-direction: row;
    align-items: center;
  }

  > * {
    flex: 0 1 auto;
  }

  .module-image {

    @media screen and (min-width: $screen-sm-min) {
      flex: 0 0 135px;
    }

    img {
      width: 100%;
      height: auto;
      transition: all 300ms $bezier;

      @media screen and (min-width: $screen-sm-min) {
        width: 135px;
        height: auto;
      }
    }
  } // .module-image

  .module-content {
    flex: 1 1 auto;
    padding: 15px 0 0;

    @media screen and (min-width: $screen-sm-min) {
      padding: 0 15px 0 30px;
    }

    > * {
      margin: 0;
    }

    .entry-title {
      font-size: $font-size-h5;

      &.ellipsis {
        overflow: hidden;
        max-height: ( ($font-size-h5 * $headings-line-height) * 2 );
      }

      + .entry-excerpt {
        margin-top: 10px;
      }
    }

    // PHP comentado.
    // .entry-meta {
    //   font-size: $font-size-medium;
    //   color: $brand-primary;
    //   font-weight: 800;

    //   .divider {
    //     font-weight: 600;
    //     color: $text-color;

    //     &:last-child {
    //       display: none;
    //     }
    //   }

    //   + .entry-excerpt {
    //     margin-top: 10px;
    //   }
    // } // .entry-meta

    .entry-excerpt {
      margin-top: 10px;
      font-size: $font-size-medium;

      &.ellipsis {

        > p {
          margin-bottom: 0;
          overflow: hidden;
          max-height: ( ($font-size-medium * $line-height-base) * 2 );
        }
      }
    }

    .entry-link {
      position: relative;
      z-index: 1;
    }
  } // .module-content

  .module-arrow {
    padding: 0 15px;
    color: $gris;
    transform: translateX(0);
    transition: all 300ms $bezier;
  }

  .module-link {
    @include absolute-full();
  }

  &:hover,
  &:focus {
    // box-shadow: $shadow-1;
    border-color: $brand-primary;

    .module-image {

      img {
        opacity: 0.5;
      }
    } // .module-image

    .module-content {

      .entry-title {
        color: #000;
      }

      .entry-excerpt {
        color: #000;

        > p {}
      }
    } // .module-content

    .module-arrow {
      color: $brand-primary;
      transform: translateX(15px);
    }

    .module-link {}
  } // :hover
} // .module-horizontal





// ----------------------------------------------------------------------------
// Module Historia (Horizontal)
// ----------------------------------------------------------------------------

.module-historia {

  .module-logo {
    order: -1;
    margin: 0 0 15px;

    @media screen and (min-width: $screen-sm-min) {
      flex: 0 0 135px;
      order: inherit;
      margin: 0 0 0 30px;
      text-align: center;
    }

    img {
      width: auto;
      height: auto;
      max-width: 135px;
      max-height: 76px;
    }
  } // .module-logo

  .module-content {

    .entry-title {
      font-size: $font-size-h5;

      &.ellipsis {
        overflow: hidden;
        max-height: ( ($font-size-h5 * $headings-line-height) * 4 );
      }
    }
  } // .module-content
} // .module-historia





// ----------------------------------------------------------------------------
// Single Posts
// ----------------------------------------------------------------------------
.single {

  .main {
    min-height: 100vh;
  }
} // .single





// ----------------------------------------------------------------------------
// Archive Proyecto
// ----------------------------------------------------------------------------
.post-type-archive-codespa_proyecto {

  .page-header {
    margin: -15px 0 15px;
    border: none;
    text-align: center;
    min-height: 30px;

    @media screen and (min-width: 481px) {
      min-height: 44px;
    }

    @media screen and (min-width: $screen-sm-min) {
      min-height: 55px;
    }

    @media screen and (min-width: $screen-md-min) {
      text-align: left;
    }

    @media screen and (min-width: $screen-lg-min) {
      min-height: 66px;
    }

    .page-title {
      @include responsive-heading($font-size-h1);
      margin: 0;
      letter-spacing: -0.025em; // .kerning-2

      @media screen and (max-width: 480px) {
        font-size: 24px;
      }

      // @media screen and (min-width: $screen-md-min) {
      //   margin-bottom: 15px;
      // }
    }
  }

  .facetwp-facet-proyecto_mapa {
    // display: none;
    margin-bottom: 30px;

    // @media screen and (min-width: 480px) {
    //   display: block;
    // }
  }

  .main-loop {
    position: relative;

    .sorter {
      position: absolute;
      top: 7px;
      right: 0;

      @media screen and (max-width: $screen-sm-max) {
        position: relative;
        top: 0;
        margin: -20px 0 30px;
        padding: 0 15px;
      }

      .facetwp-sort {
        min-width: 160px;

        @media screen and (max-width: $screen-sm-max) {
          width: 100%;
        }
      }

      // Dropdows
      > .select2-container--bootstrap {
        right: 15px;
      }

      .select2-selection {
        font-size: 18px;
        font-weight: 700;

        @media screen and (min-width: $screen-md-min) {
          height: 30px;
          padding: 5px 30px 5px 20px;
          font-size: 16px;
        }
      }

      .select2-container--focus .select2-selection,
      .select2-container--open .select2-selection {
        box-shadow: none;
      }

      .select2-results__option {
        padding: 7px 20px;
        font-size: 14px;
        line-height: 16px;
        font-weight: 600;
        transition: all 300ms $bezier;

        &:first-child {
          display: none;
          font-size: 11px;
          line-height: 1.25;
          font-weight: 800;
          text-transform: uppercase;
          background-color: #fff;
          color: $text-color;

          &:hover,
          &:focus {
            text-decoration: underline;
          }
        }
      }

      .select2-selection__arrow {
        right: 16px;

        // b {
        //   border-color: $text-color transparent transparent !important;
        // }
      }
    }

    > .title-bordered-arrow {

      @media screen and (min-width: $screen-md-min) {
        padding-right: 170px;
      }
    }

    // Spinner
    .loader {
      display: none; // js facet refresh
      position: relative;

      i {
        position: absolute;
        z-index: 1;
        top: 0;
        left: 0;
        padding: 30px;
      }
    }
  }
} // .post-type-archive-codespa_proyecto





// Botones de navegación superior
%single-top-navigation {

  @media screen and (max-width: $screen-sm-max) {
    padding-top: 15px;
  }

  .nav-back {

    @media screen and (min-width: $screen-sm-min) {
      float: right;
    }

    + .post-navigation {
      margin-top: 5px;
      margin-bottom: 10px;

      @media screen and (min-width: $screen-sm-min) {
        margin-top: 0;
        margin-bottom: 15px;
      }
    }
  }

  .post-navigation {

    .nav-links {
      @extend .clearfix;

      a {
        @extend .btn;
        @extend .btn-sm;
        @extend .btn-gray;
      }

      .nav-previous,
      .nav-next {
        float: left;

        @media screen and (max-width: $screen-xs-max) {
          margin-top: 5px;
          margin-bottom: 5px;
        }
      }

      .nav-previous {
        margin-right: 10px;

        a {

          &:before {
            content: '\003C';
            margin-right: 0.5em;
          }
        }
      }

      .nav-next {

        a {

          &:after {
            content: '\003E';
            margin-left: 0.5em;
          }
        }
      }
    } // .nav-links
  } // nav.post-navigation
} // %single-top-navigation





// ----------------------------------------------------------------------------
// Single Proyecto
// ----------------------------------------------------------------------------

.single-codespa_proyecto {

  .main-content {
    @extend %single-top-navigation;

    article.codespa_proyecto {

      > header {
        margin-bottom: 30px;

        .entry-title {
          margin: 0 0 .25em; // revisar
          @include responsive-heading($font-size-h2);
        }

        .entry-meta {
          font-size: 14px;
          color: $brand-primary;

          @media screen and (min-width: $screen-sm-min) {
            font-size: 16px;
          }

          @media screen and (min-width: $screen-lg-min) {
            font-size: 18px;
          }

          a {
            font-weight: 800;
          }

          .divider {
            font-weight: 600;
            color: $text-color;

            &:last-child {
              display: none;
            }
          }
        } // .entry-meta

        .lead {}
      } // header


      .objetivos {
        margin-bottom: 30px;

        &.impulso {

          @media screen and (min-width: $screen-sm-min) {
            margin-bottom: 0;
          }

          .data {

            &:last-child {
              border-bottom: none;
            }
          }
        }

        // > .title-bordered-arrow {}

        .data {
          display: flex;
          flex-direction: column;
          align-items: center;
          padding: 15px 0;
          outline: 0;
          text-align: center;
          color: $gris-oscuro; // revisar

          @media screen and (min-width: $screen-sm-min) {
            flex-direction: row;
            padding: 10px 0;
            border-bottom: 1px solid #ddd;
            text-align: left;
          }

          > img {
            width: 60px;
            height: 60px;

            @media screen and (min-width: $screen-sm-min) {
              margin-right: 10px;
            }
          }

          > h4 {
            margin: 0;
            font-size: 12px;
            // font-weight: 700;
            text-transform: uppercase;

            @media screen and (min-width: $screen-sm-min) {
              flex: 0 0 140px;
            }

            @media screen and (min-width: $screen-lg-min) {
              flex: 0 0 160px;
            }
          }

          > .data-text {
            display: flex;
            flex-direction: column;
            max-width: 480px;

            @media screen and (min-width: $screen-sm-min) {
              flex-direction: row;
              align-items: center;
              flex: 1;
              max-width: initial;
            }

            p {
              margin: 0;

              @media screen and (min-width: $screen-sm-min) {
                flex: 1;
                padding: 0 10px;

                &:last-child {
                  padding-right: 0;
                }
              }

              &.texto-grande {
                font-size: $font-size-h2;
                line-height: 1;
                font-weight: 800;
              }

              &.texto-mediano {
                font-size: 18px;
                font-weight: 800;
              }

              &.texto-pequeno {
                font-size: 12px;
                font-weight: 600;
              }
            }
          }
        } // .data
      } // .objetivos

      .component-slider-objetivos {

        > .slider {

          @media screen and (min-width: $screen-sm-min) {
            border-bottom: none;
          }
        }

        > .dots-container {
          position: relative;

          .slick-dots {
            position: relative;
            bottom: inherit;
            margin: 30px 0;
          }
        }
      } // .component-slider-objetivos

      .entry-social {
        margin: 30px 0;
      }

      .page-content {
        padding-top: 30px;
        border-top: 1px solid $page-header-border-color;
      }
    } // article
  } // .main-content
} // .single-codespa_proyecto









// ----------------------------------------------------------------------------
// Paginador FacetWP
// ----------------------------------------------------------------------------

.facetwp-pager {
  margin: 30px 0;
  text-align: center;

  .facetwp-prev,
  .facetwp-next {

    @media screen and (max-width: $screen-xs-max) {
      display: block;
    }
  }

  .facetwp-pager-label {
    display: inline-block;
    margin-right: 12px;
  }

  .facetwp-page {
    display: inline-block;
    padding: 10px;
    margin-right: 0;
    cursor: pointer;
    text-decoration: none;
    transition: all 300ms $bezier;
    font-size: $font-size-large;
    font-weight: 900;
    text-transform: uppercase;

    &:hover,
    &:focus {
      background-color: #fff;
    }

    &.facetwp-prev,
    &.facetwp-next {

      @media screen and (max-width: $screen-xs-max) {
        display: block;
      }
    }
  }

  .facetwp-page.active {
    cursor: default;
    color: $text-color;
    font-weight: 900;

    &:hover,
    &:focus {
      background-color: transparent;
    }
  }
} // .facetwp-pager




// ----------------------------------------------------------------------------
// Template: Campañas
// ----------------------------------------------------------------------------
.page-template-template-campana,
.page-template-template-campana-donacion {

  // Sidebar order
  .main-content > .container > .row-flex {

    // Escritorio
    @media screen and (min-width: $screen-md-min) {

      > [class^="col-"] {

        &:nth-child(2) {
          order: inherit;
        }
      }
    } // @ $screen-sm-max

  } // .main-content > .container > .row-flex


  // Sidebar en contenido (Masonry)
  .sidebar-contenido {

    .widget,
    .widget-sizer {

      // 3 col
      @media screen and (min-width: $screen-lg-min) {
        width: calc((100% / 3) - 30px);
      }
    } // .widget
  } // .sidebar-contenido


  .breadcrumbs {
    display: none;
  }

  .main-content {

    @media screen and (min-width: $screen-sm-min) {
      padding-top: 0;
    }

    .section-slider-page {
      position: relative;
      margin-top: -30px;

      @media screen and (min-width: $screen-sm-min) {
        margin-top: 0;
      }

      @media screen and (min-width: $screen-md-min) {
        margin-top: -40px;
      }

      &:before {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        width: 9999px;
        height: 50%;
        background-color: $gris;
        border-bottom: 5px solid $gris-claro;
      }

      .slider-page {

        .slider-page-slide {

          .__img-replace {
            height: 0;
            padding-bottom: 100%;

            @media screen and (min-width: 480px) {
              padding-bottom: (100% / 16) * 9; // 16:9
            }

            @media screen and (min-width: $screen-sm-min) {
              padding-bottom: (100% / 20) * 9; // 20:9
            }

            @media screen and (min-width: $screen-md-min) {
              padding-bottom: (100% / 24) * 9; // 24:9
            }
          }

          .slider-page-slide-content {

            h5 {

              @media screen and (max-width: $screen-xs-max) {
                font-size: $font-size-medium;
              }
            }

            p {

              @media screen and (max-width: $screen-xs-max) {
                font-size: $font-size-small;
              }
            }
          } // .slider-page-slide-content
        } // .slider-page-slide

        + .dots-container {

          .slick-dots {
            margin-bottom: -40px;
          }
        }
      } // .slider-page

      // Botón volver
      + .nav-back {
        margin-top: -30px;
        margin-bottom: 0;
      }
    } // .section-slider-page


    // Botón volver
    .nav-back {
      margin-bottom: 15px;
    }


    .page-header {
      // padding-bottom: 0; // (($line-height-computed / 2) - 1);
      // margin: 0 0 30px; // ($line-height-computed * 2) 0 $line-height-computed;
      // // border-bottom: 1px solid $page-header-border-color;

      .page-title {
        color: $brand-primary;
      }
    }
  } // .main-content
} // .page-template-template-campana, .page-template-template-campana-donacion


.page-template-template-campana-donacion {

  .section-slider-main {


  } // .section-slider-main


  .main-content {

    @media screen and (min-width: $screen-sm-min) {
      padding-top: 30px;
    }

    .nav-back {
      margin-top: -30px;
      margin-bottom: 0;
    }

    .section-slider-page {
      margin-top: 0;

      @media screen and (min-width: $screen-sm-min) {
        margin-top: 0;
      }

      @media screen and (min-width: $screen-md-min) {
        margin-top: 0;
      }

      &:before {
        content: none;
        // display: block;
        // position: absolute;
        // top: 0;
        // left: 50%;
        // transform: translateX(-50%);
        // width: 9999px;
        // height: 50%;
        // background-color: $gris;
        // border-bottom: 5px solid $gris-claro;
      }

      .slider-page {

        + .dots-container {

          .slick-dots {
            margin-bottom: -40px;
          }
        }
      } // .slider-page
    } // .section-slider-page
  } // .main-content
} // .page-template-template-campana-donacion





// ----------------------------------------------------------------------------
// Blog
// ----------------------------------------------------------------------------

// Archivos
.blog,
.category,
.tag,
.author,
.date {

  .page-header {
    border-bottom: 5px solid $page-header-border-color;

    // .page-title {
    //   font-weight: 600;
    // }
  }
}


// Post en archivo
.blog-post {
  margin-bottom: 60px;

  > header {
    margin-bottom: 15px;
    border-bottom: 1px solid $page-header-border-color;

    .entry-title {
      margin: 0 0 15px;
      font-size: $font-size-h3;

      a {
        color: $text-color;
        transition: all 300ms $bezier;

        &:hover,
        &:focus {
          text-decoration: none;
          color: $link-color;
        }
      }
    }

    .entry-author {
      margin-bottom: 15px;
      color: $gris-medio;

      a {
        color: $text-color;
        font-weight: 800;
      }
    }
  } // header

  .entry-meta {
    margin-bottom: 15px;
    font-size: $font-size-medium;
    line-height: 1.5;
    font-weight: 700;
    color: $gris-medio;

    > p {
      display: inline;
      margin: 0;

      + p {
        margin-left: 1em;
      }

      a {
        color: $gris-medio;
        transition: all 300ms $bezier;

        &:hover,
        &:focus {
          color: $link-color;
        }
      }
    }

    .entry-date {}

    .entry-categories {}
  } // .entry-meta

  .entry-image {
    margin-bottom: 15px;

    a {
      display: block;
    }

    img {
      transition: all 300ms $bezier;
      opacity: 1;
    }

    &:hover,
    &:focus {

      img {
        opacity: 0.5;
      }
    }
  }

  .entry-social {}

  .entry-summary {}
} // .blog-post





// Ampliado Blog
.single-post {

  .main-content {
    @extend %single-top-navigation;

    article.post {

      > header {
        border-bottom: 1px solid $page-header-border-color;
        margin-bottom: 15px;

        .entry-title {
          margin: 0 0 15px;
          @include responsive-heading($font-size-h2);
        }

        // .lead {}

        .entry-author {
          margin-bottom: 15px;
          color: $gris-medio;

          a {
            color: $text-color;
            font-weight: 800;
          }
        }
      } // header

      .entry-meta {
        margin-bottom: 15px;
        font-size: $font-size-medium;
        line-height: 1.5;
        font-weight: 700;
        color: $gris-medio;

        > p {
          margin: 0;

          + p {
            margin-top: .5em;
          }

          a {
            color: $gris-medio;
            transition: all 300ms $bezier;

            &:hover,
            &:focus {
              color: $link-color;
            }
          }
        }

        .entry-date {}

        .entry-categories {}

        .entry-tags {}
      } // .entry-meta

      .entry-image {
        margin-bottom: 30px;
      }

      .entry-social {
        margin: 30px 0;
      }

      .entry-content {
        padding-top: 30px;
        border-top: 1px solid $page-header-border-color;
      }
    } // article
  } // .main-content
} // .single-post





// ----------------------------------------------------------------------------
// Publicaciones y Notas Técnicas
// ----------------------------------------------------------------------------

// Archivos
.post-type-archive-codespa_publicacion,
.post-type-archive-codespa_nota {

  .page-header {
    border-bottom: 5px solid $page-header-border-color;
  }
}


// Post en archivo
.publicacion-nota {
  margin-bottom: 60px;

  > header {
    margin-bottom: 15px;
    border-bottom: 1px solid $page-header-border-color;

    .entry-title {
      margin: 0 0 15px;
      font-size: $font-size-h3;

      a {
        color: $text-color;
        transition: all 300ms $bezier;

        &:hover,
        &:focus {
          text-decoration: none;
          color: $link-color;
        }
      }
    }
  } // header

  .entry-meta {
    margin-bottom: 15px;
    font-size: $font-size-medium;
    line-height: 1.5;
    font-weight: 700;
    color: $gris-medio;

    > p {
      display: inline;
      margin: 0;

      + p {
        margin-left: 1em;
      }

      a {
        color: $gris-medio;
        transition: all 300ms $bezier;

        &:hover,
        &:focus {
          color: $link-color;
        }
      }
    }

    .entry-date {}

    .entry-pdf {}
  } // .entry-meta

  .entry-image {
    margin-bottom: 15px;

    a {
      display: block;
    }

    img {
      transition: all 300ms $bezier;
      opacity: 1;
    }

    &:hover,
    &:focus {

      img {
        opacity: 0.5;
      }
    }
  }

  .entry-social {}

  .entry-summary {}
} // .publicacion





// Ampliado Publicacion o Nota Técnica
.single-codespa_publicacion,
.single-codespa_nota {

  .main-content {
    @extend %single-top-navigation;

    article.codespa_publicacion,
    article.codespa_nota {

      > header {
        border-bottom: 1px solid $page-header-border-color;
        margin-bottom: 15px;

        .entry-title {
          margin: 0 0 15px;
          @include responsive-heading($font-size-h2);
        }
      } // header

      .entry-meta {
        margin-bottom: 15px;
        font-size: $font-size-medium;
        line-height: 1.5;
        font-weight: 700;
        color: $gris-medio;

        > p {
          display: inline;
          margin: 0;

          + p {
            margin-left: 1em;
          }

          a {
            color: $gris-medio;
            transition: all 300ms $bezier;

            &:hover,
            &:focus {
              color: $link-color;
            }
          }
        }

        .entry-date {}

        .entry-pdf {}
      } // .entry-meta

      .entry-image {
        margin-bottom: 30px;
      }

      .entry-social {
        margin: 30px 0;
      }

      .entry-content {
        padding-top: 30px;
        border-top: 1px solid $page-header-border-color;
      }
    } // article
  } // .main-content
} // .single-codespa_publicacion, .single-codespa_nota





// ----------------------------------------------------------------------------
// Cursos
// ----------------------------------------------------------------------------

// Archivos
.post-type-archive-codespa_curso {

  .page-header {
    border-bottom: 5px solid $page-header-border-color;
  }
}


// Post en archivo
.curso {
  margin-bottom: 60px;

  > header {
    margin-bottom: 15px;
    border-bottom: 1px solid $page-header-border-color;

    .entry-title {
      margin: 0 0 15px;
      font-size: $font-size-h3;

      a {
        color: $text-color;
        transition: all 300ms $bezier;

        &:hover,
        &:focus {
          text-decoration: none;
          color: $link-color;
        }
      }
    }
  } // header

  .entry-meta {
    margin-bottom: 15px;
    font-size: $font-size-medium;
    line-height: 1.5;
    font-weight: 600;
    color: $gris-medio;

    > div {
      margin: 0;

      + div {
        margin-top: .5em;
      }

      > strong {
        font-weight: 700;
        color: $text-color;
      }

      p {
        display: inline;
        margin: 0;
      }

      a {
        color: $gris-medio;
        text-decoration: underline;
        transition: all 300ms $bezier;

        &:hover,
        &:focus {
          color: $link-color;
        }
      }
    }

    .entry-date {}

    .entry-pdf {}
  } // .entry-meta

  .entry-image {
    margin-bottom: 15px;

    a {
      display: block;
    }

    img {
      transition: all 300ms $bezier;
      opacity: 1;
    }

    &:hover,
    &:focus {

      img {
        opacity: 0.5;
      }
    }
  }

  .entry-social {}

  .entry-summary {
    padding-left: 15px;
    border-left: 1px solid $brand-primary;
  }
} // .curso





// Ampliado Curso
.single-codespa_curso {

  .main-content {
    @extend %single-top-navigation;

    article.codespa_curso {

      > header {
        border-bottom: 1px solid $page-header-border-color;
        margin-bottom: 15px;

        .entry-title {
          margin: 0 0 15px;
          @include responsive-heading($font-size-h2);
        }
      } // header

      .entry-meta {
        margin-bottom: 15px;
        font-size: $font-size-medium;
        line-height: 1.5;
        font-weight: 600;
        color: $gris-medio;

        > div {
          margin: 0;

          + div {
            margin-top: .5em;
          }

          > strong {
            font-weight: 700;
            color: $text-color;
          }

          p {
            display: inline;
            margin: 0;
          }

          a {
            color: $gris-medio;
            text-decoration: underline;
            transition: all 300ms $bezier;

            &:hover,
            &:focus {
              color: $link-color;
            }
          }
        }
      } // .entry-meta

      .entry-image {
        margin-bottom: 30px;
      }

      .entry-social {
        margin: 30px 0;
      }

      .entry-content {
        padding-top: 30px;
        border-top: 1px solid $page-header-border-color;
      }
    } // article
  } // .main-content
} // .single-codespa_curso





// ----------------------------------------------------------------------------
// Single Historia de Éxito
// ----------------------------------------------------------------------------

.single-codespa_historia {

  .main-content {
    @extend %single-top-navigation;

    article.codespa_historia {

      > header {
        margin-bottom: 30px;

        .entry-logo {
          width: auto;
          height: auto;
          max-width: 135px;
          max-height: 76px;
          margin: 30px 0;
        }

        .entry-title {
          margin: 0 0 .25em; // revisar
          @include responsive-heading($font-size-h2);
        }

        .lead {}
      } // header

      .entry-social {
        margin: 30px 0;
      }

      .entry-content {
        padding-top: 30px;
        border-top: 1px solid $page-header-border-color;
      }
    } // article
  } // .main-content
} // .single-codespa_historia
