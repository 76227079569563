/*================================
UI ELEMENTS
================================ */
/*Titulo form */
[id*='give-form'] .give-form-title{
    @extend .page-title;
    @extend .responsive-heading-h2;
    margin-bottom: 25px;
    @media screen and (min-width:  $screen-md) {
        margin-bottom: 50px;
    }
}
form[id*='give-for']{
    input{
        outline-width:0;
        border-radius: 4px;
        &:focus{
            background-color: #FFF;
            border: $verde 1px solid;
            outline-color: $verde;
            outline-width:1px;
            transition: all 200ms cubic-bezier(0.5, 0, 0.5, 1);
        }
        &:hover{
            background-color: #FFF;
            border: $verde 1px solid;
            outline-color: $verde;
            outline-width:0;
        }
    }
    legend{
        color:$text-color;
    }
    label.give-label{
        font-size: 18px;
    }
    .give-fl-label{
        color:$verde;
    }
}
.give-submit{
    @extend .btn;
    @extend .btn-block;
    @extend .btn-xl;
    @extend .btn-success-white;
    &.disabled{
        opacity: 0.5;
        &.hover{
            color:#fff;
        }
    }
}
[disabled].give-submit:hover{
    color:#fff;
}
//button next panel/
#nextPayment, #next--Confimation{
    display: none;
    @media screen and (min-width:  $screen-md) {
        display: block;
    }
}
.fl-wrap-input{
    margin-bottom: 12px;
    label{
        width:100%;
        line-height: 1.1;
        margin-bottom: 6px;
        &--socio_haveknown_7__label{
            width:auto;
        }
    }
}
/* CAUTION: IE hackery ahead *//* remove default arrow on ie10 and ie11 */
select.give-select::-ms-expand {
  display: none;
}
/* target Internet Explorer 9 to undo the custom arrow */
@media screen and (min-width:0\0) {
  select.give-select {
      background:none\9;
      padding: 5px\9;
  }
}
[id*=give-form].give-fl-form.give-fl-style-give input.give-fl-input, [id*=give-form].give-fl-form.give-fl-style-give select.give-fl-select, [id*=give-form].give-fl-form.give-fl-style-give textarea.give-fl-textarea {
    border-radius:3px;
    font-weight: 800;

    &:focus{
        border-color: $input-border-focus;
    }

    &::-moz-placeholder {
        $color: $input-color-placeholder;
        font-weight: 600;
    }

    &:-ms-input-placeholder {
        $color: $input-color-placeholder;
        font-weight: 600;
    }

    &::-webkit-input-placeholder {
        $color: $input-color-placeholder;
        font-weight: 600;
    }
}
input:-webkit-autofill, textarea:-webkit-autofill, select:-webkit-autofill{
    background-color:#fff !important;
}
/*---------------------------------
Notices, Warnings & Errors
-----------------------------------*/
.give_error, .give_success {
    position: fixed;
    bottom: 0;
    left: 50%;
    transform: translate(-50%,0);
    width:100%;
    max-width: 1380px;
    z-index: 10;
    margin: 0;
    padding: 1em 1em 1em 2em;
    border-left: 30px solid  #dc3232;
    border-top: 1px solid #dc3232;
    -webkit-box-shadow: 1px 1px 5px 0px rgba(0,0,0,0.75);
    -moz-box-shadow: 1px 1px 5px 0px rgba(0,0,0,0.75);
    box-shadow: 1px 1px 5px 0px rgba(0,0,0,0.2);
    &>p{
        text-align:center;
    }
}
p.give_error,{
    color:#FFF;
    background-color: #dd3333;
    @media screen and (min-width:  $screen-md) {
        text-align: center;
        font-size: 20px;
    }
}
.give_success {
border-color: green;
&>p{
    text-align:center;
    color:green;
}
&:before {
  background-color: green;
  content: '\e601';
  text-shadow: 1px 1px rgba(0, 0, 0, 0.2);
}

}
/* Safari*/
.give_error:not(.give_warning){
 background-color: #dd3333;
    p{
        color:#FFF;
    }
    &.give_success{
        background-color: green;
    }
}
/*alerts validación previa */
.error {
    border: 2px solid#dd3333 !important;
}
#alertas {
    width:100%;
    position: fixed;
    z-index: 11;
    bottom: 0;
    left: 0;
    transition: all .5s;
    transform: translateY(100%);
    box-shadow: 1px 1px 5px 0px rgba(0,0,0,0.2);
    background-color: #dd3333;
    border-top: 14px solid #aa0700;
}
#alertas.visibles{
    transform: translateY(0);
}
#mensaje {
    padding: 12px 10px;
    color: #F2F3EB;
    text-align: center;
    font-size: 18px;
    margin:10px;
    &:before{
        content: '\f02d';
        font-family: 'give-icomoon';
        background-color: #a00;
        color: #FFF;
        width: 50px;
        height: 50px;
        line-height: 50px;
        border-radius: 100%;
        text-align: center;
        display: inline-block;
        margin-right: 20px;
        text-shadow: 1px 1px rgba(0, 0, 0, 0.5);
        font-size: 20px;
    }
}
