// Margins
.m {

  &0 {
    margin: 0 !important;
  }

  &5 {
    margin: 5px !important;
  }

  &10 {
    margin: 10px !important;
  }

  &15 {
    margin: 15px !important;
  }

  &20 {
    margin: 20px !important;
  }

  &30 {
    margin: 30px !important;
  }

  &40 {
    margin: 40px !important;
  }

  &50 {
    margin: 50px !important;
  }

  &60 {
    margin: 60px !important;
  }

  &70 {
    margin: 70px !important;
  }

  &80 {
    margin: 80px !important;
  }
}

.mt {

  &0 {
    margin-top: 0 !important;
  }

  &5 {
    margin-top: 5px !important;
  }

   &10 {
    margin-top: 10px !important;
  }

  &15 {
    margin-top: 15px !important;
  }

  &20 {
    margin-top: 20px !important;
  }

  &30 {
    margin-top: 30px !important;
  }

  &40 {
    margin-top: 40px !important;
  }

  &50 {
    margin-top: 50px !important;
  }

  &60 {
    margin-top: 60px !important;
  }

  &70 {
    margin-top: 70px !important;
  }

  &80 {
    margin-top: 80px !important;
  }
}

.mr {

  &0 {
    margin-right: 0 !important;
  }

  &5 {
    margin-right: 5px !important;
  }

   &10 {
    margin-right: 10px !important;
  }

  &15 {
    margin-right: 15px !important;
  }

  &20 {
    margin-right: 20px !important;
  }

  &30 {
    margin-right: 30px !important;
  }

  &40 {
    margin-right: 40px !important;
  }

  &50 {
    margin-right: 50px !important;
  }

  &60 {
    margin-right: 60px !important;
  }

  &70 {
    margin-right: 70px !important;
  }

  &80 {
    margin-right: 80px !important;
  }
}

.mb {

  &0 {
    margin-bottom: 0 !important;
  }

  &5 {
    margin-bottom: 5px !important;
  }

   &10 {
    margin-bottom: 10px !important;
  }

  &15 {
    margin-bottom: 15px !important;
  }

  &20 {
    margin-bottom: 20px !important;
  }

  &30 {
    margin-bottom: 30px !important;
  }

  &40 {
    margin-bottom: 40px !important;
  }

  &50 {
    margin-bottom: 50px !important;
  }

  &60 {
    margin-bottom: 60px !important;
  }

  &70 {
    margin-bottom: 70px !important;
  }

  &80 {
    margin-bottom: 80px !important;
  }
}

.ml {

  &0 {
    margin-left: 0 !important;
  }

  &5 {
    margin-left: 5px !important;
  }

   &10 {
    margin-left: 10px !important;
  }

  &15 {
    margin-left: 15px !important;
  }

  &20 {
    margin-left: 20px !important;
  }

  &30 {
    margin-left: 30px !important;
  }

  &40 {
    margin-left: 40px !important;
  }

  &50 {
    margin-left: 50px !important;
  }

  &60 {
    margin-left: 60px !important;
  }

  &70 {
    margin-left: 70px !important;
  }

  &80 {
    margin-left: 80px !important;
  }
}


// Paddings
.p {

  &0 {
    padding: 0 !important;
  }

  &5 {
    padding: 5px !important;
  }

  &10 {
    padding: 10px !important;
  }

  &15 {
    padding: 15px !important;
  }

  &20 {
    padding: 20px !important;
  }

  &30 {
    padding: 30px !important;
  }

  &40 {
    padding: 40px !important;
  }

  &50 {
    padding: 50px !important;
  }

  &60 {
    padding: 60px !important;
  }

  &70 {
    padding: 70px !important;
  }

  &80 {
    padding: 80px !important;
  }
}

.pt {

  &0 {
    padding-top: 0 !important;
  }

  &5 {
    padding-top: 5px !important;
  }

  &10 {
    padding-top: 10px !important;
  }

  &15 {
    padding-top: 15px !important;
  }

  &20 {
    padding-top: 20px !important;
  }

  &30 {
    padding-top: 30px !important;
  }

  &40 {
    padding-top: 40px !important;
  }

  &50 {
    padding-top: 50px !important;
  }

  &60 {
    padding-top: 60px !important;
  }

  &70 {
    padding-top: 70px !important;
  }

  &80 {
    padding-top: 80px !important;
  }
}

.pr {

  &0 {
    padding-right: 0 !important;
  }

  &5 {
    padding-right: 5px !important;
  }

  &10 {
    padding-right: 10px !important;
  }

  &15 {
    padding-right: 15px !important;
  }

  &20 {
    padding-right: 20px !important;
  }

  &30 {
    padding-right: 30px !important;
  }

  &40 {
    padding-right: 40px !important;
  }

  &50 {
    padding-right: 50px !important;
  }

  &60 {
    padding-right: 60px !important;
  }

  &70 {
    padding-right: 70px !important;
  }

  &80 {
    padding-right: 80px !important;
  }
}

.pb {

  &0 {
    padding-bottom: 0 !important;
  }

  &5 {
    padding-bottom: 5px !important;
  }

  &10 {
    padding-bottom: 10px !important;
  }

  &15 {
    padding-bottom: 15px !important;
  }

  &20 {
    padding-bottom: 20px !important;
  }

  &30 {
    padding-bottom: 30px !important;
  }

  &40 {
    padding-bottom: 40px !important;
  }

  &50 {
    padding-bottom: 50px !important;
  }

  &60 {
    padding-bottom: 60px !important;
  }

  &70 {
    padding-bottom: 70px !important;
  }

  &80 {
    padding-bottom: 80px !important;
  }
}

.pl {

  &0 {
    padding-left: 0 !important;
  }

  &5 {
    padding-left: 5px !important;
  }

  &10 {
    padding-left: 10px !important;
  }

  &15 {
    padding-left: 15px !important;
  }

  &20 {
    padding-left: 20px !important;
  }

  &30 {
    padding-left: 30px !important;
  }

  &40 {
    padding-left: 40px !important;
  }

  &50 {
    padding-left: 50px !important;
  }

  &60 {
    padding-left: 60px !important;
  }

  &70 {
    padding-left: 70px !important;
  }

  &80 {
    padding-left: 80px !important;
  }
}


// Bloques
.display-block {
  display: block !important;
}

.display-inline {
  display: inline !important;
}

.display-inline-block {
  display: inline-block !important;
}

.display-flex {
  display: flex !important;
}


// White space
.text-wrap {
  white-space: normal;
}

.text-nowrap {
  white-space: nowrap;
}


// Text Break
.break-word {
  word-break: break-word;
}

.break-all {
  word-break: break-all;
}


// Posiciones
.relative {
  position: relative;
}

.absolute {
  position: absolute;
}

.t0 {
  top: 0;
}

.r0 {
  right: 0;
}

.b0 {
  bottom: 0;
}

.l0 {
  left: 0;
}

.block-center {
  margin-left: auto !important;
  margin-right: auto !important;
}


.block-narrow {

  &-5 {
    padding-left: 5%;
    padding-right: 5%;
  }

  &-10 {
    padding-left: 10%;
    padding-right: 10%;
  }

  &-15 {
    padding-left: 15%;
    padding-right: 15%;
  }

  &-20 {
    padding-left: 20%;
    padding-right: 20%;
  }

  &-25 {
    padding-left: 25%;
    padding-right: 25%;
  }

  &-30 {
    padding-left: 30%;
    padding-right: 30%;
  }

  &-33 {
    padding-left: (100% / 3);
    padding-right: (100% / 3);
  }
} // .block-narrow


// Clears
.clearleft {
  clear: left;
}

.clearright {
  clear: right;
}

.clearboth {
  clear: both;
}


// Ancho
.w25 {
  width: 25%;
}

.w33 {
  width: (100% / 3);
}

.w50 {
  width: 50%;
}

.w66 {
  width: (100% / 3) * 2;
}

.w75 {
  width: 75%;
}

.w100 {
  width: 100%;
}


// Ancho mínimo
.mw50 {
  min-width: 50%;
}

.mw100 {
  min-width: 100%;
}


// Vertical align
.va-container {
  display: table;
  width: 100%;
  height: 100%;

  .va-content {
    display: table-cell;
    vertical-align: middle;
  }
}




// Opacidades .__opacity_50
$opacities: (0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60, 65, 70, 75, 80, 85, 90, 95, 100);

@mixin generate-opacities() {
  @each $opacity in $opacities {
    .__opacity_#{$opacity} {
      $float: ($opacity / 100);
      opacity: #{$float};
    }
  }
}

@include generate-opacities();




// // Rows and columns
// .row-responsive {

//   @media screen and (min-width: $screen-sm-min) {
//     margin-left: -10px;
//     margin-right: -10px;
//   }

//   @media screen and (min-width: $screen-md-min) {
//     margin-left: -10px;
//     margin-right: -10px;
//   }

//   @media screen and (min-width: $screen-lg-min) {
//     margin-left: -15px;
//     margin-right: -15px;
//   }
// }

// .col-responsive {

//   @media screen and (min-width: $screen-sm-min) {
//     padding-left: 10px;
//     padding-right: 10px;
//   }

//   @media screen and (min-width: $screen-md-min) {
//     padding-left: 10px;
//     padding-right: 10px;
//   }

//   @media screen and (min-width: $screen-lg-min) {
//     padding-left: 15px;
//     padding-right: 15px;
//   }
// }




// // holder.js responsive height fix on ie
// html.ie .img-responsive[data-src*="holder.js"],
// html.ie .img-responsive[src*="holder.js"] {
//   height: 100%;
// }




// Reemplazar imagen como background css
.__img-replace > img:first-child {
  @include sr-only();
}



@mixin absolute-full() {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.absolute-full {
  @include absolute-full();
}





// Flex order
.order--3 {
  order: -3;
}

.order--2 {
  order: -2;
}

.order--1 {
  order: -1;
}

.order-0 {
  order: 0;
}

.order-1 {
  order: 1;
}

.order-2 {
  order: 2;
}

.order-3 {
  order: 3;
}


// Line-Height
.lh-1 {
  line-height: $line-height-1 !important;
}

.lh-xxs {
  line-height: $line-height-xxs !important;
}

.lh-xs {
  line-height: $line-height-xs !important;
}

.lh-sm {
  line-height: $line-height-sm !important;
}

.lh-md {
  line-height: $line-height-md !important;
}

.lh-lg {
  line-height: $line-height-lg !important;
}

.lh-2 {
  line-height: $line-height-2 !important;
}
