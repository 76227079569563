// fieldset {}

legend {
  // display: block;
  // width: 100%;
  // padding: 0;
  // margin-bottom: $line-height-computed;
  // font-size: ($font-size-base * 1.5);
  // line-height: inherit;
  // color: $legend-color;
  // border: 0;
  // border-bottom: 1px solid $legend-border-color;
}

// Labels
label,
.wpcf7-list-item-label {
  // margin-bottom: 5px;
  // font-weight: bold;
  cursor: pointer;
}


// Radios y Checkboxes
input[type="radio"],
input[type="checkbox"] {
  cursor: pointer;
}


// Textareas solo redimensionan verticalmente
textarea {
  resize: vertical;
}


// Custom inputs
.form-control {
  padding: ($padding-base-vertical) ($padding-base-horizontal / 2);
  // border: 1px solid $input-border;
  // border-radius: $input-border-radius;
  font-size: $font-size-medium; // 14px
  // line-height: ($font-size-small * $line-height-base) + 1;
  font-weight: 800;
  box-shadow: none;
  transition: all 200ms $bezier;

  &:focus {
    // outline: 3px solid $brand-primary;
    // outline-offset: -2px;
    // border-color: $input-border-focus;
    box-shadow: none; // 0 0 8px rgba($brand-primary, 0.8); // @include box-shadow(inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px $color-rgba);
  }

  &::-moz-placeholder {
    // $color: $input-color-placeholder;
    font-weight: 600;
  }

  &:-ms-input-placeholder {
    // $color: $input-color-placeholder;
    font-weight: 600;
  }

  &::-webkit-input-placeholder {
    // $color: $input-color-placeholder;
    font-weight: 600;
  }

  // &[disabled],
  // &[readonly],
  // fieldset[disabled] & {
  //   background-color: $input-bg-disabled;
  // }
}


// Selects
select.form-control {
  padding: 7px;
  font-weight: 600;
  color: $input-color-placeholder;

  &.selected-option {
    font-weight: 800;
    color: $input-color;
  }

  option {
    font-weight: 600;
    color: $input-color;
  }

  option[value=""] {
    display: none;
  }
} // select


// Form groups
.form-group {}


.help-block {

  &.text-muted {
    color: $text-muted;
  }
}



// Redefinir tamaños de input
// -------------------------------------------

// .input-sm
@include input-size(
  '.input-sm',
  $input-height-small,
  $padding-small-vertical,
  $padding-small-horizontal,
  $font-size-small,
  $line-height-small, // ($font-size-small * $line-height-small) + 1,
  $input-border-radius-small
);

.form-group-sm {

  .form-control {
    height: $input-height-small;
    padding: $padding-small-vertical $padding-small-horizontal;
    border-width: $border-width-small; // 1px
    font-size: $font-size-small;
    line-height: $line-height-small; // ($font-size-small * $line-height-small) + 1;
    border-radius: $input-border-radius-small;
  }

  select.form-control {
    height: $input-height-small;
    line-height: $input-height-small;
    // padding-top: 5px;
    // padding-bottom: 5px;
  }

  textarea.form-control,
  select[multiple].form-control {
    height: auto;
  }

  // .checkbox {
  //   padding-top: 5px;
  //   padding-bottom: 5px;
  // }

  // .form-control-static {
  //   height: $input-height-small;
  //   min-height: ($line-height-computed + $font-size-xsmall);
  //   padding: ($padding-small-vertical + 1) $padding-small-horizontal;
  //   font-size: $font-size-xsmall;
  //   line-height: $line-height-small;
  // }
}

// .input-lg
@include input-size(
  '.input-lg',
  $input-height-large,
  $padding-large-vertical,
  ($padding-large-horizontal / 2),
  $font-size-base,
  $line-height-large,
  $input-border-radius-large
);

.form-group-lg {

  .form-control {
    height: $input-height-large;
    padding: ($padding-large-vertical) ($padding-large-horizontal / 2);
    border-width: $border-width-large; // 2px
    font-size: $font-size-base;
    line-height: $line-height-large;
    border-radius: $input-border-radius-large;
  }

  select.form-control {
    height: $input-height-large;
    line-height: $input-height-large;
    // padding-top: 11px;
    // padding-bottom: 11px;
  }

  textarea.form-control,
  select[multiple].form-control {
    height: auto;
  }

  // .checkbox {
  //   padding-top: 15px;
  //   padding-bottom: 15px;
  // }

  // .form-control-static {
  //   height: $input-height-large;
  //   min-height: ($line-height-computed + $font-size-base);
  //   padding: ($padding-large-vertical + 1) $padding-large-horizontal;
  //   font-size: $font-size-base;
  //   line-height: $line-height-large;
  // }
}

// .input-xl
@include input-size(
  '.input-xl',
  $input-height-xlarge,
  $padding-xl-vertical,
  ($padding-xl-horizontal / 2),
  $font-size-large,
  $line-height-large,
  $input-border-radius-large
);

.form-group-xl {

  .form-control {
    height: $input-height-xlarge;
    padding: ($padding-xl-vertical) ($padding-xl-horizontal / 2);
    border-width: $border-width-xl; // 2px
    font-size: $font-size-large;
    line-height: $line-height-large;
    border-radius: $input-border-radius-large;
  }

  select.form-control {
    height: $input-height-xlarge;
    line-height: $input-height-large;
    // padding-top: 11px; // revisar
    // padding-bottom: 11px; // revisar
  }

  textarea.form-control,
  select[multiple].form-control {
    height: auto;
  }

  // .checkbox {
  //   padding-top: 15px; // revisar
  //   padding-bottom: 15px; // revisar
  // }

  // .form-control-static {
  //   height: $input-height-large;
  //   min-height: ($line-height-computed + $font-size-base);
  //   padding: ($padding-large-vertical + 1) $padding-large-horizontal;
  //   font-size: $font-size-base;
  //   line-height: $line-height-large;
  // }
}


// Input group y addons
// .input-group {
//   width: 100%;
// }

// .input-group-addon {
//   // display: block;
//   display: flex;
//   align-items: center;
//   position: absolute;
//   top: 0;
//   right: 0;
//   z-index: 3;
//   width: auto;
//   height: 40px; // inherit
//   padding-top: 0; // 10px
//   padding-bottom: 0; // 10px
//   border: none;
//   font-size: $font-size-small;
//   line-height: 20px;
//   pointer-events: none;

//   &.input-sm {
//     font-size: $font-size-small;
//     line-height: 20px;
//   }

//   &.input-lg {
//     font-size: $font-size-base;
//     line-height: 20px;
//     // padding-top: 15px;
//     // padding-bottom: 15px;
//   }

//   &.input-xl {
//     font-size: $font-size-large;
//     line-height: 25px;
//     // padding-top: 20px;
//     // padding-bottom: 20px;
//   }

//   // .input-group-sm > & {}
//   // .input-group-lg > & {}

//   // // .fa-lg
//   // i {
//   //   font-size: 1.33333333em;
//   //   line-height: 0.75em;
//   //   vertical-align: -15%;
//   // }

//   i {
//     font-size: inherit;
//     line-height: inherit;
//     vertical-align: inherit;
//   }
// }

// Redefinir tamaños
.input-group-xl .form-control,
// .input-group-xl .input-group-addon,
// .input-group-xl .input-group-btn > .btn
{
  @extend .input-xl;
}

.input-group-lg .form-control,
// .input-group-lg .input-group-addon,
// .input-group-lg .input-group-btn > .btn
{
  @extend .input-lg;
}

.input-group-sm .form-control,
// .input-group-sm .input-group-addon,
// .input-group-sm .input-group-btn > .btn
{
  @extend .input-sm;
}

// .input-group .form-control {
//   padding-right: 47px;

//   // &.double-icon {
//   //   padding-right: 74px;
//   // }
// }

// .input-group-sm .form-control {
//   padding-right: 34px;

//   // &.double-icon {
//   //   padding-right: 56px;
//   // }
// }
// .input-group-lg .form-control {
//   padding-right: 70px;

//   // &.double-icon {
//   //   padding-right: 103px;
//   // }
// }
// .input-group-xl .form-control {
//   padding-right: 70px; // revisar

//   // &.double-icon {
//   //   padding-right: 103px; // revisar
//   // }
// }


// WPCF7
// -------------------------------------------------

// .wpcf7 {
//   margin: 0;
//   padding: 0;
// }

.wpcf7-response-output {
  @extend .alert;
  position: relative;
  margin-bottom: $form-group-margin-bottom;
  padding: 15px 15px 15px 62px;
  // font-size: $font-size-base;
  line-height: $headings-line-height;

  &:before {
    display: block;
    font-family: FontAwesome;
    font-style: normal;
    font-weight: normal;
    line-height: 1.1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    border: 0 !important;
    border-right: 0;
    border-bottom: 0;
    border-left: 0;
    margin: 0;

    position: absolute;
    top: 50%;
    right: inherit;
    bottom: inherit;
    left: 15px;
    transform: translateY(-50%);
    width: 1em;
    height: 1em;

    font-size: 2em;
    text-align: center;
  }

  &.response-custom {
    font-size: $font-size-medium;

    &:before {
      top: 19px;
      margin: 0;
    }
  }
}

.wpcf7 .screen-reader-response {
  @extend .sr-only;
}

.wpcf7-mail-sent-ok {
  border-color: transparent;
  background-color: $brand-success;
  color: #fff;

  &:before {
    content: '\f058';
    color: #fff;
  }
}

.wpcf7-mail-sent-ng,
.wpcf7-spam-blocked,
.wpcf7-validation-errors {
  @extend .alert-danger;
  border-color: transparent;
  background-color: $brand-danger;
  color: #FFFFE5;

  &:before {
    content: '\f06a';
    color: #FFFFE5;
  }
}

// .wpcf7-form-control-wrap {
//   position: relative;
//   display: block;
// }

// input
.wpcf7-not-valid {
  border-color: $brand-danger;
  background-color: #FFFFE5;
  color: $brand-danger;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;

  &::-moz-placeholder {
    color: $brand-danger;
  }

  &:-ms-input-placeholder {
    color: $brand-danger;
  }

  &::-webkit-input-placeholder {
    color: $brand-danger;
  }

  + .input-group-addon {
    color: $brand-danger;
  }

  &:focus {
    border-color: $brand-danger;
    // outline-color: $brand-danger;
    // box-shadow: 0 0 8px rgba(255, 51, 51, 0.8);

    // + .wpcf7-not-valid-tip,
    // + .input-group-addon + .wpcf7-not-valid-tip {
    //   outline: 1px solid $brand-danger;
    // }
  }
}

// Mensajes de validación
.wpcf7-not-valid-tip {
  position: relative;
  display: block;
  clear: both;
  padding: 4px 10px;
  font-size: $font-size-xsmall;
  font-weight: 800;
  text-transform: uppercase;
  background-color: $brand-danger;
  color: #FFFFE5;

  + .wpcf7-form-control-wrap:after {
    display: none;
  }
}

// CF7 Checkboxes
.wpcf7-checkbox,
.wpcf7-acceptance {
  cursor: pointer;

  .wpcf7-list-item {
    @extend .checkbox;

    input[type="checkbox"],
    &.checkbox-inline input[type="checkbox"] {
      float: left;
      margin-left: -20px;
    }

    + .wpcf7-list-item {
      margin-top: -5px; // Move up sibling radios or checkboxes for tighter spacing
    }
  }

  .wpcf7-list-item-label {
    font-size: $font-size-medium;
    font-weight: 600;
  }
}

.wpcf7-acceptance {

  .wpcf7-list-item-label {
    font-size: $font-size-small;

    &:before {
      top: -2px !important;
    }

    &:after {
      top: -1px !important;
    }
  }
}

// CF7 Radios
.wpcf7-radio {
  cursor: pointer;

  .wpcf7-list-item {
    @extend .radio;

    input[type="radio"],
    &.radio-inline input[type="radio"] {
      float: left;
      margin-left: -20px;
    }

    + .wpcf7-list-item {
      margin-top: -5px; // Move up sibling radios or checkboxes for tighter spacing
    }
  }

  .wpcf7-list-item-label {
    font-size: $font-size-medium;
    font-weight: 600;
  }
}

.wpcf7-display-none {
  display: none;
}

.wpcf7-submit {
  position: relative;
}

.ajax-loader {

  .wpcf7-submit.btn & {
    position: absolute;
    opacity: 0;
    transition: all 200ms ease-in-out;
    top: 10px;
    left: 13px;
  }

  .wpcf7-submit.btn-xs & {
    top: 3px;
    left: -1px;
  }

  .wpcf7-submit.btn-sm & {
    top: 8px;
    left: 3px;
  }

  .wpcf7-submit.btn-md & {
    top: 10px;
    left: 10px;
  }

  .wpcf7-submit.btn-lg & {
    top: 13px;
    left: 19px;
  }

  .wpcf7-submit.btn-xl & {
    top: 18px;
    left: 28px;
  }

  // activo
  &.is-active {

    .wpcf7-submit.btn & {
      opacity: 1;

      + i {
        opacity: 0;
      }
    }
  }
}

.wpcf7 .ajax-error {
  display: none;
}

// .wpcf7 .placeheld {
//   color: #888;
// }




// Bootstrap File upload
.wpcf7 .bootstrap-filestyle {

  > .group-span-filestyle {

    > label {
      border-right: 0;
    }
  }
}




// Teléfono con select de prefijos
.wpcf7 .input-group-phone-prefix {
  display: flex;

  > span {
    min-width: 0;
    min-height: 0;

    &:nth-child(1) {
      flex: 0 0 90px;
      margin-right: -1px;

      select {
        border-top-left-radius: 3px;
        border-bottom-left-radius: 3px;
      }
    }

    &:nth-child(2) {
      flex: 1 1 auto;

      input {
        border-top-right-radius: 3px;
        border-bottom-right-radius: 3px;
      }
    }
  }
} // .input-group-phone-prefix





// WPCF7 reCAPTCHA
.wpcf7-recaptcha {
  position: relative;

  &.recaptcha-xs {
    width: 194px;
    height: 50px;

    > div {
      transform-origin: 0 0;
      transform: scale(.641);
    }

    + noscript + .wpcf7-not-valid-tip {
      width: 193px;
    }
  }

  &.recaptcha-sm {
    width: 233px;
    height: 60px;

    > div {
      transform-origin: 0 0;
      transform: scale(.7692);
    }

    + noscript + .wpcf7-not-valid-tip {
      width: 232px;
    }
  }

  &.recaptcha-md {
    width: 272px;
    height: 70px;

    > div {
      transform-origin: 0 0;
      transform: scale(.8974);
    }

    + noscript + .wpcf7-not-valid-tip {
      width: 271px;
    }
  }

  &.block-center {

    @media screen and (max-width: $screen-xs-max) {
      margin-left: inherit !important;
      margin-right: inherit !important;
    }

    + noscript + .wpcf7-not-valid-tip {

      @media screen and (min-width: $screen-sm-min) {
        margin: 0 auto;
      }
    }
  }

  + noscript + .wpcf7-not-valid-tip {
    width: 303px;
  }
} // .wpcf7-recaptcha





// jQuery UI Datepicker
#ui-datepicker-div {
  z-index: $zindex-dropdown !important;
  overflow: hidden;
  width: 290px;
  padding: 10px 10px 4px;
  border: none;
  border-radius: 3px;
  font-size: inherit;
  font-family: inherit;
  background: #fff;
  color: inherit;
  box-shadow: $shadow-1;

  .ui-datepicker-buttonpane button {
    @extend .btn;
    @extend .btn-sm;
    @extend .btn-default;
    margin-bottom: 5px;
  }

  th {
    font-weight: 900;
  }

  .ui-datepicker-title {
    min-height: 31px;
  }

  .ui-datepicker-month,
  .ui-datepicker-year {
    border-radius: 3px;
    font-weight: 900;
    text-transform: uppercase;
  }

  span.ui-datepicker-month,
  span.ui-datepicker-year {
    display: inline-block;
    padding: 1px 0;
  }

  select.ui-datepicker-month,
  select.ui-datepicker-year {
    width: calc(50% - 4px);
    margin: 0 2px;
    padding: 5px;
    border-color: #ccc;
    font-size: 12px;
  }

  .ui-datepicker-prev,
  .ui-datepicker-next {
    width: 2em;
    height: 2em;
    cursor: pointer;
  }

  .ui-datepicker-prev-hover,
  .ui-datepicker-next-hover {
    top: 2px;
  }

  .ui-datepicker-prev-hover {
    left: 2px;
  }

  .ui-datepicker-next-hover {
    right: 2px;
  }

  .ui-state-default {
    border: 2px solid $gris-claro;
    border-radius: 3px;
    background: $gris-claro;
    font-weight: 700;
    color: $text-color;
    transition: all 200ms ease-in-out;

    &:hover,
    &:focus {
      border: 2px solid $text-color;
      color: $text-color;
    }
  }

  .ui-state-highlight {
    border: 2px solid #fff;
    background: #fff;
    font-weight: 900;
    color: $text-color;
  }

  .ui-state-active {
    border: 2px solid $text-color;
    background: $text-color;
    font-weight: 900;
    color: #fff;

    &:hover,
    &:focus {
      border: 2px solid $text-color;
      color: #fff;
    }
  }

  td.ui-state-disabled {

    span {

      &:hover,
      &:focus {
        border: 2px solid $gris-claro;
        color: $text-color;
      }
    }
  }
}

// Position fix: Se corresponde con header.scss
body {

  &.scrolled {

    > #ui-datepicker-div {

      @media screen and (min-width: $screen-md-min) {
        transform: translateY(-120px);
      }
    } // > #ui-datepicker-div
  } // body.scrolled

  &.scrolled-mobile {

    > #ui-datepicker-div {

      @media screen and (max-width: 419px) {
        transform: translateY(-130px);
      }

      @media screen and (min-width: 420px) and (max-width: $screen-sm-max) {
        transform: translateY(-140px);
      }
    } // > #ui-datepicker-div
  } // body.scrolled-mobile

  &.admin-bar.scrolled {

    > #ui-datepicker-div {

      @media screen and (min-width: $screen-md-min) {
        transform: translateY(-152px);
      }
    } // > #ui-datepicker-div
  } // body.scrolled

  &.admin-bar.scrolled-mobile {

    > #ui-datepicker-div {

      @media screen and (max-width: 419px) {
        transform: translateY(-176px);
      }

      @media screen and (min-width: 420px) and (max-width: 782px) {
        transform: translateY(-186px);
      }

      @media screen and (min-width: 783px) and (max-width: $screen-sm-max) {
        transform: translateY(-172px);
      }
    } // > #ui-datepicker-div
  } // body.scrolled-mobile
} // docy





// Search form
// -----------------------------
// .search-form {
//   @extend .form-inline;
//   display: table;
//   width: 100%;

//   > * {
//     display: table-cell;
//     width: 100%;
//   }

//   label {
//     @extend .form-group;
//     font-weight: normal;

//     .search-field {
//       @extend .form-control;
//       @extend .input-lg;
//       border-right: 0;
//       border-top-right-radius: 0;
//       border-bottom-right-radius: 0;
//     }
//   }

//   .search-submit {
//     @extend .btn;
//     @extend .btn-lg;
//     @extend .btn-primary-white-darker;
//     border-top-left-radius: 0;
//     border-bottom-left-radius: 0;
//   }
// }





/* hide up/down arrows ("spinners") on input fields marked type="number" */
.no-spinner {
  -moz-appearance:textfield;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}
