// Orden sidebar
.blog,
.archive,
.page,
.single,
.search {

  .main-content > .container > .row-flex {

    @media screen and (min-width: $screen-md-min) {

      > [class^="col-"] {

        // Columna del .sidebar
        &:nth-child(2) {
          order: -1;
        }
      }
    } // @ $screen-md-min

  } // .main-content > .container > .row-flex


  // Páginas de ancho completo
  &.layout-fullwidth {

    .main-content > .container > .row-flex {

      @media screen and (min-width: $screen-md-min) {

        > [class^="col-"] {
          flex-basis: 100%;
          width: 100%;

          &:nth-child(2) {
            order: inherit;
          }
        }
      } // @ $screen-sm-max

    } // .main-content > .container > .row-flex
  } // &.layout-fullwidth
} // body.&


// Archive Proyecto
// Layout de los filtros de proyectos
.post-type-archive-codespa_proyecto {

  .main-content > .container > .row-flex {

    @media screen and (min-width: $screen-md-min) {
      align-items: flex-start;
    }

    > [class^="col-"] {

      // Columna del .sidebar
      &:nth-child(2) {

        @media screen and (min-width: $screen-md-min) {
          margin-left: -(100% / 3);
          margin-top: (267px + 30px); // alto computado mas margen inferior
        }

        @media screen and (min-width: $screen-lg-min) {
          margin-top: (277px + 30px); // alto computado mas margen inferior
        }
      }

      // Columna del .sidebar-nav
      &:nth-child(3) {
        order: -2;
      }
    }
  } // .main-content > .container > .row-flex
} // .post-type-archive-codespa_proyecto





.widget {

  > h3 {
    margin-top: 0;
    color: $brand-primary;
    font-weight: 800;
  }

  // Clases para usar con widget-css-classes plugin (documentar!)
  // Ocultar titulo
  &.ocultar-titulo {

    > h3,
    > a:first-child {
      @extend .sr-only;
    }
  }

  &.well {}
} // .> widget


// .sidebar {
//   margin-top: 30px;
//   margin-bottom: 30px;

//   @media screen and (min-width: $screen-md-min) {
//     margin-top: 0;
//   }

//   @media screen and (min-width: $screen-lg-min) {
//     max-width: 360px;
//   }

//   // Tablet
//   // En tablet queremos 2 columnas de widgets
//   @media screen and (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
//     display: flex;
//     flex-wrap: wrap;
//     // justify-content: space-between;
//     max-width: inherit;
//     margin-left: -15px;
//     margin-right: -15px;
//   } // @tablet

//   .widget {
//     margin-bottom: 30px;

//     @media screen and (max-width: $screen-xs-max) {
//       max-width: 360px;
//       margin-left: auto;
//       margin-right: auto;
//     }

//     @media screen and (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
//       -ms-flex: 0 0 46%;
//       flex: 0 0 calc((100% / 2) - 30px);
//       margin-right: 15px;
//       margin-left: 15px;
//     } // @tablet

//     @media screen and (min-width: $screen-lg-min) {
//       margin-bottom: 45px;
//     }
//   } // .> widget
// } // .sidebar


.sidebar {
  margin-top: 30px;
  margin-bottom: 30px;

  @media screen and (min-width: $screen-md-min) {
    margin-top: 0;
  }

  @media screen and (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
    margin-left: -15px;
    margin-right: -15px;
    left: 15px;
  }

  @media screen and (min-width: $screen-lg-min) {
    max-width: 360px;
  }

  .widget,
  .widget-sizer {
    width: 100%;
    margin-bottom: 30px;

    // 2 col
    @media screen and (min-width: $screen-sm-min) {
      width: calc(50% - 30px);
    }

    // 1 col
    @media screen and (min-width: $screen-md-min) {
      width: 100%;
    }
  } // .widget

  // Dummy para ancho Masonry columns
  .widget-sizer {
    visibility: hidden;
    margin-bottom: 0;
    padding: 0 15px;
  }


  // Página Ancho Completo
  .layout-fullwidth & {

    @media screen and (min-width: $screen-sm-min) {
      margin-left: -15px;
      margin-right: -15px;
      left: 15px;
    }

    @media screen and (min-width: $screen-lg-min) {
      max-width: inherit;
    }

    .widget,
    .widget-sizer {

      // 2 cols
      @media screen and (min-width: $screen-sm-min) {
        width: calc(50% - 30px);
      }

      // 3 cols
      @media screen and (min-width: $screen-md-min) {
        width: calc((100% / 3) - 30px);
      }
    } // .widget
  } // .layout-fullwidth .sidebar
} // .sidebar


// Sidebar en contenido (Masonry)
.sidebar-contenido {

  @media screen and (min-width: $screen-sm-min) {
    margin-left: -15px;
    margin-right: -15px;
    left: 15px;
  }

 .widget,
 .widget-sizer {
   width: 100%;
   margin-bottom: 30px;

  // 2 col
  @media screen and (min-width: $screen-sm-min) {
    width: calc(50% - 30px);
  }

  > h3 {
    color: $brand-primary;
  }
 } // .widget

 // Dummy para ancho Masonry columns
 .widget-sizer {
    visibility: hidden;
    margin-bottom: 0;
    padding: 0 15px;
 }
} // .sidebar-contenido





// Filtros de proyectos
.sidebar-nav {
  margin-top: 0;

  // @media screen and (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
  //   display: block;
  // } // @tablet

  .widget {
    width: 100%;
  }
}

// .sidebar-nav .widget-filters-proyecto {

//   @media screen and (max-width: $screen-xs-max) {
//     max-width: inherit;
//   }
// } // .widget-filters-proyecto





.collapse-menu {
  @extend .nav;
  margin: 0;

  > li {

    > a {
      position: relative;
      padding: 5px 15px 4px calc(15px + 1em);
      border-top: 1px dotted $gris-medio;
      font-size: 14px;
      line-height: 20px;
      font-weight: 700;
      background-color: transparent;
      color: $gris-oscuro;
      transition: background-color 200ms ease-in, color 200ms ease-in;

      &:before {
        content: '\2022';
        float: left;
        margin-left: -1em;
        line-height: 1.25;
      }

      &:hover,
      &:focus {
        background-color: $gris-oscuro;
        color: #fff;

        .folder {
          color: #fff;
        }
      }
    } // > a
  } // > li.menu-item

  > .active > a,
  > .current-menu-ancestor > a,
  > .current-page-parent > a,
  > .current_page_parent > a {
    &,
    &:hover,
    &:focus {
      background-color: $gris;
      color: $text-color;
      transition: none;
      font-weight: 900;

      .folder {
        color: $text-color;

        &:hover {
          background-color: $gris-negro;
          color: #fff;
        }
      }
    }
  } // .active

  > .menu-item-has-children {
    transition: all 200ms ease-in;

    > a {
      padding-right: 40px;

      .folder {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        z-index: 1;
        top: 0;
        right: 0;
        width: 30px;
        height: 100%;
        border-left: 1px dotted $gris-medio;
        font-size: 14px;
        text-align: center;
        cursor: pointer;
        background-color: transparent;
        color: $gris-medio;
        transition: all 200ms ease-in;

        &:hover {
          background-color: $gris-negro;
          color: #fff;
        }

        .fa-caret-down {
          display: none;
        }
      } // .folder
    } // a

    &.open {

      > a {
        border-color: $gris-medio;
        color: $gris-oscuro;

        .folder {
          color: $gris-oscuro;

          .fa-caret-right {
            display: none;
          }

          .fa-caret-down {
            display: inline-block;
          }
        }

        &:hover,
        &:focus {
          background-color: $gris-oscuro;
          color: #fff;

          .folder {
            color: #fff;
          }
        }
      }
    } // .open


    // Sub-Menú primer nivel
    > .sub-menu {
      display: none;
      overflow: hidden;
      margin: 0 2px;
      padding: 0 13px;
      border: none;
      list-style: none;
      text-align: left;
      background-color: #fff;

      > li {
        margin: 15px 0;

        > a {
          display: block;
          padding: 3px 15px 2px calc(15px + 1em);
          font-size: 11px;
          font-weight: 900;
          text-transform: uppercase;
          color: #707070;
          transition: all 200ms ease-in;

          &:before {
            content: '\003E';
            float: left;
            margin-left: -1em;
            line-height: 1;
          }

          &:hover,
          &:focus {
            color: #000;
          }
        }
      } // > li

      > .active > a {
        &,
        &:hover,
        &:focus {
          text-decoration: underline;
          outline: 0;
          color: #000;
        }
      }

      // No subrayar item (ver main.js)
      > .active-parent > a {
        text-decoration: none;

        &:hover,
        &:focus {
          text-decoration: underline;
        }
      }

      // Sub-Menú segundo nivel +
      .sub-menu {
        overflow: hidden;
        margin: 0;
        padding: 0 15px;
        border: none;
        list-style: none;
        text-align: left;

        > li {

          > a {
            display: block;
            padding: 3px 15px 2px 1em;
            font-size: 12px;
            font-weight: 700;
            color: #707070;
            transition: all 200ms ease-in;

            &:hover,
            &:focus {
              color: #000;
            }
          }
        } // > li

        > .active > a {
          &,
          &:hover,
          &:focus {
            font-weight: 800;
            text-decoration: none;
            outline: 0;
            color: $link-color;
          }
        }
      } // > .sub-menu .sub-menu
    } // > .sub-menu
  } // .menu-item-has-children
} // .collapse-menu





// Menú
.widget_nav_menu {
  @extend .capsule;
  @extend .capsule-gradient;
  z-index: 1; // masonry push
  background-color: #fff !important;
  padding-top: 20px !important;

  h3 {
    margin: 0 0 15px;
    font-size: 18px;
    line-height: 20px;
    font-weight: 900;
    text-transform: uppercase;
    color: $gris-oscuro;

    a {
      display: block;
      text-decoration: none;
      color: $gris-oscuro;
      transition: color 200ms ease-in-out;

      &:hover,
      &:focus {
        color: #000;
      }
    }
  }

  > a:first-child {
    display: block;
    margin: -20px -15px 0;
    padding: 20px 15px 15px;
    text-decoration: none;
    color: $gris-oscuro;
    transition: color 200ms ease-in-out;

    h3 {
      margin: 0;
    }

    &:hover,
    &:focus {
      color: #000;
    }
  }

  .menu {
    @extend .collapse-menu;
    margin-left: -15px;
    margin-right: -15px;
  } // .menu
} // .widget_nav_menu





// Categorías
.widget_categories {
  @extend .capsule;
  z-index: 1; // masonry push
  padding-top: 20px !important;

  h3 {
    margin: 0 0 15px;
    font-size: 18px;
    line-height: 20px;
    font-weight: 900;
    text-transform: uppercase;
    color: $gris-oscuro;

    a {
      display: block;
      text-decoration: none;
      color: $gris-oscuro;
      transition: color 200ms ease-in-out;

      &:hover,
      &:focus {
        color: #000;
      }
    }
  }

  > a:first-child {
    display: block;
    margin: -20px -15px 0;
    padding: 20px 15px 15px;
    text-decoration: none;
    color: $gris-oscuro;
    transition: color 200ms ease-in-out;

    h3 {
      margin: 0;
    }

    &:hover,
    &:focus {
      color: #000;
    }
  }

  > ul {
    @extend .nav;
    margin: 0 -15px;
    border-bottom: 1px dotted $gris-medio;

    > .cat-item-none {
      padding: 5px 15px 4px;
      border-top: 1px dotted $gris-medio;
      font-size: 14px;
      line-height: 20px;
      font-weight: 600;
    }

    > .cat-item {

      > a {
        position: relative;
        padding: 5px 15px 4px calc(15px + 1em);
        border-top: 1px dotted $gris-medio;
        font-size: 14px;
        line-height: 20px;
        font-weight: 700;
        background-color: transparent;
        color: $gris-oscuro;
        transition: background-color 200ms ease-in, color 200ms ease-in;

        &:before {
          content: '\2022';
          float: left;
          margin-left: -1em;
          line-height: 1.25;
        }

        &:hover,
        &:focus {
          background-color: $gris-oscuro;
          color: #fff;
        }
      } // > a
    } // > li.cat-item

    > .current-cat > a,
    > .current-cat-ancestor > a,
    > .current-cat-parent > a {
      &,
      &:hover,
      &:focus {
        background-color: $gris;
        color: $text-color;
        transition: none;
        font-weight: 900;
      }
    }

    > .cat-item {

      // Sub-Menú primer nivel
      > .children {
        overflow: hidden;
        margin: 0;
        padding: 0 15px 5px;
        border: none;
        list-style: none;
        text-align: left;

        > li {
          margin: 0;

          > a {
            display: block;
            padding: 3px 15px 2px calc(15px + 1em);
            font-size: 12px;
            font-weight: 700;
            color: $gris-oscuro;
            transition: all 200ms ease-in;

            &:before {
              content: '\003E';
              float: left;
              margin-left: -1em;
              line-height: 1;
            }

            &:hover,
            &:focus {
              color: $link-color;
            }
          }
        } // > li

        > .current-cat > a {
          &,
          &:hover,
          &:focus {
            font-weight: 800;
            text-decoration: none;
            outline: 0;
            color: $link-color;
          }
        }

        // Sub-Menú segundo nivel +
        .children {
          display: none;
        } // > .children .children
      } // > .children
    } // > .cat-item
  } // > ul
} // .widget_categories





// Collapsed Archives Widget
.widget_collapsed_archives_widget {
  @extend .capsule;
  z-index: 1; // masonry push
  padding-top: 20px !important;

  h3 {
    margin: 0 0 15px;
    font-size: 18px;
    line-height: 20px;
    font-weight: 900;
    text-transform: uppercase;
    color: $gris-oscuro;

    a {
      display: block;
      text-decoration: none;
      color: $gris-oscuro;
      transition: color 200ms ease-in-out;

      &:hover,
      &:focus {
        color: #000;
      }
    }
  }

  > a:first-child {
    display: block;
    margin: -20px -15px 0;
    padding: 20px 15px 15px;
    text-decoration: none;
    color: $gris-oscuro;
    transition: color 200ms ease-in-out;

    h3 {
      margin: 0;
    }

    &:hover,
    &:focus {
      color: #000;
    }
  }

  .collapsed-archives {

    label,
    input {
      display: none;
    }

    > ul {
      @extend .nav;
      margin: 0 -15px;
      border-bottom: 1px dotted $gris-medio;

      > li {

        > a {
          position: relative;
          padding: 5px 15px 4px calc(15px + 1em);
          border-top: 1px dotted $gris-medio;
          font-size: 14px;
          line-height: 20px;
          font-weight: 700;
          background-color: transparent;
          color: $gris-oscuro;
          transition: background-color 200ms ease-in, color 200ms ease-in;

          &:before {
            content: '\2022';
            float: left;
            margin-left: -1em;
            line-height: 1.25;
          }

          &:hover,
          &:focus {
            background-color: $gris-oscuro;
            color: #fff;

            .folder {
              color: #fff;
            }
          }
        } // > a
      } // > li

      > .active > a {
        &,
        &:hover,
        &:focus {
          background-color: $gris;
          color: $text-color;
          transition: none;
          font-weight: 900;

          .folder {
            color: $text-color;

            &:hover {
              background-color: $gris-negro;
              color: #fff;
            }
          }
        }
      } // .active

      > li {
        transition: all 200ms ease-in;

        > a {
          padding-right: 40px;

          .folder {
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            z-index: 1;
            top: 0;
            right: 0;
            width: 30px;
            height: 100%;
            border-left: 1px dotted $gris-medio;
            font-size: 14px;
            text-align: center;
            cursor: pointer;
            background-color: transparent;
            color: $gris-medio;
            transition: all 200ms ease-in;

            &:hover {
              background-color: $gris-negro;
              color: #fff;
            }

            .fa-caret-down {
              display: none;
            }
          } // .folder
        } // a

        &.open {

          > a {
            border-color: $gris-medio;
            color: $gris-oscuro;

            .folder {
              color: $gris-oscuro;

              .fa-caret-right {
                display: none;
              }

              .fa-caret-down {
                display: inline-block;
              }
            }

            &:hover,
            &:focus {
              background-color: $gris-oscuro;
              color: #fff;

              .folder {
                color: #fff;
              }
            }
          }
        } // .open


        // Sub-Menú primer nivel
        > ul {
          overflow: hidden;
          margin: 0 2px;
          padding: 15px 13px;
          border: none;
          list-style: none;
          text-align: left;
          background-color: #fff;

          > li {
            margin: 0;

            > a {
              display: block;
              padding: 3px 15px 2px calc(15px + 1em);
              font-size: 11px;
              font-weight: 900;
              text-transform: uppercase;
              color: #707070;
              transition: all 200ms ease-in;

              &:before {
                content: '\003E';
                float: left;
                margin-left: -1em;
                line-height: 1;
              }

              &:hover,
              &:focus {
                color: #000;
              }
            }
          } // > li

          > .active > a {
            &,
            &:hover,
            &:focus {
              font-weight: 800;
              text-decoration: none;
              outline: 0;
              color: $link-color;
            }
          }
        } // > ul
      } // > li
    } // > ul
  } // .collapsed-archives
} // .widget_collapsed_archives_widget





// Nube de Etiquetas
.widget_tag_cloud {
  transition: box-shadow 300ms ease-in-out;

  > h3 {
    margin: 0;
    padding: 9px 15px 8px;
    font-size: $font-size-small;
    font-weight: 900;
    text-transform: uppercase;
    background-color: $gris-borde;
    color: #fff;
    transition: all 300ms $bezier;
  }

  .tagcloud {
    background-color: $gris-claro;
    padding: 15px;
    line-height: $headings-line-height;

    a {
      color: $gris-medio;
      padding: 2px;
      transition: all 300ms $bezier;

      &:hover,
      &:focus {
        color: $link-color;
      }
    }
  } // .tagclud

  &:hover,
  &:focus {
    box-shadow: $shadow-1;

    > h3 {
      background-color: $brand-primary;
    }
  }
} // .widget_tag_cloud





// Widget Imagen nativo
.widget_media_image {

  img {
    outline: 1px solid transparent;
    outline-offset: -1px;
    transition: all 300ms $bezier;
  }

  // Clases para usar con widget-css-classes plugin (documentar!)
  &.borde-gris {

    img {
      outline: 1px solid $gris;
    }
  }

  &.borde-negro {

    img {
      outline: 1px solid $text-color;
    }
  }

  &.borde-naranja {

    img {
      outline: 1px solid $brand-primary;
    }
  }

  &.borde-verde {

    img {
      outline: 1px solid $brand-success;
    }
  }

  &.borde-amarillo {

    img {
      outline: 1px solid $brand-warning;
    }
  }

  &.borde-azul {

    img {
      outline: 1px solid $brand-info;
    }
  }

  &.borde-rojo {

    img {
      outline: 1px solid $brand-danger;
    }
  }

  > a {
    display: block;

    &:hover,
    &:focus {

      img {
        outline: 1px solid $brand-primary;
        box-shadow: $shadow-1;
      }
    }
  }
} // .widget_media_image










// // Categorías
// .widget_categories {

//   > ul {
//     @include list-unstyled;
//     margin: 10px 0;

//     // @media screen and (min-width: $screen-lg-min) {
//     //   font-size: $font-size-large;
//     // }

//     > li {

//       > a {
//         display: block;
//         padding: 10px 0;
//         border-left: 0 solid transparent;
//         font-weight: 700;
//         text-decoration: none;
//         color: $link-color;
//         transition: all 0.2s ease-in-out;

//         // @media screen and (min-width: $screen-lg-min) {
//         //   font-weight: 400;
//         // }

//         &:hover,
//         &:focus {
//           padding-left: 11px;
//           border-left: 4px solid $link-color;
//           background-color: $gray-white;
//           color: $link-color;
//         }
//       }

//       &.current-cat,
//       &.active {

//         > a {
//           padding-left: 11px;
//           border-left: 4px solid $link-color;
//           background-color: $gray-white;
//           font-weight: 700;
//         }
//       }
//     }
//   }
// } // .widget_categories




// .widget-relacionadas {

//   > h3 {
//     margin-bottom: 0;
//   }

//   article {}
// }


// // Categorias
// .widget_categories {
//   @extend .widget;
//   @extend .widget_categories;
// }


// // Taxonomias
// .widget_lc_taxonomy {

//   .list-custom-taxonomy-widget {
//     @extend .widget;
//     @extend .widget_categories;
//     margin-bottom: initial;
//   }
// } // .widget_categories





// // Widget Imagen
// .widget_sp_image {
//   margin-bottom: $line-height-computed;

//   .widget_sp_image-image-link {
//     display: block;
//     @include opacity(1);
//     transition: opacity 0.2s ease;

//     &:hover,
//     &:focus {
//       @include opacity(0.8);
//       transition: opacity 0.2s ease;
//     }
//   }

//   img {
//     @extend .img-responsive;
//     width: 100%;
//     max-width: 320px !important;
//     margin: 0 auto;
//   }
// } // .widget_sp_image




// Formulario CF7 en widget
// .widget-form {

//   .form-group {
//     margin-bottom: 15px;
//   }

//   .wpcf7-response-output {
//     margin-bottom: 15px;
//   }

//   .single & {
//     padding: 15px;
//     background-color: $gris-claro;
//   }
// }





// Widget donación directa
.widget-donacion {
  margin-right: 15px;
  margin-left: 15px;
  width: calc(100% - 30px) !important;
  transition: all 300ms $bezier;

  @media screen and (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
    width: calc(100% - 60px) !important;
  }

  .layout-fullwidth & {

    @media screen and (min-width: $screen-md-min) {
      width: calc((100% / 3) - 60px) !important;
    }
  }

  // @media screen and (max-width: $screen-xs-max) {
  //   max-width: 100%;
  // }

  // @media screen and (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
  //   flex: 0 0 calc(50% - 60px);
  //   margin-left: 30px;
  //   margin-right: 30px;
  // } // @tablet

  // @media screen and (min-width: $screen-md-min) {
  //   margin-right: 15px;
  //   margin-left: 15px;
  // } // @tablet

  > h3 {
    margin: -15px -15px 15px;
    padding: 10px 15px;
    font-size: 18px;
    line-height: 20px;
    font-weight: 900;
    text-transform: uppercase;
    border-top-left-radius: $border-radius-base;
    border-top-right-radius: $border-radius-base;
    background-color: $gris-medio;
    color: #fff;
  }

  > p {
    font-size: $font-size-small;
    font-weight: 700;
  }

  .cinta {
    position: relative;
    display: flex;
    margin: 0 -30px 30px;
    padding: 15px 30px;
    background: $brand-primary;
    box-shadow: 0 3px 3px rgba(#000, 0.2);

    &:before,
    &:after {
      position: absolute;
      z-index: -1;
      top: 100%;
      width: 0;
      height: 0;
      border-style: solid;
    }

    &:before {
      left: 0;
      border-width: 0 15px 10px 0;
      border-color: transparent #707070 transparent transparent;
    }

    &:after {
      right: 0;
      border-width: 10px 15px 0 0;
      border-color: #707070 transparent transparent transparent;
    }

    .monto {
      flex: 1 0 auto;

      &:nth-child(1) {
        border-right: 1px solid darken($brand-primary, 5%);
      }

      &:nth-child(2) {
        border-left: 1px solid lighten($brand-primary, 5%);
      }

      h5 {
        margin: 0;
        font-size: 11px;
        font-weight: 800;
        text-transform: uppercase;
      }

      p {
        margin: 0;
        font-size: $font-size-large;
        line-height: 1;
        font-weight: 800;
        color: #fff;
        // text-shadow: -1px -1px 0 rgba(#000, 0.2);

        .odometro {

          + .divisa {
            display: inline-block;
            vertical-align: middle;
          }
        }
      }
    }
  } // .cinta

  .progress {
    position: relative;
    height: 40px;
    font-size: 18px;

    .progress-bar {
      width: 0;
      transition: width 1200ms $bezier;
    }

    > span {
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      font-weight: 700;
      box-shadow: inset 0 0 12px rgba(#000, 0.5);
    }
  } // .progress

  &:hover,
  &:focus {
    box-shadow: 0 1px 3px rgba(#000, 0.5);
  }
} // .widget-donacion





// Widget de Búsqueda
.widget_search {

  > h3, a {
    @extend .sr-only;
  }

  .search-form {

    .form-group {
      margin-bottom: 0;
    }
  }
} // .widget_search
