// Pagination (multiple pages)
// --------------------------------------------------

//
// Pagination (multiple pages)
// --------------------------------------------------
.pagination {

  > li {

    > span {

      &:hover,
      &:focus {
        color: $pagination-color;
        background-color: $pagination-bg;
        border-color: $pagination-border;
      }
    }
  }

  // > .active > a,
  // > .active > span {
  //   &,
  //   &:hover,
  //   &:focus {
  //     z-index: 3;
  //     color: $pagination-active-color;
  //     background-color: $pagination-active-bg;
  //     border-color: $pagination-active-border;
  //     cursor: default;
  //   }
  // }
}

// Sizing
// --------------------------------------------------

// Large
.pagination-lg {
  @include pagination-size($padding-large-vertical, $padding-large-horizontal, $font-size-large, $line-height-large, $border-radius-large);
}

// Small
.pagination-sm {
  @include pagination-size($padding-small-vertical, $padding-small-horizontal, $font-size-small, $line-height-small, $border-radius-small);
}


// MD
.pagination-md {
  @include pagination-size(
    $padding-base-vertical,
    15px,
    $font-size-medium,
    $line-height-base,
    $border-radius-base
  );

  @media screen and (max-width: $screen-xs-max) {

    > li {

      > a,
      > span {
        padding: $padding-small-vertical $padding-small-horizontal;
        font-size: $font-size-small;
      }
    }

    .pages {
      display: none;
    }
  } // @media
} // .pagination-md
