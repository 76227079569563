//
// Mixins
// --------------------------------------------------


/// Returns the opposite direction of each direction in a list
/// @author Hugo Giraudel
/// @param {List} $directions - List of initial directions
/// @return {List} - List of opposite directions
///
/// Usage:
/// .selector {
///   background-position: opposite-direction(top right);
/// }
@function opposite-direction($directions) {
  $opposite-directions: ();
  $direction-map: (
    'top':    'bottom',
    'right':  'left',
    'bottom': 'top',
    'left':   'right',
    'center': 'center',
    'ltr':    'rtl',
    'rtl':    'ltr'
  );

  @each $direction in $directions {
    $direction: to-lower-case($direction);

    @if map-has-key($direction-map, $direction) {
      $opposite-directions: append($opposite-directions, unquote(map-get($direction-map, $direction)));
    } @else {
      @warn "No opposite direction can be found for `#{$direction}`. Direction omitted.";
    }
  }

  @return $opposite-directions;
}




/// Triangle helper mixin
/// @param {Direction} $direction - Triangle direction, either `top`, `right`, `bottom` or `left`
/// @param {Color} $color [currentcolor] - Triangle color
/// @param {Length} $size [1em] - Triangle size
///
/// Usage:
/// .foo::before {
///   @include triangle(bottom);
///   position: absolute;
///   left: 50%;
///   bottom: 100%;
/// }
@mixin triangle($direction, $color: currentcolor, $size: 1em) {
  @if not index(top right bottom left, $direction) {
    @error "Direction must be either `top`, `right`, `bottom` or `left`.";
  }

  $height: 1; // 1 es triángulo rectángulo isóceles

  width: 0;
  height: 0;
  content: '';
  z-index: 2;
  border-#{opposite-direction($direction)}: ($size * $height) solid $color;

  $perpendicular-borders: $size solid transparent;

  @if $direction == top or $direction == bottom {
    border-left:   $perpendicular-borders;
    border-right:  $perpendicular-borders;
  } @else if $direction == right or $direction == left {
    border-bottom: $perpendicular-borders;
    border-top:    $perpendicular-borders;
  }
}




// Generate the x-large columns
@mixin make-xl-column($columns, $gutter: $grid-gutter-width) {
  position: relative;
  min-height: 1px;
  padding-left:  ($gutter / 2);
  padding-right: ($gutter / 2);

  @media (min-width: $screen-xl-min) {
    float: left;
    width: percentage(($columns / $grid-columns));
  }
}
@mixin make-xl-column-offset($columns) {
  @media (min-width: $screen-xl-min) {
    margin-left: percentage(($columns / $grid-columns));
  }
}
@mixin make-xl-column-push($columns) {
  @media (min-width: $screen-xl-min) {
    left: percentage(($columns / $grid-columns));
  }
}
@mixin make-xl-column-pull($columns) {
  @media (min-width: $screen-xl-min) {
    right: percentage(($columns / $grid-columns));
  }
}




// Framework grid generation
//
// Used only by Bootstrap to generate the correct number of grid classes given
// any value of `$grid-columns`.

// [converter] This is defined recursively in LESS, but Sass supports real loops
@mixin make-grid-columns($i: 1, $list: ".col-xs-#{$i}, .col-sm-#{$i}, .col-md-#{$i}, .col-lg-#{$i}, .col-xl-#{$i}") {
  @for $i from (1 + 1) through $grid-columns {
    $list: "#{$list}, .col-xs-#{$i}, .col-sm-#{$i}, .col-md-#{$i}, .col-lg-#{$i}, .col-xl-#{$i}";
  }
  #{$list} {
    position: relative;
    // Prevent columns from collapsing when empty
    min-height: 1px;
    // Inner gutter via padding
    padding-left:  ceil(($grid-gutter-width / 2));
    padding-right: floor(($grid-gutter-width / 2));
  }
}
