#cookie-law-info-bar {
  position: absolute;
  z-index: ($zindex-modal-background - 1);
  width: 100%;
  margin: 0 auto;
  padding: 10px 0;
  border: 0;
  text-align: center;
  font-size: 12px;
  font-weight: 700;
  background-color: #FFF;
  box-shadow: 0 0 20px rgba(0,0,0,.5);

  @media (min-width: $screen-sm-min) {
    padding: 15px 0;
  }

  > span {
    display: block;
    @include container-fixed;

    @media (min-width: $screen-sm-min) {
      width: $container-sm;
    }

    @media (min-width: $screen-md-min) {
      width: $container-md;
    }

    @media (min-width: $screen-lg-min) {
      width: $container-lg;
    }

    @media (min-width: $screen-xl-min) {
      width: $container-xl;
    }
  }

  .cookie-law-es,
  .cookie-law-en {
    display: none;
  }

  .cli-plugin-main-link {
    font-weight: 900;
    text-decoration: underline;
  }
}

#button-modal-cookie {
  text-decoration: underline;
}

#cookie-law-info-again {
  @extend .btn;
  @extend .btn-warning;
}

.cli-plugin-button {
  @extend .btn;
  @extend .btn-sm;
  display: block;
  margin: 5px auto 0;
  width: 100px;
  border-bottom: inherit;
  box-shadow: none;
}

#cookie_action_close_header {
  @extend .btn-success;
}

#cookie_action_close_header_reject {
  @extend .btn-default;
}

.small.cli-plugin-button {
  @extend .btn-xs;
}

.large.cli-plugin-button {
  @extend .btn;
}

.super.cli-plugin-button {
  @extend .btn-lg;
}

// .pink.cli-plugin-button {}
// .magenta.cli-plugin-button {}
// .green.cli-plugin-button {}
// .red.cli-plugin-button {}
// .orange.cli-plugin-button {}
// .blue.cli-plugin-button {}
// .yellow.cli-plugin-button {}
