//
// Grid system
// --------------------------------------------------


// XL
.container {

  @media (min-width: $screen-xl-min) {
    width: $container-xl;
  }
}

@media (min-width: $screen-xl-min) {
  @include make-grid(xl);
}




// Custom rows and columns
.row {

  &-0 {
    margin-left: 0;
    margin-right: 0;
  }

  &-5 {
    margin-left: -5px;
    margin-right: -5px;
  }

  &-10 {
    margin-left: -10px;
    margin-right: -10px;
  }

  &-15 {
    margin-left: -15px;
    margin-right: -15px;
  }

  &-20 {
    margin-left: -20px;
    margin-right: -20px;
  }

  &-25 {
    margin-left: -25px;
    margin-right: -25px;
  }

  &-30 {
    margin-left: -30px;
    margin-right: -30px;
  }

  &-35 {
    margin-left: -35px;
    margin-right: -35px;
  }

  &-40 {
    margin-left: -40px;
    margin-right: -40px;
  }

  &-45 {
    margin-left: -45px;
    margin-right: -45px;
  }

  &-50 {
    margin-left: -50px;
    margin-right: -50px;
  }

  &-55 {
    margin-left: -55px;
    margin-right: -55px;
  }

  &-60 {
    margin-left: -60px;
    margin-right: -60px;
  }
}

.col {

  &-0 {
    padding-left: 0;
    padding-right: 0;
  }

  &-5 {
    padding-left: 5px;
    padding-right: 5px;
  }

  &-10 {
    padding-left: 10px;
    padding-right: 10px;
  }

  &-15 {
    padding-left: 15px;
    padding-right: 15px;
  }

  &-20 {
    padding-left: 20px;
    padding-right: 20px;
  }

  &-25 {
    padding-left:25px;
    padding-right:25px;
  }

  &-30 {
    padding-left: 30px;
    padding-right: 30px;
  }

  &-35 {
    padding-left: 35px;
    padding-right: 35px;
  }

  &-40 {
    padding-left: 40px;
    padding-right: 40px;
  }

  &-45 {
    padding-left: 45px;
    padding-right: 45px;
  }

  &-50 {
    padding-left: 50px;
    padding-right: 50px;
  }

  &-55 {
    padding-left: 55px;
    padding-right: 55px;
  }

  &-60 {
    padding-left: 60px;
    padding-right: 60px;
  }
}




// Flex rows
.row-flex {
  display: flex;
  flex-wrap: wrap;

  // Safari fix
  &:before,
  &:after {
    content: none;
  }

  > [class^="col-"] {
    float: inherit;
    flex-basis: 100%;
    width: 100%;

    // Firefox Fix
    min-width: 0;
    min-height: 0;
  }

  > .col-xs-12 {
    flex-basis: 100%;
    width: 100%;
  }

  > .col-xs-11 {
    flex-basis: 91.66666667%;
    width: 91.66666667%;
  }

  > .col-xs-10 {
    flex-basis: 83.33333333%;
    width: 83.33333333%;
  }

  > .col-xs-9 {
    flex-basis: 75%;
    width: 75%;
  }

  > .col-xs-8 {
    flex-basis: 66.66666667%;
    width: 66.66666667%;
  }

  > .col-xs-7 {
    flex-basis: 58.33333333%;
    width: 58.33333333%;
  }

  > .col-xs-6 {
    flex-basis: 50%;
    width: 50%;
  }

  > .col-xs-5 {
    flex-basis: 41.66666667%;
    width: 41.66666667%;
  }

  > .col-xs-4 {
    flex-basis: 33.33333333%;
    width: 33.33333333%;
  }

  > .col-xs-3 {
    flex-basis: 25%;
    width: 25%;
  }

  > .col-xs-2 {
    flex-basis: 16.66666667%;
    width: 16.66666667%;
  }

  > .col-xs-1 {
    flex-basis: 8.33333333%;
    width: 8.33333333%;
  }

  @media (min-width: $screen-sm-min) {

    > .col-sm-12 {
      flex-basis: 100%;
      width: 100%;
    }

    > .col-sm-11 {
      flex-basis: 91.66666667%;
      width: 91.66666667%;
    }

    > .col-sm-10 {
      flex-basis: 83.33333333%;
      width: 83.33333333%;
    }

    > .col-sm-9 {
      flex-basis: 75%;
      width: 75%;
    }

    > .col-sm-8 {
      flex-basis: 66.66666667%;
      width: 66.66666667%;
    }

    > .col-sm-7 {
      flex-basis: 58.33333333%;
      width: 58.33333333%;
    }

    > .col-sm-6 {
      flex-basis: 50%;
      width: 50%;
    }

    > .col-sm-5 {
      flex-basis: 41.66666667%;
      width: 41.66666667%;
    }

    > .col-sm-4 {
      flex-basis: 33.33333333%;
      width: 33.33333333%;
    }

    > .col-sm-3 {
      flex-basis: 25%;
      width: 25%;
    }

    > .col-sm-2 {
      flex-basis: 16.66666667%;
      width: 16.66666667%;
    }

    > .col-sm-1 {
      flex-basis: 8.33333333%;
      width: 8.33333333%;
    }
  }

  @media (min-width: $screen-md-min) {

    > .col-md-12 {
      flex-basis: 100%;
      width: 100%;
    }

    > .col-md-11 {
      flex-basis: 91.66666667%;
      width: 91.66666667%;
    }

    > .col-md-10 {
      flex-basis: 83.33333333%;
      width: 83.33333333%;
    }

    > .col-md-9 {
      flex-basis: 75%;
      width: 75%;
    }

    > .col-md-8 {
      flex-basis: 66.66666667%;
      width: 66.66666667%;
    }

    > .col-md-7 {
      flex-basis: 58.33333333%;
      width: 58.33333333%;
    }

    > .col-md-6 {
      flex-basis: 50%;
      width: 50%;
    }

    > .col-md-5 {
      flex-basis: 41.66666667%;
      width: 41.66666667%;
    }

    > .col-md-4 {
      flex-basis: 33.33333333%;
      width: 33.33333333%;
    }

    > .col-md-3 {
      flex-basis: 25%;
      width: 25%;
    }

    > .col-md-2 {
      flex-basis: 16.66666667%;
      width: 16.66666667%;
    }

    > .col-md-1 {
      flex-basis: 8.33333333%;
      width: 8.33333333%;
    }
  }

  @media (min-width: $screen-lg-min) {

    > .col-lg-12 {
      flex-basis: 100%;
      width: 100%;
    }

    > .col-lg-11 {
      flex-basis: 91.66666667%;
      width: 91.66666667%;
    }

    > .col-lg-10 {
      flex-basis: 83.33333333%;
      width: 83.33333333%;
    }

    > .col-lg-9 {
      flex-basis: 75%;
      width: 75%;
    }

    > .col-lg-8 {
      flex-basis: 66.66666667%;
      width: 66.66666667%;
    }

    > .col-lg-7 {
      flex-basis: 58.33333333%;
      width: 58.33333333%;
    }

    > .col-lg-6 {
      flex-basis: 50%;
      width: 50%;
    }

    > .col-lg-5 {
      flex-basis: 41.66666667%;
      width: 41.66666667%;
    }

    > .col-lg-4 {
      flex-basis: 33.33333333%;
      width: 33.33333333%;
    }

    > .col-lg-3 {
      flex-basis: 25%;
      width: 25%;
    }

    > .col-lg-2 {
      flex-basis: 16.66666667%;
      width: 16.66666667%;
    }

    > .col-lg-1 {
      flex-basis: 8.33333333%;
      width: 8.33333333%;
    }
  }

  @media (min-width: $screen-xl-min) {

    > .col-xl-12 {
      flex-basis: 100%;
      width: 100%;
    }

    > .col-xl-11 {
      flex-basis: 91.66666667%;
      width: 91.66666667%;
    }

    > .col-xl-10 {
      flex-basis: 83.33333333%;
      width: 83.33333333%;
    }

    > .col-xl-9 {
      flex-basis: 75%;
      width: 75%;
    }

    > .col-xl-8 {
      flex-basis: 66.66666667%;
      width: 66.66666667%;
    }

    > .col-xl-7 {
      flex-basis: 58.33333333%;
      width: 58.33333333%;
    }

    > .col-xl-6 {
      flex-basis: 50%;
      width: 50%;
    }

    > .col-xl-5 {
      flex-basis: 41.66666667%;
      width: 41.66666667%;
    }

    > .col-xl-4 {
      flex-basis: 33.33333333%;
      width: 33.33333333%;
    }

    > .col-xl-3 {
      flex-basis: 25%;
      width: 25%;
    }

    > .col-xl-2 {
      flex-basis: 16.66666667%;
      width: 16.66666667%;
    }

    > .col-xl-1 {
      flex-basis: 8.33333333%;
      width: 8.33333333%;
    }
  }
} // .row-flex


// Flex rows - IE 10-11 FiX (30px $grid-gutter-width)
.ie .row-flex:not(.row-0) {

  > [class^="col-"] {
    flex-basis: calc(100% - 30px);
    width: calc(100% - 30px);
  }

  > .col-xs-12 {
    flex-basis: calc(100% - 30px);
    width: calc(100% - 30px);
  }

  > .col-xs-11 {
    flex-basis: calc(91.66666667% - 30px);
    width: calc(91.66666667% - 30px);
  }

  > .col-xs-10 {
    flex-basis: calc(83.33333333% - 30px);
    width: calc(83.33333333% - 30px);
  }

  > .col-xs-9 {
    flex-basis: calc(75% - 30px);
    width: calc(75% - 30px);
  }

  > .col-xs-8 {
    flex-basis: calc(66.66666667% - 30px);
    width: calc(66.66666667% - 30px);
  }

  > .col-xs-7 {
    flex-basis: calc(58.33333333% - 30px);
    width: calc(58.33333333% - 30px);
  }

  > .col-xs-6 {
    flex-basis: calc(50% - 30px);
    width: calc(50% - 30px);
  }

  > .col-xs-5 {
    flex-basis: calc(41.66666667% - 30px);
    width: calc(41.66666667% - 30px);
  }

  > .col-xs-4 {
    flex-basis: calc(33.33333333% - 30px);
    width: calc(33.33333333% - 30px);
  }

  > .col-xs-3 {
    flex-basis: calc(25% - 30px);
    width: calc(25% - 30px);
  }

  > .col-xs-2 {
    flex-basis: calc(16.66666667% - 30px);
    width: calc(16.66666667% - 30px);
  }

  > .col-xs-1 {
    flex-basis: calc(8.33333333% - 30px);
    width: calc(8.33333333% - 30px);
  }

  @media (min-width: $screen-sm-min) {

    > .col-sm-12 {
      flex-basis: calc(100% - 30px);
      width: calc(100% - 30px);
    }

    > .col-sm-11 {
      flex-basis: calc(91.66666667% - 30px);
      width: calc(91.66666667% - 30px);
    }

    > .col-sm-10 {
      flex-basis: calc(83.33333333% - 30px);
      width: calc(83.33333333% - 30px);
    }

    > .col-sm-9 {
      flex-basis: calc(75% - 30px);
      width: calc(75% - 30px);
    }

    > .col-sm-8 {
      flex-basis: calc(66.66666667% - 30px);
      width: calc(66.66666667% - 30px);
    }

    > .col-sm-7 {
      flex-basis: calc(58.33333333% - 30px);
      width: calc(58.33333333% - 30px);
    }

    > .col-sm-6 {
      flex-basis: calc(50% - 30px);
      width: calc(50% - 30px);
    }

    > .col-sm-5 {
      flex-basis: calc(41.66666667% - 30px);
      width: calc(41.66666667% - 30px);
    }

    > .col-sm-4 {
      flex-basis: calc(33.33333333% - 30px);
      width: calc(33.33333333% - 30px);
    }

    > .col-sm-3 {
      flex-basis: calc(25% - 30px);
      width: calc(25% - 30px);
    }

    > .col-sm-2 {
      flex-basis: calc(16.66666667% - 30px);
      width: calc(16.66666667% - 30px);
    }

    > .col-sm-1 {
      flex-basis: calc(8.33333333% - 30px);
      width: calc(8.33333333% - 30px);
    }
  }

  @media (min-width: $screen-md-min) {

    > .col-md-12 {
      flex-basis: calc(100% - 30px);
      width: calc(100% - 30px);
    }

    > .col-md-11 {
      flex-basis: calc(91.66666667% - 30px);
      width: calc(91.66666667% - 30px);
    }

    > .col-md-10 {
      flex-basis: calc(83.33333333% - 30px);
      width: calc(83.33333333% - 30px);
    }

    > .col-md-9 {
      flex-basis: calc(75% - 30px);
      width: calc(75% - 30px);
    }

    > .col-md-8 {
      flex-basis: calc(66.66666667% - 30px);
      width: calc(66.66666667% - 30px);
    }

    > .col-md-7 {
      flex-basis: calc(58.33333333% - 30px);
      width: calc(58.33333333% - 30px);
    }

    > .col-md-6 {
      flex-basis: calc(50% - 30px);
      width: calc(50% - 30px);
    }

    > .col-md-5 {
      flex-basis: calc(41.66666667% - 30px);
      width: calc(41.66666667% - 30px);
    }

    > .col-md-4 {
      flex-basis: calc(33.33333333% - 30px);
      width: calc(33.33333333% - 30px);
    }

    > .col-md-3 {
      flex-basis: calc(25% - 30px);
      width: calc(25% - 30px);
    }

    > .col-md-2 {
      flex-basis: calc(16.66666667% - 30px);
      width: calc(16.66666667% - 30px);
    }

    > .col-md-1 {
      flex-basis: calc(8.33333333% - 30px);
      width: calc(8.33333333% - 30px);
    }
  }

  @media (min-width: $screen-lg-min) {

    > .col-lg-12 {
      flex-basis: calc(100% - 30px);
      width: calc(100% - 30px);
    }

    > .col-lg-11 {
      flex-basis: calc(91.66666667% - 30px);
      width: calc(91.66666667% - 30px);
    }

    > .col-lg-10 {
      flex-basis: calc(83.33333333% - 30px);
      width: calc(83.33333333% - 30px);
    }

    > .col-lg-9 {
      flex-basis: calc(75% - 30px);
      width: calc(75% - 30px);
    }

    > .col-lg-8 {
      flex-basis: calc(66.66666667% - 30px);
      width: calc(66.66666667% - 30px);
    }

    > .col-lg-7 {
      flex-basis: calc(58.33333333% - 30px);
      width: calc(58.33333333% - 30px);
    }

    > .col-lg-6 {
      flex-basis: calc(50% - 30px);
      width: calc(50% - 30px);
    }

    > .col-lg-5 {
      flex-basis: calc(41.66666667% - 30px);
      width: calc(41.66666667% - 30px);
    }

    > .col-lg-4 {
      flex-basis: calc(33.33333333% - 30px);
      width: calc(33.33333333% - 30px);
    }

    > .col-lg-3 {
      flex-basis: calc(25% - 30px);
      width: calc(25% - 30px);
    }

    > .col-lg-2 {
      flex-basis: calc(16.66666667% - 30px);
      width: calc(16.66666667% - 30px);
    }

    > .col-lg-1 {
      flex-basis: calc(8.33333333% - 30px);
      width: calc(8.33333333% - 30px);
    }
  }

  @media (min-width: $screen-xl-min) {

    > .col-xl-12 {
      flex-basis: calc(100% - 30px);
      width: calc(100% - 30px);
    }

    > .col-xl-11 {
      flex-basis: calc(91.66666667% - 30px);
      width: calc(91.66666667% - 30px);
    }

    > .col-xl-10 {
      flex-basis: calc(83.33333333% - 30px);
      width: calc(83.33333333% - 30px);
    }

    > .col-xl-9 {
      flex-basis: calc(75% - 30px);
      width: calc(75% - 30px);
    }

    > .col-xl-8 {
      flex-basis: calc(66.66666667% - 30px);
      width: calc(66.66666667% - 30px);
    }

    > .col-xl-7 {
      flex-basis: calc(58.33333333% - 30px);
      width: calc(58.33333333% - 30px);
    }

    > .col-xl-6 {
      flex-basis: calc(50% - 30px);
      width: calc(50% - 30px);
    }

    > .col-xl-5 {
      flex-basis: calc(41.66666667% - 30px);
      width: calc(41.66666667% - 30px);
    }

    > .col-xl-4 {
      flex-basis: calc(33.33333333% - 30px);
      width: calc(33.33333333% - 30px);
    }

    > .col-xl-3 {
      flex-basis: calc(25% - 30px);
      width: calc(25% - 30px);
    }

    > .col-xl-2 {
      flex-basis: calc(16.66666667% - 30px);
      width: calc(16.66666667% - 30px);
    }

    > .col-xl-1 {
      flex-basis: calc(8.33333333% - 30px);
      width: calc(8.33333333% - 30px);
    }
  }
} // .ie .row-flex:not(.row-0)


// row 0
// .ie .row-flex.row-0 {

//   > [class^="col-"] {
//     flex-basis: calc(100% - 0);
//     width: calc(100% - 0);
//   }

//   > .col-xs-12 {
//     flex-basis: calc(100% - 0);
//     width: calc(100% - 0);
//   }

//   > .col-xs-11 {
//     flex-basis: calc(91.66666667% - 0);
//     width: calc(91.66666667% - 0);
//   }

//   > .col-xs-10 {
//     flex-basis: calc(83.33333333% - 0);
//     width: calc(83.33333333% - 0);
//   }

//   > .col-xs-9 {
//     flex-basis: calc(75% - 0);
//     width: calc(75% - 0);
//   }

//   > .col-xs-8 {
//     flex-basis: calc(66.66666667% - 0);
//     width: calc(66.66666667% - 0);
//   }

//   > .col-xs-7 {
//     flex-basis: calc(58.33333333% - 0);
//     width: calc(58.33333333% - 0);
//   }

//   > .col-xs-6 {
//     flex-basis: calc(50% - 0);
//     width: calc(50% - 0);
//   }

//   > .col-xs-5 {
//     flex-basis: calc(41.66666667% - 0);
//     width: calc(41.66666667% - 0);
//   }

//   > .col-xs-4 {
//     flex-basis: calc(33.33333333% - 0);
//     width: calc(33.33333333% - 0);
//   }

//   > .col-xs-3 {
//     flex-basis: calc(25% - 0);
//     width: calc(25% - 0);
//   }

//   > .col-xs-2 {
//     flex-basis: calc(16.66666667% - 0);
//     width: calc(16.66666667% - 0);
//   }

//   > .col-xs-1 {
//     flex-basis: calc(8.33333333% - 0);
//     width: calc(8.33333333% - 0);
//   }

//   @media (min-width: $screen-sm-min) {

//     > .col-sm-12 {
//       flex-basis: calc(100% - 0);
//       width: calc(100% - 0);
//     }

//     > .col-sm-11 {
//       flex-basis: calc(91.66666667% - 0);
//       width: calc(91.66666667% - 0);
//     }

//     > .col-sm-10 {
//       flex-basis: calc(83.33333333% - 0);
//       width: calc(83.33333333% - 0);
//     }

//     > .col-sm-9 {
//       flex-basis: calc(75% - 0);
//       width: calc(75% - 0);
//     }

//     > .col-sm-8 {
//       flex-basis: calc(66.66666667% - 0);
//       width: calc(66.66666667% - 0);
//     }

//     > .col-sm-7 {
//       flex-basis: calc(58.33333333% - 0);
//       width: calc(58.33333333% - 0);
//     }

//     > .col-sm-6 {
//       flex-basis: calc(50% - 0);
//       width: calc(50% - 0);
//     }

//     > .col-sm-5 {
//       flex-basis: calc(41.66666667% - 0);
//       width: calc(41.66666667% - 0);
//     }

//     > .col-sm-4 {
//       flex-basis: calc(33.33333333% - 0);
//       width: calc(33.33333333% - 0);
//     }

//     > .col-sm-3 {
//       flex-basis: calc(25% - 0);
//       width: calc(25% - 0);
//     }

//     > .col-sm-2 {
//       flex-basis: calc(16.66666667% - 0);
//       width: calc(16.66666667% - 0);
//     }

//     > .col-sm-1 {
//       flex-basis: calc(8.33333333% - 0);
//       width: calc(8.33333333% - 0);
//     }
//   }

//   @media (min-width: $screen-md-min) {

//     > .col-md-12 {
//       flex-basis: calc(100% - 0);
//       width: calc(100% - 0);
//     }

//     > .col-md-11 {
//       flex-basis: calc(91.66666667% - 0);
//       width: calc(91.66666667% - 0);
//     }

//     > .col-md-10 {
//       flex-basis: calc(83.33333333% - 0);
//       width: calc(83.33333333% - 0);
//     }

//     > .col-md-9 {
//       flex-basis: calc(75% - 0);
//       width: calc(75% - 0);
//     }

//     > .col-md-8 {
//       flex-basis: calc(66.66666667% - 0);
//       width: calc(66.66666667% - 0);
//     }

//     > .col-md-7 {
//       flex-basis: calc(58.33333333% - 0);
//       width: calc(58.33333333% - 0);
//     }

//     > .col-md-6 {
//       flex-basis: calc(50% - 0);
//       width: calc(50% - 0);
//     }

//     > .col-md-5 {
//       flex-basis: calc(41.66666667% - 0);
//       width: calc(41.66666667% - 0);
//     }

//     > .col-md-4 {
//       flex-basis: calc(33.33333333% - 0);
//       width: calc(33.33333333% - 0);
//     }

//     > .col-md-3 {
//       flex-basis: calc(25% - 0);
//       width: calc(25% - 0);
//     }

//     > .col-md-2 {
//       flex-basis: calc(16.66666667% - 0);
//       width: calc(16.66666667% - 0);
//     }

//     > .col-md-1 {
//       flex-basis: calc(8.33333333% - 0);
//       width: calc(8.33333333% - 0);
//     }
//   }

//   @media (min-width: $screen-lg-min) {

//     > .col-lg-12 {
//       flex-basis: calc(100% - 0);
//       width: calc(100% - 0);
//     }

//     > .col-lg-11 {
//       flex-basis: calc(91.66666667% - 0);
//       width: calc(91.66666667% - 0);
//     }

//     > .col-lg-10 {
//       flex-basis: calc(83.33333333% - 0);
//       width: calc(83.33333333% - 0);
//     }

//     > .col-lg-9 {
//       flex-basis: calc(75% - 0);
//       width: calc(75% - 0);
//     }

//     > .col-lg-8 {
//       flex-basis: calc(66.66666667% - 0);
//       width: calc(66.66666667% - 0);
//     }

//     > .col-lg-7 {
//       flex-basis: calc(58.33333333% - 0);
//       width: calc(58.33333333% - 0);
//     }

//     > .col-lg-6 {
//       flex-basis: calc(50% - 0);
//       width: calc(50% - 0);
//     }

//     > .col-lg-5 {
//       flex-basis: calc(41.66666667% - 0);
//       width: calc(41.66666667% - 0);
//     }

//     > .col-lg-4 {
//       flex-basis: calc(33.33333333% - 0);
//       width: calc(33.33333333% - 0);
//     }

//     > .col-lg-3 {
//       flex-basis: calc(25% - 0);
//       width: calc(25% - 0);
//     }

//     > .col-lg-2 {
//       flex-basis: calc(16.66666667% - 0);
//       width: calc(16.66666667% - 0);
//     }

//     > .col-lg-1 {
//       flex-basis: calc(8.33333333% - 0);
//       width: calc(8.33333333% - 0);
//     }
//   }

//   @media (min-width: $screen-xl-min) {

//     > .col-xl-12 {
//       flex-basis: calc(100% - 0);
//       width: calc(100% - 0);
//     }

//     > .col-xl-11 {
//       flex-basis: calc(91.66666667% - 0);
//       width: calc(91.66666667% - 0);
//     }

//     > .col-xl-10 {
//       flex-basis: calc(83.33333333% - 0);
//       width: calc(83.33333333% - 0);
//     }

//     > .col-xl-9 {
//       flex-basis: calc(75% - 0);
//       width: calc(75% - 0);
//     }

//     > .col-xl-8 {
//       flex-basis: calc(66.66666667% - 0);
//       width: calc(66.66666667% - 0);
//     }

//     > .col-xl-7 {
//       flex-basis: calc(58.33333333% - 0);
//       width: calc(58.33333333% - 0);
//     }

//     > .col-xl-6 {
//       flex-basis: calc(50% - 0);
//       width: calc(50% - 0);
//     }

//     > .col-xl-5 {
//       flex-basis: calc(41.66666667% - 0);
//       width: calc(41.66666667% - 0);
//     }

//     > .col-xl-4 {
//       flex-basis: calc(33.33333333% - 0);
//       width: calc(33.33333333% - 0);
//     }

//     > .col-xl-3 {
//       flex-basis: calc(25% - 0);
//       width: calc(25% - 0);
//     }

//     > .col-xl-2 {
//       flex-basis: calc(16.66666667% - 0);
//       width: calc(16.66666667% - 0);
//     }

//     > .col-xl-1 {
//       flex-basis: calc(8.33333333% - 0);
//       width: calc(8.33333333% - 0);
//     }
//   }
// } // .ie .row-flex.row-0


// row 5
.ie .row-flex.row-5 {

  > [class^="col-"] {
    flex-basis: calc(100% - 5px);
    width: calc(100% - 5px);
  }

  > .col-xs-12 {
    flex-basis: calc(100% - 5px);
    width: calc(100% - 5px);
  }

  > .col-xs-11 {
    flex-basis: calc(91.66666667% - 5px);
    width: calc(91.66666667% - 5px);
  }

  > .col-xs-10 {
    flex-basis: calc(83.33333333% - 5px);
    width: calc(83.33333333% - 5px);
  }

  > .col-xs-9 {
    flex-basis: calc(75% - 5px);
    width: calc(75% - 5px);
  }

  > .col-xs-8 {
    flex-basis: calc(66.66666667% - 5px);
    width: calc(66.66666667% - 5px);
  }

  > .col-xs-7 {
    flex-basis: calc(58.33333333% - 5px);
    width: calc(58.33333333% - 5px);
  }

  > .col-xs-6 {
    flex-basis: calc(50% - 5px);
    width: calc(50% - 5px);
  }

  > .col-xs-5 {
    flex-basis: calc(41.66666667% - 5px);
    width: calc(41.66666667% - 5px);
  }

  > .col-xs-4 {
    flex-basis: calc(33.33333333% - 5px);
    width: calc(33.33333333% - 5px);
  }

  > .col-xs-3 {
    flex-basis: calc(25% - 5px);
    width: calc(25% - 5px);
  }

  > .col-xs-2 {
    flex-basis: calc(16.66666667% - 5px);
    width: calc(16.66666667% - 5px);
  }

  > .col-xs-1 {
    flex-basis: calc(8.33333333% - 5px);
    width: calc(8.33333333% - 5px);
  }

  @media (min-width: $screen-sm-min) {

    > .col-sm-12 {
      flex-basis: calc(100% - 5px);
      width: calc(100% - 5px);
    }

    > .col-sm-11 {
      flex-basis: calc(91.66666667% - 5px);
      width: calc(91.66666667% - 5px);
    }

    > .col-sm-10 {
      flex-basis: calc(83.33333333% - 5px);
      width: calc(83.33333333% - 5px);
    }

    > .col-sm-9 {
      flex-basis: calc(75% - 5px);
      width: calc(75% - 5px);
    }

    > .col-sm-8 {
      flex-basis: calc(66.66666667% - 5px);
      width: calc(66.66666667% - 5px);
    }

    > .col-sm-7 {
      flex-basis: calc(58.33333333% - 5px);
      width: calc(58.33333333% - 5px);
    }

    > .col-sm-6 {
      flex-basis: calc(50% - 5px);
      width: calc(50% - 5px);
    }

    > .col-sm-5 {
      flex-basis: calc(41.66666667% - 5px);
      width: calc(41.66666667% - 5px);
    }

    > .col-sm-4 {
      flex-basis: calc(33.33333333% - 5px);
      width: calc(33.33333333% - 5px);
    }

    > .col-sm-3 {
      flex-basis: calc(25% - 5px);
      width: calc(25% - 5px);
    }

    > .col-sm-2 {
      flex-basis: calc(16.66666667% - 5px);
      width: calc(16.66666667% - 5px);
    }

    > .col-sm-1 {
      flex-basis: calc(8.33333333% - 5px);
      width: calc(8.33333333% - 5px);
    }
  }

  @media (min-width: $screen-md-min) {

    > .col-md-12 {
      flex-basis: calc(100% - 5px);
      width: calc(100% - 5px);
    }

    > .col-md-11 {
      flex-basis: calc(91.66666667% - 5px);
      width: calc(91.66666667% - 5px);
    }

    > .col-md-10 {
      flex-basis: calc(83.33333333% - 5px);
      width: calc(83.33333333% - 5px);
    }

    > .col-md-9 {
      flex-basis: calc(75% - 5px);
      width: calc(75% - 5px);
    }

    > .col-md-8 {
      flex-basis: calc(66.66666667% - 5px);
      width: calc(66.66666667% - 5px);
    }

    > .col-md-7 {
      flex-basis: calc(58.33333333% - 5px);
      width: calc(58.33333333% - 5px);
    }

    > .col-md-6 {
      flex-basis: calc(50% - 5px);
      width: calc(50% - 5px);
    }

    > .col-md-5 {
      flex-basis: calc(41.66666667% - 5px);
      width: calc(41.66666667% - 5px);
    }

    > .col-md-4 {
      flex-basis: calc(33.33333333% - 5px);
      width: calc(33.33333333% - 5px);
    }

    > .col-md-3 {
      flex-basis: calc(25% - 5px);
      width: calc(25% - 5px);
    }

    > .col-md-2 {
      flex-basis: calc(16.66666667% - 5px);
      width: calc(16.66666667% - 5px);
    }

    > .col-md-1 {
      flex-basis: calc(8.33333333% - 5px);
      width: calc(8.33333333% - 5px);
    }
  }

  @media (min-width: $screen-lg-min) {

    > .col-lg-12 {
      flex-basis: calc(100% - 5px);
      width: calc(100% - 5px);
    }

    > .col-lg-11 {
      flex-basis: calc(91.66666667% - 5px);
      width: calc(91.66666667% - 5px);
    }

    > .col-lg-10 {
      flex-basis: calc(83.33333333% - 5px);
      width: calc(83.33333333% - 5px);
    }

    > .col-lg-9 {
      flex-basis: calc(75% - 5px);
      width: calc(75% - 5px);
    }

    > .col-lg-8 {
      flex-basis: calc(66.66666667% - 5px);
      width: calc(66.66666667% - 5px);
    }

    > .col-lg-7 {
      flex-basis: calc(58.33333333% - 5px);
      width: calc(58.33333333% - 5px);
    }

    > .col-lg-6 {
      flex-basis: calc(50% - 5px);
      width: calc(50% - 5px);
    }

    > .col-lg-5 {
      flex-basis: calc(41.66666667% - 5px);
      width: calc(41.66666667% - 5px);
    }

    > .col-lg-4 {
      flex-basis: calc(33.33333333% - 5px);
      width: calc(33.33333333% - 5px);
    }

    > .col-lg-3 {
      flex-basis: calc(25% - 5px);
      width: calc(25% - 5px);
    }

    > .col-lg-2 {
      flex-basis: calc(16.66666667% - 5px);
      width: calc(16.66666667% - 5px);
    }

    > .col-lg-1 {
      flex-basis: calc(8.33333333% - 5px);
      width: calc(8.33333333% - 5px);
    }
  }

  @media (min-width: $screen-xl-min) {

    > .col-xl-12 {
      flex-basis: calc(100% - 5px);
      width: calc(100% - 5px);
    }

    > .col-xl-11 {
      flex-basis: calc(91.66666667% - 5px);
      width: calc(91.66666667% - 5px);
    }

    > .col-xl-10 {
      flex-basis: calc(83.33333333% - 5px);
      width: calc(83.33333333% - 5px);
    }

    > .col-xl-9 {
      flex-basis: calc(75% - 5px);
      width: calc(75% - 5px);
    }

    > .col-xl-8 {
      flex-basis: calc(66.66666667% - 5px);
      width: calc(66.66666667% - 5px);
    }

    > .col-xl-7 {
      flex-basis: calc(58.33333333% - 5px);
      width: calc(58.33333333% - 5px);
    }

    > .col-xl-6 {
      flex-basis: calc(50% - 5px);
      width: calc(50% - 5px);
    }

    > .col-xl-5 {
      flex-basis: calc(41.66666667% - 5px);
      width: calc(41.66666667% - 5px);
    }

    > .col-xl-4 {
      flex-basis: calc(33.33333333% - 5px);
      width: calc(33.33333333% - 5px);
    }

    > .col-xl-3 {
      flex-basis: calc(25% - 5px);
      width: calc(25% - 5px);
    }

    > .col-xl-2 {
      flex-basis: calc(16.66666667% - 5px);
      width: calc(16.66666667% - 5px);
    }

    > .col-xl-1 {
      flex-basis: calc(8.33333333% - 5px);
      width: calc(8.33333333% - 5px);
    }
  }
} // .ie .row-flex.row-5


// row 10
.ie .row-flex.row-10 {

  > [class^="col-"] {
    flex-basis: calc(100% - 10px);
    width: calc(100% - 10px);
  }

  > .col-xs-12 {
    flex-basis: calc(100% - 10px);
    width: calc(100% - 10px);
  }

  > .col-xs-11 {
    flex-basis: calc(91.66666667% - 10px);
    width: calc(91.66666667% - 10px);
  }

  > .col-xs-10 {
    flex-basis: calc(83.33333333% - 10px);
    width: calc(83.33333333% - 10px);
  }

  > .col-xs-9 {
    flex-basis: calc(75% - 10px);
    width: calc(75% - 10px);
  }

  > .col-xs-8 {
    flex-basis: calc(66.66666667% - 10px);
    width: calc(66.66666667% - 10px);
  }

  > .col-xs-7 {
    flex-basis: calc(58.33333333% - 10px);
    width: calc(58.33333333% - 10px);
  }

  > .col-xs-6 {
    flex-basis: calc(50% - 10px);
    width: calc(50% - 10px);
  }

  > .col-xs-5 {
    flex-basis: calc(41.66666667% - 10px);
    width: calc(41.66666667% - 10px);
  }

  > .col-xs-4 {
    flex-basis: calc(33.33333333% - 10px);
    width: calc(33.33333333% - 10px);
  }

  > .col-xs-3 {
    flex-basis: calc(25% - 10px);
    width: calc(25% - 10px);
  }

  > .col-xs-2 {
    flex-basis: calc(16.66666667% - 10px);
    width: calc(16.66666667% - 10px);
  }

  > .col-xs-1 {
    flex-basis: calc(8.33333333% - 10px);
    width: calc(8.33333333% - 10px);
  }

  @media (min-width: $screen-sm-min) {

    > .col-sm-12 {
      flex-basis: calc(100% - 10px);
      width: calc(100% - 10px);
    }

    > .col-sm-11 {
      flex-basis: calc(91.66666667% - 10px);
      width: calc(91.66666667% - 10px);
    }

    > .col-sm-10 {
      flex-basis: calc(83.33333333% - 10px);
      width: calc(83.33333333% - 10px);
    }

    > .col-sm-9 {
      flex-basis: calc(75% - 10px);
      width: calc(75% - 10px);
    }

    > .col-sm-8 {
      flex-basis: calc(66.66666667% - 10px);
      width: calc(66.66666667% - 10px);
    }

    > .col-sm-7 {
      flex-basis: calc(58.33333333% - 10px);
      width: calc(58.33333333% - 10px);
    }

    > .col-sm-6 {
      flex-basis: calc(50% - 10px);
      width: calc(50% - 10px);
    }

    > .col-sm-5 {
      flex-basis: calc(41.66666667% - 10px);
      width: calc(41.66666667% - 10px);
    }

    > .col-sm-4 {
      flex-basis: calc(33.33333333% - 10px);
      width: calc(33.33333333% - 10px);
    }

    > .col-sm-3 {
      flex-basis: calc(25% - 10px);
      width: calc(25% - 10px);
    }

    > .col-sm-2 {
      flex-basis: calc(16.66666667% - 10px);
      width: calc(16.66666667% - 10px);
    }

    > .col-sm-1 {
      flex-basis: calc(8.33333333% - 10px);
      width: calc(8.33333333% - 10px);
    }
  }

  @media (min-width: $screen-md-min) {

    > .col-md-12 {
      flex-basis: calc(100% - 10px);
      width: calc(100% - 10px);
    }

    > .col-md-11 {
      flex-basis: calc(91.66666667% - 10px);
      width: calc(91.66666667% - 10px);
    }

    > .col-md-10 {
      flex-basis: calc(83.33333333% - 10px);
      width: calc(83.33333333% - 10px);
    }

    > .col-md-9 {
      flex-basis: calc(75% - 10px);
      width: calc(75% - 10px);
    }

    > .col-md-8 {
      flex-basis: calc(66.66666667% - 10px);
      width: calc(66.66666667% - 10px);
    }

    > .col-md-7 {
      flex-basis: calc(58.33333333% - 10px);
      width: calc(58.33333333% - 10px);
    }

    > .col-md-6 {
      flex-basis: calc(50% - 10px);
      width: calc(50% - 10px);
    }

    > .col-md-5 {
      flex-basis: calc(41.66666667% - 10px);
      width: calc(41.66666667% - 10px);
    }

    > .col-md-4 {
      flex-basis: calc(33.33333333% - 10px);
      width: calc(33.33333333% - 10px);
    }

    > .col-md-3 {
      flex-basis: calc(25% - 10px);
      width: calc(25% - 10px);
    }

    > .col-md-2 {
      flex-basis: calc(16.66666667% - 10px);
      width: calc(16.66666667% - 10px);
    }

    > .col-md-1 {
      flex-basis: calc(8.33333333% - 10px);
      width: calc(8.33333333% - 10px);
    }
  }

  @media (min-width: $screen-lg-min) {

    > .col-lg-12 {
      flex-basis: calc(100% - 10px);
      width: calc(100% - 10px);
    }

    > .col-lg-11 {
      flex-basis: calc(91.66666667% - 10px);
      width: calc(91.66666667% - 10px);
    }

    > .col-lg-10 {
      flex-basis: calc(83.33333333% - 10px);
      width: calc(83.33333333% - 10px);
    }

    > .col-lg-9 {
      flex-basis: calc(75% - 10px);
      width: calc(75% - 10px);
    }

    > .col-lg-8 {
      flex-basis: calc(66.66666667% - 10px);
      width: calc(66.66666667% - 10px);
    }

    > .col-lg-7 {
      flex-basis: calc(58.33333333% - 10px);
      width: calc(58.33333333% - 10px);
    }

    > .col-lg-6 {
      flex-basis: calc(50% - 10px);
      width: calc(50% - 10px);
    }

    > .col-lg-5 {
      flex-basis: calc(41.66666667% - 10px);
      width: calc(41.66666667% - 10px);
    }

    > .col-lg-4 {
      flex-basis: calc(33.33333333% - 10px);
      width: calc(33.33333333% - 10px);
    }

    > .col-lg-3 {
      flex-basis: calc(25% - 10px);
      width: calc(25% - 10px);
    }

    > .col-lg-2 {
      flex-basis: calc(16.66666667% - 10px);
      width: calc(16.66666667% - 10px);
    }

    > .col-lg-1 {
      flex-basis: calc(8.33333333% - 10px);
      width: calc(8.33333333% - 10px);
    }
  }

  @media (min-width: $screen-xl-min) {

    > .col-xl-12 {
      flex-basis: calc(100% - 10px);
      width: calc(100% - 10px);
    }

    > .col-xl-11 {
      flex-basis: calc(91.66666667% - 10px);
      width: calc(91.66666667% - 10px);
    }

    > .col-xl-10 {
      flex-basis: calc(83.33333333% - 10px);
      width: calc(83.33333333% - 10px);
    }

    > .col-xl-9 {
      flex-basis: calc(75% - 10px);
      width: calc(75% - 10px);
    }

    > .col-xl-8 {
      flex-basis: calc(66.66666667% - 10px);
      width: calc(66.66666667% - 10px);
    }

    > .col-xl-7 {
      flex-basis: calc(58.33333333% - 10px);
      width: calc(58.33333333% - 10px);
    }

    > .col-xl-6 {
      flex-basis: calc(50% - 10px);
      width: calc(50% - 10px);
    }

    > .col-xl-5 {
      flex-basis: calc(41.66666667% - 10px);
      width: calc(41.66666667% - 10px);
    }

    > .col-xl-4 {
      flex-basis: calc(33.33333333% - 10px);
      width: calc(33.33333333% - 10px);
    }

    > .col-xl-3 {
      flex-basis: calc(25% - 10px);
      width: calc(25% - 10px);
    }

    > .col-xl-2 {
      flex-basis: calc(16.66666667% - 10px);
      width: calc(16.66666667% - 10px);
    }

    > .col-xl-1 {
      flex-basis: calc(8.33333333% - 10px);
      width: calc(8.33333333% - 10px);
    }
  }
} // .ie .row-flex.row-10


// row 15
.ie .row-flex.row-15 {

  > [class^="col-"] {
    flex-basis: calc(100% - 15px);
    width: calc(100% - 15px);
  }

  > .col-xs-12 {
    flex-basis: calc(100% - 15px);
    width: calc(100% - 15px);
  }

  > .col-xs-11 {
    flex-basis: calc(91.66666667% - 15px);
    width: calc(91.66666667% - 15px);
  }

  > .col-xs-10 {
    flex-basis: calc(83.33333333% - 15px);
    width: calc(83.33333333% - 15px);
  }

  > .col-xs-9 {
    flex-basis: calc(75% - 15px);
    width: calc(75% - 15px);
  }

  > .col-xs-8 {
    flex-basis: calc(66.66666667% - 15px);
    width: calc(66.66666667% - 15px);
  }

  > .col-xs-7 {
    flex-basis: calc(58.33333333% - 15px);
    width: calc(58.33333333% - 15px);
  }

  > .col-xs-6 {
    flex-basis: calc(50% - 15px);
    width: calc(50% - 15px);
  }

  > .col-xs-5 {
    flex-basis: calc(41.66666667% - 15px);
    width: calc(41.66666667% - 15px);
  }

  > .col-xs-4 {
    flex-basis: calc(33.33333333% - 15px);
    width: calc(33.33333333% - 15px);
  }

  > .col-xs-3 {
    flex-basis: calc(25% - 15px);
    width: calc(25% - 15px);
  }

  > .col-xs-2 {
    flex-basis: calc(16.66666667% - 15px);
    width: calc(16.66666667% - 15px);
  }

  > .col-xs-1 {
    flex-basis: calc(8.33333333% - 15px);
    width: calc(8.33333333% - 15px);
  }

  @media (min-width: $screen-sm-min) {

    > .col-sm-12 {
      flex-basis: calc(100% - 15px);
      width: calc(100% - 15px);
    }

    > .col-sm-11 {
      flex-basis: calc(91.66666667% - 15px);
      width: calc(91.66666667% - 15px);
    }

    > .col-sm-10 {
      flex-basis: calc(83.33333333% - 15px);
      width: calc(83.33333333% - 15px);
    }

    > .col-sm-9 {
      flex-basis: calc(75% - 15px);
      width: calc(75% - 15px);
    }

    > .col-sm-8 {
      flex-basis: calc(66.66666667% - 15px);
      width: calc(66.66666667% - 15px);
    }

    > .col-sm-7 {
      flex-basis: calc(58.33333333% - 15px);
      width: calc(58.33333333% - 15px);
    }

    > .col-sm-6 {
      flex-basis: calc(50% - 15px);
      width: calc(50% - 15px);
    }

    > .col-sm-5 {
      flex-basis: calc(41.66666667% - 15px);
      width: calc(41.66666667% - 15px);
    }

    > .col-sm-4 {
      flex-basis: calc(33.33333333% - 15px);
      width: calc(33.33333333% - 15px);
    }

    > .col-sm-3 {
      flex-basis: calc(25% - 15px);
      width: calc(25% - 15px);
    }

    > .col-sm-2 {
      flex-basis: calc(16.66666667% - 15px);
      width: calc(16.66666667% - 15px);
    }

    > .col-sm-1 {
      flex-basis: calc(8.33333333% - 15px);
      width: calc(8.33333333% - 15px);
    }
  }

  @media (min-width: $screen-md-min) {

    > .col-md-12 {
      flex-basis: calc(100% - 15px);
      width: calc(100% - 15px);
    }

    > .col-md-11 {
      flex-basis: calc(91.66666667% - 15px);
      width: calc(91.66666667% - 15px);
    }

    > .col-md-10 {
      flex-basis: calc(83.33333333% - 15px);
      width: calc(83.33333333% - 15px);
    }

    > .col-md-9 {
      flex-basis: calc(75% - 15px);
      width: calc(75% - 15px);
    }

    > .col-md-8 {
      flex-basis: calc(66.66666667% - 15px);
      width: calc(66.66666667% - 15px);
    }

    > .col-md-7 {
      flex-basis: calc(58.33333333% - 15px);
      width: calc(58.33333333% - 15px);
    }

    > .col-md-6 {
      flex-basis: calc(50% - 15px);
      width: calc(50% - 15px);
    }

    > .col-md-5 {
      flex-basis: calc(41.66666667% - 15px);
      width: calc(41.66666667% - 15px);
    }

    > .col-md-4 {
      flex-basis: calc(33.33333333% - 15px);
      width: calc(33.33333333% - 15px);
    }

    > .col-md-3 {
      flex-basis: calc(25% - 15px);
      width: calc(25% - 15px);
    }

    > .col-md-2 {
      flex-basis: calc(16.66666667% - 15px);
      width: calc(16.66666667% - 15px);
    }

    > .col-md-1 {
      flex-basis: calc(8.33333333% - 15px);
      width: calc(8.33333333% - 15px);
    }
  }

  @media (min-width: $screen-lg-min) {

    > .col-lg-12 {
      flex-basis: calc(100% - 15px);
      width: calc(100% - 15px);
    }

    > .col-lg-11 {
      flex-basis: calc(91.66666667% - 15px);
      width: calc(91.66666667% - 15px);
    }

    > .col-lg-10 {
      flex-basis: calc(83.33333333% - 15px);
      width: calc(83.33333333% - 15px);
    }

    > .col-lg-9 {
      flex-basis: calc(75% - 15px);
      width: calc(75% - 15px);
    }

    > .col-lg-8 {
      flex-basis: calc(66.66666667% - 15px);
      width: calc(66.66666667% - 15px);
    }

    > .col-lg-7 {
      flex-basis: calc(58.33333333% - 15px);
      width: calc(58.33333333% - 15px);
    }

    > .col-lg-6 {
      flex-basis: calc(50% - 15px);
      width: calc(50% - 15px);
    }

    > .col-lg-5 {
      flex-basis: calc(41.66666667% - 15px);
      width: calc(41.66666667% - 15px);
    }

    > .col-lg-4 {
      flex-basis: calc(33.33333333% - 15px);
      width: calc(33.33333333% - 15px);
    }

    > .col-lg-3 {
      flex-basis: calc(25% - 15px);
      width: calc(25% - 15px);
    }

    > .col-lg-2 {
      flex-basis: calc(16.66666667% - 15px);
      width: calc(16.66666667% - 15px);
    }

    > .col-lg-1 {
      flex-basis: calc(8.33333333% - 15px);
      width: calc(8.33333333% - 15px);
    }
  }

  @media (min-width: $screen-xl-min) {

    > .col-xl-12 {
      flex-basis: calc(100% - 15px);
      width: calc(100% - 15px);
    }

    > .col-xl-11 {
      flex-basis: calc(91.66666667% - 15px);
      width: calc(91.66666667% - 15px);
    }

    > .col-xl-10 {
      flex-basis: calc(83.33333333% - 15px);
      width: calc(83.33333333% - 15px);
    }

    > .col-xl-9 {
      flex-basis: calc(75% - 15px);
      width: calc(75% - 15px);
    }

    > .col-xl-8 {
      flex-basis: calc(66.66666667% - 15px);
      width: calc(66.66666667% - 15px);
    }

    > .col-xl-7 {
      flex-basis: calc(58.33333333% - 15px);
      width: calc(58.33333333% - 15px);
    }

    > .col-xl-6 {
      flex-basis: calc(50% - 15px);
      width: calc(50% - 15px);
    }

    > .col-xl-5 {
      flex-basis: calc(41.66666667% - 15px);
      width: calc(41.66666667% - 15px);
    }

    > .col-xl-4 {
      flex-basis: calc(33.33333333% - 15px);
      width: calc(33.33333333% - 15px);
    }

    > .col-xl-3 {
      flex-basis: calc(25% - 15px);
      width: calc(25% - 15px);
    }

    > .col-xl-2 {
      flex-basis: calc(16.66666667% - 15px);
      width: calc(16.66666667% - 15px);
    }

    > .col-xl-1 {
      flex-basis: calc(8.33333333% - 15px);
      width: calc(8.33333333% - 15px);
    }
  }
} // .ie .row-flex.row-15


// row 20
.ie .row-flex.row-20 {

  > [class^="col-"] {
    flex-basis: calc(100% - 20px);
    width: calc(100% - 20px);
  }

  > .col-xs-12 {
    flex-basis: calc(100% - 20px);
    width: calc(100% - 20px);
  }

  > .col-xs-11 {
    flex-basis: calc(91.66666667% - 20px);
    width: calc(91.66666667% - 20px);
  }

  > .col-xs-10 {
    flex-basis: calc(83.33333333% - 20px);
    width: calc(83.33333333% - 20px);
  }

  > .col-xs-9 {
    flex-basis: calc(75% - 20px);
    width: calc(75% - 20px);
  }

  > .col-xs-8 {
    flex-basis: calc(66.66666667% - 20px);
    width: calc(66.66666667% - 20px);
  }

  > .col-xs-7 {
    flex-basis: calc(58.33333333% - 20px);
    width: calc(58.33333333% - 20px);
  }

  > .col-xs-6 {
    flex-basis: calc(50% - 20px);
    width: calc(50% - 20px);
  }

  > .col-xs-5 {
    flex-basis: calc(41.66666667% - 20px);
    width: calc(41.66666667% - 20px);
  }

  > .col-xs-4 {
    flex-basis: calc(33.33333333% - 20px);
    width: calc(33.33333333% - 20px);
  }

  > .col-xs-3 {
    flex-basis: calc(25% - 20px);
    width: calc(25% - 20px);
  }

  > .col-xs-2 {
    flex-basis: calc(16.66666667% - 20px);
    width: calc(16.66666667% - 20px);
  }

  > .col-xs-1 {
    flex-basis: calc(8.33333333% - 20px);
    width: calc(8.33333333% - 20px);
  }

  @media (min-width: $screen-sm-min) {

    > .col-sm-12 {
      flex-basis: calc(100% - 20px);
      width: calc(100% - 20px);
    }

    > .col-sm-11 {
      flex-basis: calc(91.66666667% - 20px);
      width: calc(91.66666667% - 20px);
    }

    > .col-sm-10 {
      flex-basis: calc(83.33333333% - 20px);
      width: calc(83.33333333% - 20px);
    }

    > .col-sm-9 {
      flex-basis: calc(75% - 20px);
      width: calc(75% - 20px);
    }

    > .col-sm-8 {
      flex-basis: calc(66.66666667% - 20px);
      width: calc(66.66666667% - 20px);
    }

    > .col-sm-7 {
      flex-basis: calc(58.33333333% - 20px);
      width: calc(58.33333333% - 20px);
    }

    > .col-sm-6 {
      flex-basis: calc(50% - 20px);
      width: calc(50% - 20px);
    }

    > .col-sm-5 {
      flex-basis: calc(41.66666667% - 20px);
      width: calc(41.66666667% - 20px);
    }

    > .col-sm-4 {
      flex-basis: calc(33.33333333% - 20px);
      width: calc(33.33333333% - 20px);
    }

    > .col-sm-3 {
      flex-basis: calc(25% - 20px);
      width: calc(25% - 20px);
    }

    > .col-sm-2 {
      flex-basis: calc(16.66666667% - 20px);
      width: calc(16.66666667% - 20px);
    }

    > .col-sm-1 {
      flex-basis: calc(8.33333333% - 20px);
      width: calc(8.33333333% - 20px);
    }
  }

  @media (min-width: $screen-md-min) {

    > .col-md-12 {
      flex-basis: calc(100% - 20px);
      width: calc(100% - 20px);
    }

    > .col-md-11 {
      flex-basis: calc(91.66666667% - 20px);
      width: calc(91.66666667% - 20px);
    }

    > .col-md-10 {
      flex-basis: calc(83.33333333% - 20px);
      width: calc(83.33333333% - 20px);
    }

    > .col-md-9 {
      flex-basis: calc(75% - 20px);
      width: calc(75% - 20px);
    }

    > .col-md-8 {
      flex-basis: calc(66.66666667% - 20px);
      width: calc(66.66666667% - 20px);
    }

    > .col-md-7 {
      flex-basis: calc(58.33333333% - 20px);
      width: calc(58.33333333% - 20px);
    }

    > .col-md-6 {
      flex-basis: calc(50% - 20px);
      width: calc(50% - 20px);
    }

    > .col-md-5 {
      flex-basis: calc(41.66666667% - 20px);
      width: calc(41.66666667% - 20px);
    }

    > .col-md-4 {
      flex-basis: calc(33.33333333% - 20px);
      width: calc(33.33333333% - 20px);
    }

    > .col-md-3 {
      flex-basis: calc(25% - 20px);
      width: calc(25% - 20px);
    }

    > .col-md-2 {
      flex-basis: calc(16.66666667% - 20px);
      width: calc(16.66666667% - 20px);
    }

    > .col-md-1 {
      flex-basis: calc(8.33333333% - 20px);
      width: calc(8.33333333% - 20px);
    }
  }

  @media (min-width: $screen-lg-min) {

    > .col-lg-12 {
      flex-basis: calc(100% - 20px);
      width: calc(100% - 20px);
    }

    > .col-lg-11 {
      flex-basis: calc(91.66666667% - 20px);
      width: calc(91.66666667% - 20px);
    }

    > .col-lg-10 {
      flex-basis: calc(83.33333333% - 20px);
      width: calc(83.33333333% - 20px);
    }

    > .col-lg-9 {
      flex-basis: calc(75% - 20px);
      width: calc(75% - 20px);
    }

    > .col-lg-8 {
      flex-basis: calc(66.66666667% - 20px);
      width: calc(66.66666667% - 20px);
    }

    > .col-lg-7 {
      flex-basis: calc(58.33333333% - 20px);
      width: calc(58.33333333% - 20px);
    }

    > .col-lg-6 {
      flex-basis: calc(50% - 20px);
      width: calc(50% - 20px);
    }

    > .col-lg-5 {
      flex-basis: calc(41.66666667% - 20px);
      width: calc(41.66666667% - 20px);
    }

    > .col-lg-4 {
      flex-basis: calc(33.33333333% - 20px);
      width: calc(33.33333333% - 20px);
    }

    > .col-lg-3 {
      flex-basis: calc(25% - 20px);
      width: calc(25% - 20px);
    }

    > .col-lg-2 {
      flex-basis: calc(16.66666667% - 20px);
      width: calc(16.66666667% - 20px);
    }

    > .col-lg-1 {
      flex-basis: calc(8.33333333% - 20px);
      width: calc(8.33333333% - 20px);
    }
  }

  @media (min-width: $screen-xl-min) {

    > .col-xl-12 {
      flex-basis: calc(100% - 20px);
      width: calc(100% - 20px);
    }

    > .col-xl-11 {
      flex-basis: calc(91.66666667% - 20px);
      width: calc(91.66666667% - 20px);
    }

    > .col-xl-10 {
      flex-basis: calc(83.33333333% - 20px);
      width: calc(83.33333333% - 20px);
    }

    > .col-xl-9 {
      flex-basis: calc(75% - 20px);
      width: calc(75% - 20px);
    }

    > .col-xl-8 {
      flex-basis: calc(66.66666667% - 20px);
      width: calc(66.66666667% - 20px);
    }

    > .col-xl-7 {
      flex-basis: calc(58.33333333% - 20px);
      width: calc(58.33333333% - 20px);
    }

    > .col-xl-6 {
      flex-basis: calc(50% - 20px);
      width: calc(50% - 20px);
    }

    > .col-xl-5 {
      flex-basis: calc(41.66666667% - 20px);
      width: calc(41.66666667% - 20px);
    }

    > .col-xl-4 {
      flex-basis: calc(33.33333333% - 20px);
      width: calc(33.33333333% - 20px);
    }

    > .col-xl-3 {
      flex-basis: calc(25% - 20px);
      width: calc(25% - 20px);
    }

    > .col-xl-2 {
      flex-basis: calc(16.66666667% - 20px);
      width: calc(16.66666667% - 20px);
    }

    > .col-xl-1 {
      flex-basis: calc(8.33333333% - 20px);
      width: calc(8.33333333% - 20px);
    }
  }
} // .ie .row-flex.row-20


// row 25
.ie .row-flex.row-25 {

  > [class^="col-"] {
    flex-basis: calc(100% - 25px);
    width: calc(100% - 25px);
  }

  > .col-xs-12 {
    flex-basis: calc(100% - 25px);
    width: calc(100% - 25px);
  }

  > .col-xs-11 {
    flex-basis: calc(91.66666667% - 25px);
    width: calc(91.66666667% - 25px);
  }

  > .col-xs-10 {
    flex-basis: calc(83.33333333% - 25px);
    width: calc(83.33333333% - 25px);
  }

  > .col-xs-9 {
    flex-basis: calc(75% - 25px);
    width: calc(75% - 25px);
  }

  > .col-xs-8 {
    flex-basis: calc(66.66666667% - 25px);
    width: calc(66.66666667% - 25px);
  }

  > .col-xs-7 {
    flex-basis: calc(58.33333333% - 25px);
    width: calc(58.33333333% - 25px);
  }

  > .col-xs-6 {
    flex-basis: calc(50% - 25px);
    width: calc(50% - 25px);
  }

  > .col-xs-5 {
    flex-basis: calc(41.66666667% - 25px);
    width: calc(41.66666667% - 25px);
  }

  > .col-xs-4 {
    flex-basis: calc(33.33333333% - 25px);
    width: calc(33.33333333% - 25px);
  }

  > .col-xs-3 {
    flex-basis: calc(25% - 25px);
    width: calc(25% - 25px);
  }

  > .col-xs-2 {
    flex-basis: calc(16.66666667% - 25px);
    width: calc(16.66666667% - 25px);
  }

  > .col-xs-1 {
    flex-basis: calc(8.33333333% - 25px);
    width: calc(8.33333333% - 25px);
  }

  @media (min-width: $screen-sm-min) {

    > .col-sm-12 {
      flex-basis: calc(100% - 25px);
      width: calc(100% - 25px);
    }

    > .col-sm-11 {
      flex-basis: calc(91.66666667% - 25px);
      width: calc(91.66666667% - 25px);
    }

    > .col-sm-10 {
      flex-basis: calc(83.33333333% - 25px);
      width: calc(83.33333333% - 25px);
    }

    > .col-sm-9 {
      flex-basis: calc(75% - 25px);
      width: calc(75% - 25px);
    }

    > .col-sm-8 {
      flex-basis: calc(66.66666667% - 25px);
      width: calc(66.66666667% - 25px);
    }

    > .col-sm-7 {
      flex-basis: calc(58.33333333% - 25px);
      width: calc(58.33333333% - 25px);
    }

    > .col-sm-6 {
      flex-basis: calc(50% - 25px);
      width: calc(50% - 25px);
    }

    > .col-sm-5 {
      flex-basis: calc(41.66666667% - 25px);
      width: calc(41.66666667% - 25px);
    }

    > .col-sm-4 {
      flex-basis: calc(33.33333333% - 25px);
      width: calc(33.33333333% - 25px);
    }

    > .col-sm-3 {
      flex-basis: calc(25% - 25px);
      width: calc(25% - 25px);
    }

    > .col-sm-2 {
      flex-basis: calc(16.66666667% - 25px);
      width: calc(16.66666667% - 25px);
    }

    > .col-sm-1 {
      flex-basis: calc(8.33333333% - 25px);
      width: calc(8.33333333% - 25px);
    }
  }

  @media (min-width: $screen-md-min) {

    > .col-md-12 {
      flex-basis: calc(100% - 25px);
      width: calc(100% - 25px);
    }

    > .col-md-11 {
      flex-basis: calc(91.66666667% - 25px);
      width: calc(91.66666667% - 25px);
    }

    > .col-md-10 {
      flex-basis: calc(83.33333333% - 25px);
      width: calc(83.33333333% - 25px);
    }

    > .col-md-9 {
      flex-basis: calc(75% - 25px);
      width: calc(75% - 25px);
    }

    > .col-md-8 {
      flex-basis: calc(66.66666667% - 25px);
      width: calc(66.66666667% - 25px);
    }

    > .col-md-7 {
      flex-basis: calc(58.33333333% - 25px);
      width: calc(58.33333333% - 25px);
    }

    > .col-md-6 {
      flex-basis: calc(50% - 25px);
      width: calc(50% - 25px);
    }

    > .col-md-5 {
      flex-basis: calc(41.66666667% - 25px);
      width: calc(41.66666667% - 25px);
    }

    > .col-md-4 {
      flex-basis: calc(33.33333333% - 25px);
      width: calc(33.33333333% - 25px);
    }

    > .col-md-3 {
      flex-basis: calc(25% - 25px);
      width: calc(25% - 25px);
    }

    > .col-md-2 {
      flex-basis: calc(16.66666667% - 25px);
      width: calc(16.66666667% - 25px);
    }

    > .col-md-1 {
      flex-basis: calc(8.33333333% - 25px);
      width: calc(8.33333333% - 25px);
    }
  }

  @media (min-width: $screen-lg-min) {

    > .col-lg-12 {
      flex-basis: calc(100% - 25px);
      width: calc(100% - 25px);
    }

    > .col-lg-11 {
      flex-basis: calc(91.66666667% - 25px);
      width: calc(91.66666667% - 25px);
    }

    > .col-lg-10 {
      flex-basis: calc(83.33333333% - 25px);
      width: calc(83.33333333% - 25px);
    }

    > .col-lg-9 {
      flex-basis: calc(75% - 25px);
      width: calc(75% - 25px);
    }

    > .col-lg-8 {
      flex-basis: calc(66.66666667% - 25px);
      width: calc(66.66666667% - 25px);
    }

    > .col-lg-7 {
      flex-basis: calc(58.33333333% - 25px);
      width: calc(58.33333333% - 25px);
    }

    > .col-lg-6 {
      flex-basis: calc(50% - 25px);
      width: calc(50% - 25px);
    }

    > .col-lg-5 {
      flex-basis: calc(41.66666667% - 25px);
      width: calc(41.66666667% - 25px);
    }

    > .col-lg-4 {
      flex-basis: calc(33.33333333% - 25px);
      width: calc(33.33333333% - 25px);
    }

    > .col-lg-3 {
      flex-basis: calc(25% - 25px);
      width: calc(25% - 25px);
    }

    > .col-lg-2 {
      flex-basis: calc(16.66666667% - 25px);
      width: calc(16.66666667% - 25px);
    }

    > .col-lg-1 {
      flex-basis: calc(8.33333333% - 25px);
      width: calc(8.33333333% - 25px);
    }
  }

  @media (min-width: $screen-xl-min) {

    > .col-xl-12 {
      flex-basis: calc(100% - 25px);
      width: calc(100% - 25px);
    }

    > .col-xl-11 {
      flex-basis: calc(91.66666667% - 25px);
      width: calc(91.66666667% - 25px);
    }

    > .col-xl-10 {
      flex-basis: calc(83.33333333% - 25px);
      width: calc(83.33333333% - 25px);
    }

    > .col-xl-9 {
      flex-basis: calc(75% - 25px);
      width: calc(75% - 25px);
    }

    > .col-xl-8 {
      flex-basis: calc(66.66666667% - 25px);
      width: calc(66.66666667% - 25px);
    }

    > .col-xl-7 {
      flex-basis: calc(58.33333333% - 25px);
      width: calc(58.33333333% - 25px);
    }

    > .col-xl-6 {
      flex-basis: calc(50% - 25px);
      width: calc(50% - 25px);
    }

    > .col-xl-5 {
      flex-basis: calc(41.66666667% - 25px);
      width: calc(41.66666667% - 25px);
    }

    > .col-xl-4 {
      flex-basis: calc(33.33333333% - 25px);
      width: calc(33.33333333% - 25px);
    }

    > .col-xl-3 {
      flex-basis: calc(25% - 25px);
      width: calc(25% - 25px);
    }

    > .col-xl-2 {
      flex-basis: calc(16.66666667% - 25px);
      width: calc(16.66666667% - 25px);
    }

    > .col-xl-1 {
      flex-basis: calc(8.33333333% - 25px);
      width: calc(8.33333333% - 25px);
    }
  }
} // .ie .row-flex.row-25




// // Document Row
// .main {
//   @include make-sm-column($main-sm-columns);

//   .sidebar-primary & {
//     @include make-sm-column($main-sm-columns - $sidebar-sm-columns);
//   }
// }

// .sidebar {
//   @include make-sm-column($sidebar-sm-columns);
// }
