.search {

  .page-header {

    h1 {
      margin: 0;
    }

    .lead-lg {
      margin-bottom: 15px;

      i {
        color: $brand-primary;
      }
    }
  }

  .serp-results {}

  .relevanssi-query-term {
    color: #000;
    font-weight: 800;
  }

  .serp {
    margin-bottom: 20px;
    padding-bottom: 20px;
    border-bottom: 1px dotted $gris-medio;

    @media screen and (min-width: $screen-sm-min) {
      display: flex;
    }

    .entry-image {
      margin-bottom: 15px;

      @media screen and (min-width: $screen-sm-min) {
        flex: 0 0 160px;
        padding-right: 15px;
      }
    }

    .entry-text {

      .entry-type {
        font-weight: 700;
      }

      .entry-title {
        margin: 0;

        a {
          display: block;
        }
      }

      p {
        margin-bottom: 0;
      }

      .url {
        display: block;
        margin-bottom: 15px;
        word-break: break-all;
        font-weight: 800;
        color: $text-muted;

        &:hover,
        &:focus {
          color: $link-hover-color;
        }
      }

      time {
        float: left;
        color: $text-muted;
      }

      .divider {
        float: left;
        color: $text-muted;
        margin: 0 .25em;
      }

      .btn {
        margin-top: 15px;
      }
    }
  }

  header + .serp {
    margin-top: 0;
  }
}
