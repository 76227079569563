#scrollUp {
  // visibility: hidden; // para ocultar en mobile
  position: fixed;
  z-index: $zindex-navbar-fixed;
  right: 10px;
  bottom: 10px;
  width: 50px;
  height: 50px;
  padding: 0;
  border-radius: 50%;
  text-align: center;
  background-color: rgba(#FFF, 0.75);
  color: $link-color;
  transform-origin: 100% 100%;
  transition: all 300ms $bezier; // headroom

  @media screen and (min-width: $screen-sm-min) {
    // visibility: visible;
    right: 15px;
    bottom: 15px;
  }

  @media screen and (min-width: $screen-lg-min) {
    right: 30px;
    bottom: 30px;
  }

  i {
    line-height: 40px;
    transition: all 200ms $bezier;
  }

  span {
    display: block;
    transition: all 200ms $bezier;
  }

  // svg {
  //   width: 18px;
  //   height: auto;
  //   transition: all 200ms $bezier;

  //   .ie & {
  //     height: 26px;
  //   }

  //   // path {
  //   //   fill: $link-color;
  //   // }
  // }

  // span {
  //   display: block;
  //   transition: all 200ms $bezier;
  // }

  &:hover,
  &:focus {
    text-decoration: none;
    background-color: #FFF;

    i {
      transform: translateY(-5px);
    }

    // svg {
    //   transform: translateY(-5px);
    // }

    // span {
    //   display: block;
    // }
  }

  // Ocultar cuando se abre un modal
  .modal-open & {
    display: none !important;
  }
}
