// Awesome Bootstrap Checkbox
// https://github.com/flatlogic/awesome-bootstrap-checkbox/tree/v0.3.7
// --------------------------------------------------

//
// Checkboxes
// --------------------------------------------------


$font-family-icon: 'FontAwesome' !default;
$fa-var-check: "\f00c" !default;
$check-icon: $fa-var-check !default;


@mixin checkbox-variant($parent, $color) {
  #{$parent} input[type="checkbox"]:checked + label,
  #{$parent} input[type="checkbox"]:checked + .wpcf7-list-item-label,
  #{$parent} input[type="radio"]:checked + label {

    &:before {
      background-color: $color;
      border-color: $color;
    }

    &:after {
      color: #fff;
    }
  }
}


@mixin checkbox-variant-indeterminate($parent, $color) {
  #{$parent} input[type="checkbox"]:indeterminate + label,
  #{$parent} input[type="checkbox"]:indeterminate + .wpcf7-list-item-label,
  #{$parent} input[type="radio"]:indeterminate + label {

    &:before {
      background-color: $color;
      border-color: $color;
    }

    &:after {
      background-color: #fff;
    }
  }
}


.checkbox,
.wpcf7-checkbox,
.wpcf7-acceptance {
  padding-left: 20px;

  &.wpcf7-form-control {
    padding-left: 0;
  }

  label,
  .wpcf7-list-item-label {
    display: inline-block;
    vertical-align: middle;
    position: relative;
    padding-left: 5px;
    text-align: left;

    &:before {
      content: '';
      display: inline-block;
      position: absolute;
      width: 18px;
      height: 18px;
      left: 0;
      margin-left: -20px;
      border: 2px solid $input-border;
      border-radius: 0;
      background-color: #fff;
      @include transition(border 0.15s ease-in-out, color 0.15s ease-in-out, box-shadow ease-in-out .15s);
    }

    &:after {
      display: inline-block;
      position: absolute;
      width: 16px;
      height: 16px;
      left: 0;
      top: 1px;
      margin-left: -20px;
      padding-left: 3px;
      padding-top: 1px;
      font-size: 12px;
      text-align: center;
      color: $input-color;
    }
  } // label

  input[type="checkbox"],
  input[type="radio"] {
    opacity: 0;
    z-index: 1;

    &:focus + label:before,
    &:focus + .wpcf7-list-item-label:before {
      outline: 0;
      outline-offset: 0;
      box-shadow: 0 0 8px rgba($brand-primary, 0.8);
    }

    &:checked:focus + label:before,
    &:checked:focus + .wpcf7-list-item-label:before {
      outline: 3px solid $brand-primary;
      outline-offset: -2px;
      box-shadow: 0 0 8px rgba($brand-primary, 0.8);
    }

    &:checked + label:after,
    &:checked + .wpcf7-list-item-label:after {
      font-family: $font-family-icon;
      content: $check-icon;
    }

    &:indeterminate + label:after,
    &:indeterminate + .wpcf7-list-item-label:after {
      display: block;
      content: '';
      width: 10px;
      height: 3px;
      background-color: #555555;
      border-radius: 2px;
      margin-left: -16.5px;
      margin-top: 7px;
    }

    &:disabled + label,
    &:disabled + .wpcf7-list-item-label {
      opacity: 0.65;

      &:before{
        background-color: $input-bg-disabled;
        cursor: not-allowed;
      }
    }
  } // input

  &.checkbox-circle label:before
  &.checkbox-circle .wpcf7-list-item-label:before {
    border-radius: 50%;
  }

  &.checkbox-inline {
    margin-top: 0;
  }
}


.wpcf7-acceptance {

  .wpcf7-list-item {

    label {
      position: inherit;
      padding-left: 0;

      &:before,
      &:after {
        display: none;
      }
    }
  }
}


@include checkbox-variant('.checkbox-primary', $brand-primary);
@include checkbox-variant('.checkbox-danger', $brand-danger);
@include checkbox-variant('.checkbox-info', $brand-info);
@include checkbox-variant('.checkbox-warning', $brand-warning);
@include checkbox-variant('.checkbox-success', $brand-success);


@include checkbox-variant-indeterminate('.checkbox-primary', $brand-primary);
@include checkbox-variant-indeterminate('.checkbox-danger', $brand-danger);
@include checkbox-variant-indeterminate('.checkbox-info', $brand-info);
@include checkbox-variant-indeterminate('.checkbox-warning', $brand-warning);
@include checkbox-variant-indeterminate('.checkbox-success', $brand-success);




//
// Radios
// --------------------------------------------------

@mixin radio-variant($parent, $color) {
  #{$parent} input[type="radio"]{
    + label{
      &:after {
        background-color: $color;
      }
    }
    &:checked + label{
      &:before {
        border-color: $color;
      }
      &:after {
        background-color: $color;
      }
    }
  }
}


.radio {
  padding-left: 20px;

  label {
    display: inline-block;
    vertical-align: middle;
    position: relative;
    padding-left: 5px;

    &:before {
      content: '';
      display: inline-block;
      position: absolute;
      width: 17px;
      height: 17px;
      left: 0;
      margin-left: -20px;
      border: 1px solid $input-border;
      border-radius: 50%;
      background-color: #fff;
      @include transition(border 0.15s ease-in-out);
    }

    &:after {
      display: inline-block;
      position: absolute;
      content: ' ';
      width: 11px;
      height: 11px;
      left: 3px;
      top: 3px;
      margin-left: -20px;
      border-radius: 50%;
      background-color: $input-color;
      @include scale(0, 0);

      @include transition-transform(.1s cubic-bezier(.8,-0.33,.2,1.33));
      //curve - http://cubic-bezier.com/#.8,-0.33,.2,1.33
    }
  }

  input[type="radio"] {
    opacity: 0;
    z-index: 1;

    &:focus + label:before {
      @include tab-focus();
    }

    &:checked + label:after {
      @include scale(1, 1);
    }

    &:disabled + label {
      opacity: 0.65;

      &:before{
        cursor: not-allowed;
      }
    }
  }

  &.radio-inline{
    margin-top: 0;
  }
}


@include radio-variant('.radio-primary', $brand-primary);
@include radio-variant('.radio-danger', $brand-danger);
@include radio-variant('.radio-info', $brand-info);
@include radio-variant('.radio-warning', $brand-warning);
@include radio-variant('.radio-success', $brand-success);


.checkbox {

  input[type="checkbox"] {

    &.styled:checked + label:after {
      font-family: $font-family-icon;
      content: $check-icon;
    }

    .styled:checked + label {

      &:before {
        color: #fff;
      }

      &:after {
        color: #fff;
      }
    }
  }
} // .checkbox


.radio {

  input[type="radio"] {

    &.styled:checked + label:after {
      font-family: $font-family-icon;
      content: $check-icon;
    }

    .styled:checked + label {

      &:before {
        color: #fff;
      }

      &:after {
        color: #fff;
      }
    }
  }
} // .radio


// CF7
.wpcf7-list-item {

  input[type="checkbox"],
  input[type="radio"] {

    &.styled:checked + .wpcf7-list-item-label:after {
      font-family: $font-family-icon;
      content: $check-icon;
    }

    .styled:checked + .wpcf7-list-item-label {

      &:before {
        color: #fff;
      }

      &:after {
        color: #fff;
      }
    }
  }
}
