// Custom variables
@import "common/variables";


/* ==========================================================================
   Bootstrap 3
   ========================================================================== */

// Core variables and mixins
// @import "../../bower_components/bootstrap-sass/assets/stylesheets/bootstrap/variables";
@import "../../bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins";

// Reset and dependencies
@import "../../bower_components/bootstrap-sass/assets/stylesheets/bootstrap/normalize";
@import "../../bower_components/bootstrap-sass/assets/stylesheets/bootstrap/print";
// @import "../../bower_components/bootstrap-sass/assets/stylesheets/bootstrap/glyphicons";

// Core CSS
@import "../../bower_components/bootstrap-sass/assets/stylesheets/bootstrap/scaffolding";
@import "../../bower_components/bootstrap-sass/assets/stylesheets/bootstrap/type";
@import "../../bower_components/bootstrap-sass/assets/stylesheets/bootstrap/code";
@import "../../bower_components/bootstrap-sass/assets/stylesheets/bootstrap/grid";
@import "../../bower_components/bootstrap-sass/assets/stylesheets/bootstrap/tables";
@import "../../bower_components/bootstrap-sass/assets/stylesheets/bootstrap/forms";
@import "../../bower_components/bootstrap-sass/assets/stylesheets/bootstrap/buttons";

// Components
@import "../../bower_components/bootstrap-sass/assets/stylesheets/bootstrap/component-animations";
@import "../../bower_components/bootstrap-sass/assets/stylesheets/bootstrap/dropdowns";
@import "../../bower_components/bootstrap-sass/assets/stylesheets/bootstrap/button-groups";
@import "../../bower_components/bootstrap-sass/assets/stylesheets/bootstrap/input-groups";
@import "../../bower_components/bootstrap-sass/assets/stylesheets/bootstrap/navs";
@import "../../bower_components/bootstrap-sass/assets/stylesheets/bootstrap/navbar";
@import "../../bower_components/bootstrap-sass/assets/stylesheets/bootstrap/breadcrumbs";
@import "../../bower_components/bootstrap-sass/assets/stylesheets/bootstrap/pagination";
@import "../../bower_components/bootstrap-sass/assets/stylesheets/bootstrap/pager";
@import "../../bower_components/bootstrap-sass/assets/stylesheets/bootstrap/labels";
@import "../../bower_components/bootstrap-sass/assets/stylesheets/bootstrap/badges";
@import "../../bower_components/bootstrap-sass/assets/stylesheets/bootstrap/jumbotron";
@import "../../bower_components/bootstrap-sass/assets/stylesheets/bootstrap/thumbnails";
@import "../../bower_components/bootstrap-sass/assets/stylesheets/bootstrap/alerts";
@import "../../bower_components/bootstrap-sass/assets/stylesheets/bootstrap/progress-bars";
@import "../../bower_components/bootstrap-sass/assets/stylesheets/bootstrap/media";
@import "../../bower_components/bootstrap-sass/assets/stylesheets/bootstrap/list-group";
@import "../../bower_components/bootstrap-sass/assets/stylesheets/bootstrap/panels";
@import "../../bower_components/bootstrap-sass/assets/stylesheets/bootstrap/responsive-embed";
@import "../../bower_components/bootstrap-sass/assets/stylesheets/bootstrap/wells";
@import "../../bower_components/bootstrap-sass/assets/stylesheets/bootstrap/close";

// Components w/ JavaScript
@import "../../bower_components/bootstrap-sass/assets/stylesheets/bootstrap/modals";
@import "../../bower_components/bootstrap-sass/assets/stylesheets/bootstrap/tooltip";
@import "../../bower_components/bootstrap-sass/assets/stylesheets/bootstrap/popovers";
@import "../../bower_components/bootstrap-sass/assets/stylesheets/bootstrap/carousel";

// Utility classes
@import "../../bower_components/bootstrap-sass/assets/stylesheets/bootstrap/utilities";
@import "../../bower_components/bootstrap-sass/assets/stylesheets/bootstrap/responsive-utilities";




/* ==========================================================================
   BS3 Hacks
   ========================================================================== */

// Fix content width inside hidden tabs
.tab-content > .tab-pane,
.pill-content > .pill-pane {
  display: block;     // undo display:none
  height: 0;          // height:0 is also invisible
  overflow: hidden;   // no-overflow
}

.tab-content > .active,
.pill-content > .active {
  height: auto;       // let the content decide it
}
// End hack




/* ==========================================================================
   Bower
   ========================================================================== */

// Automatically injected Bower dependencies via wiredep (never manually edit this block)
// bower:scss
@import "../../bower_components/slick-carousel/slick/slick.scss";
@import "../../bower_components/font-awesome/scss/font-awesome.scss";
@import "../../bower_components/select2/src/scss/core.scss";
// endbower




/* ==========================================================================
   CODESPA
   ========================================================================== */

// Common
@import "common/global";
@import "common/mixins";
@import "common/grid";
@import "common/helpers";
@import "common/type";
@import "common/forms";
@import "common/checkbox-radio";
@import "common/buttons";
@import "common/wp-classes";
@import "common/responsive-utilities";

// Components
@import "components/offcanvas";
// @import "components/comments";
@import "components/pagination";
@import "components/scrollup";
@import "components/cookie-law-info";
@import "components/modals";
@import "components/navs";
@import "components/alerts";
@import "components/sliders";
@import "components/select2-bootstrap";
// @import "components/panels";
@import "components/headroom";
@import "components/odometer-theme-codespa";

// Layouts
@import "layouts/header";
@import "layouts/sidebar";
@import "layouts/footer";
@import "layouts/posts";
@import "layouts/search";
@import "layouts/tinymce";
// @import "layouts/pages";
// @import "layouts/page-xxx";
// @import "layouts/template-xxx";

// Temp
// @import "common/temp";

// Give Plugin
@import "give/common";
@import "give/wrapper";
@import "give/component";



.main {}

@media screen and (min-width: $screen-sm-min) {
  // @
}

@media screen and (min-width: $screen-md-min) {
  // @
}

@media screen and (min-width: $screen-lg-min) {
  // @
}

@media screen and (min-width: $screen-xl-min) {
  // @
}







// Jarallax
.jarallax {
  position: relative;
  z-index: 0;

  > .jarallax-img {
    @include absolute-full();
    z-index: -1;
    object-fit: cover;
  }

  > .jarallax-img-contain {
    @include absolute-full();
    z-index: -1;
    object-fit: contain;
  }
} // Jarallax






// Google Maps
.acf-map {
  width: 100%;
  margin: 1em 0;
  border: #ccc solid 1px;

  // fixes potential theme css conflict
  img {
    max-width: inherit !important;
  }

  > .marker {
    display: none;
  }
} // .acf-map


// Mapa responsive (mobile cuadrado)
.acf-map-responsive {
  height: 0 !important;
  padding-bottom: 100%; // 1:1

  @media screen and (min-width: 480px) {
    padding-bottom: ((100% / 3) * 2); // 3:2
  }

  @media screen and (min-width: $screen-sm-min) {
    padding-bottom: (100% / 16) * 9; // 16:9
  }
}


// Mapa es slider
.slider-page .slick-slide .acf-map {
  height: 0 !important;
  border: none;
  margin: 0;
  padding-bottom: (100% / 16) * 9; // 16:9
}


// Mapa FacetWP en Archivo de Proyectos
.post-type-archive-codespa_proyecto .facetwp-facet-proyecto_mapa {
  height: 0 !important;
  padding-bottom: 100%;
  background-color: #aadaff;

  @media screen and (min-width: 480px) {
    padding-bottom: (100% / 16) * 9; // 16:9
  }

  @media screen and (min-width: $screen-sm-min) {
    padding-bottom: (100% / 20) * 9; // 20:9
  }

  @media screen and (min-width: $screen-md-min) {
    padding-bottom: (100% / 24) * 9; // 24:9
  }

  @media screen and (min-width: $screen-lg-min) {
    padding-bottom: (100% / 28) * 9; // 28:9
  }

  @media screen and (min-width: $screen-xl-min) {
    padding-bottom: (100% / 32) * 9; // 32:9
  }
}


#facetwp-map {
  @extend .acf-map;
  margin: 0;
  height: 0 !important;
  padding-bottom: 100%;
  // padding-bottom: (100% / 5) * 4; // 5:4
  // padding-bottom: (100% / 16) * 9; // 16:9

  @media screen and (min-width: 480px) {
    padding-bottom: (100% / 16) * 9; // 16:9
  }

  @media screen and (min-width: $screen-sm-min) {
    padding-bottom: (100% / 20) * 9; // 20:9
  }

  @media screen and (min-width: $screen-md-min) {
    padding-bottom: (100% / 24) * 9; // 24:9
  }

  @media screen and (min-width: $screen-lg-min) {
    padding-bottom: (100% / 28) * 9; // 28:9
  }

  @media screen and (min-width: $screen-xl-min) {
    padding-bottom: (100% / 32) * 9; // 32:9
  }
} // #facetwp-map



// Marcadores de Mapa
.marker-content {
  position: relative;
  font-family: $font-family-sans-serif;
}

// Tooltip marcador con imagen y contenido de proyecto
// Estilos para mapa en Archive.
.marker-content-proyecto {
  display: flex;
  align-items: flex-start;
  width: 200px;
  margin: 0;
  padding: 5px 0;
  transition: all 200ms $bezier;

  @media screen and (min-width: 480px) {
    width: 315px;
  }

  @media screen and (min-width: $screen-sm-min) {
    width: 415px;
  }

  .marker-image {
    display: none;
    flex: 0 0 100px;
    z-index: 1;

    @media screen and (min-width: 480px) {
      display: block;
    }

    @media screen and (min-width: $screen-sm-min) {
      flex: 0 0 150px;
    }

    img {
      width: 100px;
      height: auto;

      @media screen and (min-width: $screen-sm-min) {
        width: 150px;
      }
    }

    a {
      display: block;
      transition: opacity 300ms $bezier;

      &:hover,
      &:focus {
        opacity: 0.5;
      }
    }

  } // .marker-image

  .marker-entry {
    flex: 1;
    padding: 0;

    @media screen and (min-width: 480px) {
      margin-left: 15px;
    }

    .entry-title {
      margin: 0;
    }

    .entry-meta {
      margin: 0;
      font-size: $font-size-small;
      font-weight: 800;
      color: $brand-primary;

      .divider {
        font-weight: 600;

        &:last-child {
          display: none;
        }
      }
    } // .entry-meta

    .entry-excerpt {
      display: none;
      margin: 5px 0 0;
      font-size: $font-size-small;
      font-weight: 600;

      @media screen and (min-width: $screen-sm-min) {
        display: block;
      }

      > p {
        margin-bottom: 0;
      }

      &.ellipsis {

        > p {
          overflow: hidden;
          max-height: ( ($font-size-small * $line-height-base) * 6 );
        }
      }
    } // .entry-excerpt

    .entry-link {
      z-index: 1;
    }
  } // .marker-entry

  .marker-link {
    @include absolute-full();
  }

  &:hover,
  &:focus {
    // box-shadow: $shadow-1;
    border-color: $brand-primary;

    .marker-image {

      img {}
    } // .marker-image

    .marker-entry {

      .entry-title {
        color: #000;
      }

      .entry-excerpt {
        color: #000;

        > p {}
      }
    } // .marker-entry

    .marker-link {}
  } // :hover
} // .marker-content-proyecto


.marker-content-proyecto-single {

  .marker-entry {

    .entry-meta {
      margin: 10px 0 0;
      font-size: $font-size-base;

      a {
        outline: none;
      }
    } // .entry-meta
  }
} // ..marker-content-proyecto-single




// Delegación
.marker-content-delegacion {
  display: flex;
  align-items: flex-start;
  width: 100%;
  max-width: 200px;
  margin: 0;
  padding: 5px 0;

  @media screen and (min-width: 480px) {
    max-width: 315px;
  }

  @media screen and (min-width: $screen-sm-min) {
    max-width: 415px;
  }

  .ficha.ficha-delegacion {

    .ficha-nombre {
      font-size: $font-size-h5;
    }

    .ficha-direccion {
      font-size: $font-size-medium;
    }

    .ficha-info {
      font-size: $font-size-small;

      dt {
        width: 80px;
      }

      dd {
        margin-left: 90px;
      }
    }
  } // .ficha.ficha-delegacion
} // .marker-content-delegacion






// Filtros FacetWP
// -------------------------------------

.facetwp-dropdown,
.facetwp-sort-select {
  @extend .form-control;
  @extend .input-sm;
  cursor: pointer;

  option {
    font-size: 14px;
  }
}


// Ocultar botón "enable filtering"
.facetwp-type-map {

  .facetwp-map-filtering {
    display: none;
  }
}


.sidebar-nav {

  .widget-filters-proyecto {
    padding: 20px 15px 0;

    @media screen and (max-width: $screen-xs-max) {
      max-width: inherit;
    }

    > h3 {
      margin: 0 0 15px;
      text-align: center;
      @include responsive-heading($font-size-h3);
      text-transform: uppercase;
      color: $text-color;
    }

    .filters {
      position: relative;
    }

    .facetwp-facet {
      margin-bottom: 10px;
    }

    .facetwp-reset {
      font-size: $font-size-xsmall;
      font-weight: 800;
    }

    .facetwp-sort-select {}

    .facetwp-loading {
      width: 40px;
      height: 40px;
      background-position: center;
    }

    // Dropdows
    > .select2-container--bootstrap {
      right: 15px;
    }

    .select2-selection {
      font-size: 18px;
      line-height: 1;
      font-weight: 700;
    }

    .select2-container--focus .select2-selection, .select2-container--open .select2-selection {
      box-shadow: none;
    }

    .select2-results__option {
      padding: 7px 20px;
      font-size: 14px;
      line-height: 16px;
      font-weight: 600;
      transition: all 200ms $bezier;

      &:first-child {
        display: none;
        font-size: 11px;
        line-height: 1.25;
        font-weight: 800;
        text-transform: uppercase;
        background-color: #fff;
        color: $text-color;

        &:hover,
        &:focus {
          text-decoration: underline;
        }
      }
    }

    .select2-selection__arrow b {
      border-color: $text-color transparent transparent;
    }
  } // .widget-filters-proyecto
} // .sidebar-nav

// -------------------------------------




// Capsules
.capsule {
  background-color: $gris-claro;
  margin-bottom: 30px;
  padding: 15px;
  border-radius: $border-radius-base;
}

.capsule-large {
  padding: 30px;
}

.capsule-small {
  padding: 10px;
}

.capsule-white {
  background-color: #fff;
}

.capsule-light {
  background-color: $gris-blanco;
}

.capsule-dark {
  background-color: $gris;
}

.capsule-darker {
  background-color: $gris-borde;
}

.capsule-gradient {
  background-color: transparent;
  @include gradient-vertical(
    $gris-claro,
    rgba(255, 255, 255 ,0),
    0%,
    100%
  );
}






// Videos
.youtube-video {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  max-width: 100%;

  iframe,
  object,
  embed {
    @include absolute-full();
  }
} // .youtube-video

.video-wrapper {
  margin-bottom: $line-height-computed;
}







// Animaciones
// ----------------------------------

// Bounce
@keyframes bounce {

  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }

  40% {
    transform: translateY(-20px);
  }

  60% {
    transform: translateY(-10px);
  }
}

.bounce {
  animation: bounce 1s infinite;
}


// Bounce Inverse
@keyframes bounce-inverse {

  0%, 40%, 80%, 100% {
    transform: translateY(0);
  }

  60% {
    transform: translateY(-20px);
  }
}

.bounce-inverse {
  animation: bounce-inverse 2s infinite;
}


// Blink
@keyframes blink {

  from {
    opacity: 1.0;
  }

  to {
    opacity: 0.0;
  }
}

.blink {
  animation: blink 1s infinite;
}


// Fade-in
@keyframes fadeIn {

  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.fadein {
  animation: fadeIn;
}


// Fade amarillo
@keyframes highlightWarning {

  from {
    background-color: $brand-warning;
  }

  to {
    background-color: #FFF;
  }
}




// Dropdown menu fade in
.open > .dropdown-menu {
  animation-name: fadeIn;
  animation-duration: 200ms;
  animation-iteration-count: 1;
  animation-timing-function: ease;
  animation-fill-mode: forwards;
}







// Breadcrumbs
.breadcrumbs {
  display: none;
  align-items: center;
  min-height: 40px;
  padding: 10px 0;
  font-size: 12px;
  line-height: 15px;
  font-weight: 700;

  @media screen and (min-width: $screen-md-min) {
    display: flex;
    margin-top: -40px;
  }

  > .container {

    @media screen and (max-width: $screen-xs-max) {
      width: 100%;
    }
  }

  p {
    margin: 0;

    @media screen and (min-width: $screen-md-min) {
      margin: 0 400px 0 0;
    }
  }

  .breadcrumb_last {
    color: #999;
  }

  // Ocultar
  .error404 &,
  .page.home & {
    display: none !important;
  }

} // .breadcrumbs



// 404
.error404 {

  .main-content {
    position: relative;
    height: calc(100vh - 30px);
    top: -100px;
    margin-bottom: -100px;
    padding: 0;
    background-color: $text-color;
    color: #fff;

    .__img-replace {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;

      background-repeat: no-repeat;
      background-size: cover;
      background-attachment: fixed;
      background-position: 50% 50%;
      opacity: 0.5;
    }

    @media screen and (min-width: 420px) {
      height: calc(100vh - 40px);
    }

    @media screen and (min-width: $screen-sm-min) {
      // padding-top: initial;
    }

    @media screen and (min-width: $screen-md-min) {
      height: 100vh;
      top: -160px;
      margin-bottom: -160px;
    }

    > .container {
      display: flex;
      height: 100%;
      justify-content: center;
      align-items: center;

      @media screen and (max-height: 480px) {
        align-items: flex-end;
        padding-bottom: 30px;
      }
    }

    .page-header {
      position: relative;
      margin: 0;
      border: none;
      text-align: center;

      @media screen and (min-width: $screen-sm-min) {
        margin: 60px 0 0;
      }

      .page-title {

        @media screen and (max-width: $screen-xs-max) {
          font-size: 30px;
        }

        i {
          display: block;
          margin-bottom: 15px;

          @media screen and (min-width: $screen-sm-min) and (max-height: 539px) {
            display: none;
          }
        }
      }
    }

    .lead-lg {

      @media screen and (max-width: $screen-xs-max) {
        font-size: 16px;
      }
    }
  }
} // .error404




.document {}





.main-content {
  padding: 30px 0;
} // .main-content

// .main-loop {} // .main-loop





// Responsive header
.responsive-header {
  @include responsive-padding($font-size-h2);

  h1, h2,
  .h0, .h1, .h2 {
    margin: 0;
    // text-transform: uppercase;
  }

  .h0 {
    @include responsive-heading($font-size-h0);

    @media screen and (max-width: 480px) {
      font-size: 30px;
    }

    @media screen and (min-width: 481px) and (max-width: $screen-xs-max) {
      font-size: 36px;
    }
  }

  h1, .h1 {
    @include responsive-heading($font-size-h1);

    @media screen and (max-width: 480px) {
      font-size: 30px;
    }
  }

  h2, .h2 {
    @include responsive-heading($font-size-h2);
  }

  h3, .h3 {
    @include responsive-heading($font-size-h3);
  }

  .lead-lg {

    @media screen and (max-width: 480px) {
      font-size: $font-size-large;
    }
  }

  > .container {

    > *:last-child {
      margin-bottom: 0;
    }
  }
} // .responsive-header





// Bloque de sección
.section-block {

  > header {
    @extend .responsive-header;

    &.text-center {

      > .container {

        > h1, h2, h3, h4, h5, h6 {

          + p {
            margin-top: 0.25em;
          }
        }
      }
    }
  }

  // &-alt {
  //   background: magenta;
  // }

  .section-block-content {
    @include responsive-padding($font-size-h2);
  }

  .section-block-action {
    @include responsive-padding($font-size-h2);
  }
} // .section-block





// Full window height
.__100vh {
  height: 100vh
}

.__50vh {
  height: 50vh;
}

// .__100vh-top {
//   height: calc(100vh - 40px);

//   @media screen and (min-width: $screen-md-min) {
//     height: 100vh;
//     margin-top: -120px;
//   }
// }

.__min-height {
  min-height: 240px;

  @media screen and (min-width: $screen-sm-min) {
    min-height: 320px;
  }

  @media screen and (min-width: $screen-md-min) {
    min-height: 360px;
  }

  @media screen and (min-width: $screen-lg-min) {
    min-height: 400px;
  }

  @media screen and (min-width: $screen-xl-min) {
    min-height: 480px;
  }
} // .__min-height





// Narrow container
.container.__narrow {

  @media screen and (min-width: $screen-md-min) {
    max-width: $container-sm !important;
    padding: 0;
  }

  @media screen and (min-width: $screen-lg-min) {
    max-width: $container-md !important;
    padding: 0;
  }

  @media screen and (min-width: $screen-xl-min) {
    max-width: $container-lg !important;
    padding: 0;
  }
} // .container.narrow





// Narrower container
.container.__narrower {

  @media screen and (min-width: $screen-sm-min) {
    max-width: 540px !important;
    padding: 0;
  }

  @media screen and (min-width: $screen-md-min) {
    max-width: 540px !important;
    padding: 0;
  }

  @media screen and (min-width: $screen-lg-min) {
    max-width: $container-sm !important;
    padding: 0;
  }

  @media screen and (min-width: $screen-xl-min) {
    max-width: $container-sm !important;
    padding: 0;
  }
} // .container.narrower





.container.__narrow_fixed_sm {

  @media screen and (min-width: $screen-md-min) {
    max-width: $container-sm !important;
    padding: 0;
  }
} // .container.__narrow_fixed_sm





// Image swap en hover
.image-swap {
  position: relative;
  z-index: 2 !important;
  cursor: -webkit-grab;

  img:first-child {
    display: block;
  }

  img:last-child {
    display: none;
  }

  &:hover {

    img:first-child {
      display: none;
    }

    img:last-child {
      display: block;
    }
  }
} // .image-swap





// Módulo horizontal de ancho de página
.section-modulo-horizontal {
  position: relative;

  &:before {
    content: '';
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 75%;
    background-color: $brand-primary;
  }

  .panel {
    position: relative;
    margin: 0;
    text-align: center;

    .panel-body {
      padding: 15px;

      @media screen and (min-width: $screen-sm-min) {
        padding: 20px;
      }
    } // .panel-body

    .row {

      .ie & {

        @media screen and (min-width: $screen-sm-min) {
          flex-wrap: nowrap;
        }
      }

      &.row-20 {

        @media screen and (max-width: $screen-xs-max) {
          margin-left: -15px;
          margin-right: -15px;
        }
      }

      > .col-20 {

        @media screen and (max-width: $screen-xs-max) {
          padding-left: 15px;
          padding-right: 15px;
        }

        &:last-child {

          @media screen and (min-width: $screen-sm-min) {
            padding-right: 20px;
          }
        }
      }
    }

    .panel-image {
      position: relative;
      height: 100%;

      .__img-replace {
        height: 0;
        padding-bottom: (100% / 5) * 4; // 5:4
        border-radius: 3px;
        background-size: cover;
        background-position: 50% 50%;
        background-repeat: no-repeat;
        transition: all 300ms $bezier;

        @media screen and (min-width: $screen-sm-min) {
          height: 100%;
        }
      } // .__img-replace
    } // .panel-image

    .panel-content {
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 100%;
      min-height: 320px;
      padding: 20px 0;

      @media screen and (min-width: $screen-sm-min) {
        padding-right: 20px;
      }

      > * {
        max-width: 100%; // ie fix
      }

      h6 {
        margin-top: 0;
        font-weight: 600;
        text-transform: uppercase;
        color: $brand-primary;

        @media screen and (min-width: $screen-md-min) {
          font-size: $font-size-h5;
        }

        &.ellipsis {
          overflow: hidden;
          max-height: ($font-size-h5 * $headings-line-height) * 6;
        }
      }

      h2 {
        margin: 0 0 0.5em;
        font-size: 27px;
        line-height: 1;
        color: #000;

        @media screen and (min-width: $screen-sm-min) {
          overflow: hidden;
          font-size: 30px;

          &.ellipsis {
            max-height: 30px * 6;
          }
        }

        @media screen and (min-width: $screen-md-min) {
          font-size: 30px;

          &.ellipsis {
            max-height: 30px * 6;
          }
        }

        @media screen and (min-width: $screen-lg-min) {
          font-size: 36px;

          &.ellipsis {
            max-height: 36px * 6;
          }
        }

        @media screen and (min-width: $screen-xl-min) {
          font-size: 45px;

          &.ellipsis {
            max-height: 45px * 6;
          }
        }

        + .btn {
          margin-top: 1.25em;
        }
      }

      .lead-alt {
        font-size: 16px;
        margin-bottom: 30px;

        @media screen and (min-width: $screen-sm-min) {
          overflow: hidden;
          font-size: 18px;

          &.ellipsis {
            max-height: (18px * $line-height-base) * 9;
          }
        }

        @media screen and (min-width: $screen-md-min) {
          font-size: 18px;

          &.ellipsis {
            max-height: (18px * $line-height-base) * 9;
          }
        }

        @media screen and (min-width: $screen-lg-min) {
          font-size: $font-size-large;

          &.ellipsis {
            max-height: ($font-size-large * $line-height-base) * 9;
          }
        }

        @media screen and (min-width: $screen-xl-min) {
          font-size: ($font-size-large * 1.20);

          &.ellipsis {
            max-height: (($font-size-large * 1.20) * $line-height-base) * 9;
          }
        }
      } // .lead-alt

      a {
        position: relative;
        z-index: 1;
      }

      .btn {

        @media screen and (min-width: $screen-md-min) {
          margin-top: 30px;
        }
      }

      .link-secondary {
        margin: 30px 0 0;

        a {
          text-decoration: underline;

          &:hover,
          &:focus {
            text-decoration: none;
          }
        }
      }
    } // .panel-content

    .panel-link {
      @include absolute-full();
    }
  } // .panel

  &.no-content {

    .panel {

      .panel-image {

        .__img-replace {

          @media screen and (min-width: $screen-sm-min) {
            padding-bottom: (100% / 16) * 9; // 16:9
          }
        } // .__img-replace
      } // .panel-image
    } // .panel
  } // .no-content

} // .section-modulo-horizontal





// Módulos con imagen de fondo
.section-modulos-imagen {

  > .container {

    @media screen and (max-width: $screen-xs-max) {
      padding-right: 0;
      padding-left: 0;
    }
  }

  .section-block-content {
    position: relative;
    @include responsive-padding($font-size-h0 * 2);

    @media screen and (max-width: $screen-xs-max) {
      padding-top: 0;
      padding-bottom: 0;
    }

    @media screen and (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
      padding-top: ceil($font-size-h2 * $headings-line-height);
      padding-bottom: ceil($font-size-h2 * $headings-line-height);
    }

    // Mask
    &:before,
    &:after {
      content: '';
      position: absolute;
      z-index: 1;
      top: 0;
      bottom: 0;
      width: 10000%;
    }

    &:before {
      right: calc(100% + 10px);
      background: #fff;
    }

    &:after {
      left: calc(100% + 10px);
      background: #fff;
    }
  } // .section-block-content

  .slider-modulos-imagen {} // ver _sliders.scss

  .panel {
    position: relative;
    overflow: hidden;
    margin: 0;
    height: 100%;
    min-height: 320px;
    border: none;
    border-radius: 0;
    text-align: center;
    background-color: #000;
    color: #fff;
    box-shadow: none;
    opacity: 1;
    backface-visibility: hidden;
    transform: scale(1);
    transition: all 300ms $bezier;

    @media screen and (min-width: $screen-sm-min) {
      margin: 0;
      border-radius: $panel-border-radius;
    }

    @media screen and (max-width: $screen-sm-max) {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }

    .__img-replace {
      @include absolute-full();
      background-size: cover;
      background-position: 50% 50%;
      background-repeat: no-repeat;
      opacity: 0.75;
      transform: scale(1);
      transition: all 300ms $bezier;

      @media screen and (min-width: $screen-md-min) {
        opacity: 0.5;
        transform: scale(1.42857);
      }
    }

    .panel-body {
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 100%;
      padding: 15px;

      @media screen and (min-width: $screen-sm-min) {
        padding: 30px;
      }

      @media screen and (min-width: $screen-md-min) {
        padding: 30px 15px;
      }

      @media screen and (min-width: $screen-lg-min) {
        padding: 60px 30px;
      }

      > * {
        max-width: 100%; // ie fix
      }

      h6 {
        margin-top: 0;
        font-weight: 400;
        text-transform: uppercase;

        &.ellipsis {
          overflow: hidden;
          max-height: ($font-size-h6 * $headings-line-height) * 2;
        }
      }

      h2 {
        margin: 0 0 0.25em;
        font-size: 27px;
        line-height: 1;

        &.ellipsis {
          overflow: hidden;
          max-height: 27px * 3;
        }

        @media screen and (min-width: $screen-sm-min) {
          font-size: 30px;

          &.ellipsis {
            max-height: 30px * 3;
          }
        }

        @media screen and (min-width: $screen-md-min) {
          font-size: 30px;

          &.ellipsis {
            max-height: 30px * 3;
          }
        }

        @media screen and (min-width: $screen-lg-min) {
          font-size: 36px;

          &.ellipsis {
            max-height: 36px * 3;
          }
        }

        @media screen and (min-width: $screen-xl-min) {
          font-size: 45px;

          &.ellipsis {
            max-height: 45px * 3;
          }
        }

        + .btn {
          margin-top: 1.25em;
        }
      } // h2

      .lead-alt {
        font-size: 16px;

        &.ellipsis {
          overflow: hidden;
          max-height: (16px * $line-height-base) * 3;
        }

        @media screen and (min-width: $screen-sm-min) {
          font-size: 18px;

          &.ellipsis {
            max-height: (18px * $line-height-base) * 3;
          }
        }

        @media screen and (min-width: $screen-md-min) {
          font-size: 18px;

          &.ellipsis {
            max-height: (18px * $line-height-base) * 3;
          }
        }

        @media screen and (min-width: $screen-lg-min) {
          font-size: $font-size-large;

          &.ellipsis {
            max-height: ($font-size-large * $line-height-base) * 3;
          }
        }

        @media screen and (min-width: $screen-xl-min) {
          font-size: ($font-size-large * 1.20);

          &.ellipsis {
            max-height: (($font-size-large * 1.20) * $line-height-base) * 3;
          }
        }
      } // .lead-alt

      .btn {
        z-index: 1;

        @media screen and (min-width: $screen-md-min) {
          pointer-events: none;
        }
      }
    } // .panel-body

    .panel-link {
      @include absolute-full();
      border-bottom: 10px solid $brand-primary;
      transition: all 300ms $bezier;

      @media screen and (min-width: $screen-md-min) {
        border-bottom: 10px solid transparent;
        pointer-events: none;
      }

      @media screen and (min-width: $screen-lg-min) {
        border-bottom: 15px solid transparent;
      }
    }
  } // .panel

} // .section-modulos-imagen





// Descargas en página estática
.section-descargas {

  .download {
    position: relative;
    display: flex;
    align-items: center;
    margin-bottom: 30px;
    padding: 15px;
    border: 1px solid $gris;
    background-color: $gris-claro;
    transition: all 200ms $bezier;

    > * {
      flex: 0 1 auto;
    }

    .download-info {

      .download-icon {
        position: relative;
        margin-bottom: 5px;

        .fa-download {
          color: $gris;

          + i {
            position: absolute;
            bottom: 0;
            right: 0;
            color: $text-color;
          }
        }
      }

      .download-size {
        font-size: $font-size-small;
        font-weight: 700;
        text-align: center;
        color: $gris-medio;
      }
    } // .download-info

    header {
      flex: 1 1 auto;
      padding: 0 15px;

      > * {
        margin: 0;
      }

      h4 {

        &.ellipsis {
          overflow: hidden;
          max-height: ( ($font-size-h4 * $headings-line-height) * 2 );
        }

        + p {
          margin-top: ($line-height-computed / 2);
        }
      }

      p {

        &.ellipsis {
          overflow: hidden;
          max-height: ( ($font-size-base * $line-height-base) * 2 );
        }
      }
    } // header

    .download-arrow {
      padding: 0 15px;
      color: $gris;
      transform: translateX(0);
      transition: all 200ms ease-in;
    }

    .download-link {
      @include absolute-full();
    }

    &:hover,
    &:focus {
      box-shadow: $shadow-1;
      border-color: $brand-primary;

      .download-info {

        .download-icon {

          .fa-download {

            + i {

            }
          }
        }

        .download-size {

        }
      } // .download-info

      header {

        h3 {

        }

        p {

        }
      } // header

      .download-arrow {
        color: $brand-primary;
        transform: translateX(15px);
      }

      .download-link {}
    } // :hover
  } // .download
} // .section-descargas





// Rotador de entradas
.section-rotador-entradas {

  // Título principal
  > header {
    @include responsive-padding($font-size-h0);
    text-align: center;

    .h0 {
      font-weight: 800;
      color: #000;
    }
  }
} // .section-rotador-entradas





// Colabora
.page-template-template-colabora {

  // Ocultar botones superiores de socio y donación
  .nav-colabora {

    @media screen and (min-width: $screen-md-min) {
      display: none;
    }
  } // .nav-colabora

  .section-colabora {
    margin: 15px 0 30px;

    @media screen and (min-width: $screen-md-min) {
      margin: 0 0 30px;
    }

    // Menú de WordPress colabora_tabs
    // Se debe agregar clases en el admin
    // 'nav-tab-xl' para los tabs grandes
    // 'pull-right' para el tab pequeño de la derecha.
    .colabora-tabs {

      .nav-tabs {
        margin: 0;
        border-bottom: 0;

        > li {
          margin-bottom: 0;

          > a {
            margin-right: 0;
            line-height: 20px;
            font-weight: 800;
            text-transform: uppercase;
            color: $text-color;
            transition: all 200ms $bezier;
          }

          &.nav-tab-xl {

            > a {
              border-radius: 0;
              padding: 19px 20px;
              border: 1px solid $brand-success;
              font-size: 14px;
              background-color: $brand-success;

              &:hover {
                border-color: darken($brand-success, 5%);
                background-color: darken($brand-success, 5%);
                color: #fff;
              }
            }

            &:first-child > a {
              border-radius: $border-radius-base 0 0 0;
              border-right-color: darken($brand-success, 5%);
            }

            &:nth-child(2) > a {
              border-right-color: darken($brand-success, 5%);
            }

            &:nth-child(3) > a {
              border-right-color: darken($brand-success, 5%);
            }

            &:nth-child(4) > a {
              border-radius: 0 $border-radius-base 0 0;
            }

            &.active > a {
              &,
              &:hover,
              &:focus {
                border-color: darken($brand-success, 5%);
                background-color: darken($brand-success, 5%);
                color: #fff;
              }
            }
          } // .nav-tab-xl

          &.pull-right {

            > a {
              margin-top: 30px;
              padding: 4px 15px;
              font-size: 12px;
              border-color: $gris-claro;
              background-color: $gris-claro;

              &:hover {
                border-color: $gris;
                background-color: $gris;
                color: $link-color;
              }
            }

            &.active > a {
              &,
              &:hover,
              &:focus {
                border-color: $gris;
                background-color: $gris;
                color: $text-color;
              }
            }
          } // .pull-right
        } // > li
      } // .nav-tabs
    } // nav

    .panel {
      margin-bottom: 15px;
      border: 1px solid $gris;
      background-color: $gris-claro;

      @media screen and (min-width: $screen-md-min) {
        margin-bottom: 30px;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
      }

      .panel-body {
        padding: 15px;

        @media screen and (min-width: $screen-sm-min) {
          padding: 30px;
        }

        @media screen and (min-width: $screen-lg-min) {
          padding: 40px;
        }

        @media screen and (min-width: $screen-xl-min) {
          padding: 60px;
        }
      } // .panel-body
    } // .panel


    // Colabora: Inicio
    .panel.colabora-inicio {

      // .panel-body {}

      .panel-content {
        display: flex;
        flex-direction: column;

        // Firefox flexbox
        > * {
          min-width: 0;
          min-height: 0;
        }

        @media screen and (min-width: $screen-sm-min) {
          flex-direction: row;
        }

        .colabora-inicio-image {
          flex: 1 0 auto;
          padding: 0 0 15px;
          text-align: center;

          @media screen and (min-width: $screen-sm-min) {
            padding: 0 30px 0 0;
          }

          @media screen and (min-width: $screen-lg-min) {
            padding: 0 40px 0 0;
          }

          @media screen and (min-width: $screen-xl-min) {
            padding: 0 60px 0 0;
          }

          img {
            width: 90px;

            @media screen and (min-width: $screen-sm-min) {
              width: 180px;
            }
          }
        }

        .colabora-inicio-content {
          flex: 1 1 auto;
          text-align: center;

          @media screen and (min-width: $screen-sm-min) {
            text-align: left;
          }

          .lead-lg {
            margin-bottom: 0;
          }

          h1 {
            @include responsive-heading($font-size-h2);
          }

          p.color-success {

            @media screen and (min-width: $screen-lg-min) {
              font-size: $font-size-large;
            }
          }

          .action {
            font-size: 0;

            .btn {

              @media screen and (max-width: $screen-sm-max) {
                display: block;
                width: 100%;
                white-space: normal;
              }

              &:first-child {
                margin-bottom: 15px;

                @media screen and (min-width: $screen-md-min) {
                  margin-bottom: 0;
                  margin-right: 15px;
                }
              }
            }
          }
        }
      } // .panel-content
    } // .panel.colabora-inicio


    // Colabora: Inicio Únete
    .panel.colabora-inicio-unete {
      background-color: $gris-blanco;

      // .panel-body {}

      // html[lang="es-ES"] & {}

      // html[lang="en-US"] & {}

      .deco-unete {
        margin-bottom: 30px;

        @media screen and (min-width: $screen-sm-min) {
          margin-bottom: 0;
        }
      }

      .flex-end {
        align-self: flex-end;
      }

      .action {
        font-size: 0;

        .btn {

          &:first-child {
            margin-bottom: 15px;
          }

          @media screen and (min-width: $screen-xl-min) {
            border-width: $border-width-large;

            @include button-size(
              $padding-large-vertical - $border-width-large - 2,
              $padding-large-horizontal - $border-width-large,
              $font-size-large,
              $font-size-large + 4,
              $btn-border-radius-large
            );
          }
        }
      }

      .donaciones-ejemplo {
        padding: 30px 0 0;
        text-align: center;

        @media screen and (min-width: $screen-sm-min) {
          padding: 30px 0 0;
          text-align: left;
        }

        @media screen and (min-width: $screen-lg-min) {
          padding: 40px 0 0;
        }

        @media screen and (min-width: $screen-xl-min) {
          padding: 60px 0 0;
        }

        .donacion {
          margin-bottom: 30px;

          @media screen and (min-width: $screen-sm-min) {
            margin-bottom: 0;
          }

          h3 {
            @include responsive-heading($font-size-h2);
            margin: 0 0 $line-height-computed;
            color: $brand-success;

            @media screen and (max-width: $screen-xs-max) {
              font-size: $font-size-h2;
            }

            small {
              display: block;
              font-size: $font-size-base;
              font-weight: 700;
              line-height: 0.5;
              color: $gray;
            }
          }

          p {
            margin: 0;
            font-weight: 700;
            color: $gray;

            @media screen and (max-width: $screen-xs-max) {
              max-width: 300px;
              margin: 0 auto;
            }
          }

          @media screen and (min-width: $screen-lg-min) {
            display: flex;
            justify-content: middle;

            > * {
              align-self: center;
            }

            h3 {
              margin: 0;
            }

            p {
              margin: 0 0 0 15px;
            }
          }
        }
      } // .donaciones-ejemplo
    } // .panel.colabora-inicio-unete


    // Colabora: Voluntarios
    .panel.colabora-voluntarios {

      .panel-body {

        @media screen and (max-width: $screen-xs-max) {
          padding-bottom: 0;
        }
      }

      .list-custom-alt.text-xlarge {

        @media screen and (max-width: 320px) {
          margin-left: 10px;
          font-size: 18px !important;
        }
      }

      #form {
        padding: 30px 15px;

        @media screen and (max-width: $screen-xs-max) {
          margin: 0 -15px;
          border-top-left-radius: 0;
          border-top-right-radius: 0;
        }

        @media screen and (min-width: $screen-md-min) {
          padding: 30px;
        }

        label {
          display: block;

          small {
            float: right;
            margin: 3px 0;
          }
        }

        .wpcf7-acceptance .wpcf7-list-item-label {

          @media screen and (min-width: $screen-lg-min) {
            font-size: 16px;
            line-height: 1.25;
          }
        }
      }
    } // .panel.colabora-voluntarios


    // Colabora: Otras formas de colaborar
    .panel.colabora-otras-formas {

      .social-links {
        @extend .social-links-color;
        text-align: center;
      }
    } // .panel.colabora-otras-formas


    // Colabora: Destino de tu ayuda
    .panel.colabora-destino-ayuda {

      .graph {
        margin-bottom: 60px;

        @media screen and (min-width: $screen-md-min) {
          margin-bottom: 30px;
        }

        // .graph-image {}

        .graph-title {
          padding: ((1em * $headings-line-height) / 2 ) 0;
          border-top: 1px solid $gris-borde;
          border-bottom: 1px solid $gris-borde;
          text-align: center;
          text-transform: uppercase;

          small {
            display: block;
            font-size: 50%;
            font-weight: 800;
            color: $text-color;
          }
        } // .graph-title

        .graph-data {
          font-size: $font-size-medium;
          margin: 1.5em 0;

          @media screen and (min-width: $screen-lg-min) {
            font-size: $font-size-base;
          }

          .graph-data-item {
            display: flex;
            margin-bottom: .55em;

            // Firefox flexbox
            > * {
              min-width: 0;
              min-height: 0;
            }

            .graph-data-item-title {
              flex: 1 1 auto;
              padding-left: 1em;
              font-weight: 800;
            }

            .graph-data-item-data {
              flex: 0 0 80px;
              order: -1;
              text-align: right;
              font-weight: 600;

              @media screen and (min-width: $screen-lg-min) {
                flex: 0 0 90px;
              }

              i {
                display: block;
                float: left;
                width: 16px;
                height: 16px;
                margin-top: 0;
                background-image: url('/app/themes/codespa/dist/images/sprite-legend-colors.png');
                background-repeat: no-repeat;
                background-position: 0 0;

                @media screen and (min-width: $screen-lg-min) {
                  margin-top: 1px;
                }

                &.legend-color {

                  &-01 {
                    background-position: 0 0;
                  }

                  &-02 {
                    background-position: 0 -20px;
                  }

                  &-03 {
                    background-position: 0 -40px;
                  }

                  &-04 {
                    background-position: 0 -60px;
                  }

                  &-05 {
                    background-position: 0 -80px;
                  }

                  &-06 {
                    background-position: 0 -100px;
                  }

                  &-07 {
                    background-position: 0 -120px;
                  }
                }
              } // i
            } // .graph-data-item-data
          } // .graph-data-item
        } // .graph-data
      } // .graph
    } // .panel.colabora-destino-ayuda


    // Colabora: Destino de tu ayuda
    .panel.colabora-gracias {

      @media screen and (min-width: $screen-md-min) {
        border-top-color: darken($brand-success, 5%);
      }

      .gracias-hero {
        margin: -1px -1px 0 -1px;
        border-top-left-radius: 3px;
        border-top-right-radius: 3px;
        background-color: $brand-success;
        color:#fff;

        @media screen and (min-width: $screen-sm-min) {
          padding-right: 15px;
        }

        @media screen and (min-width: $screen-md-min) {
          margin: 0 -1px 0 -1px;
          padding: 10px 15px 10px 10px;
          border-radius: 0;
        }

        @media screen and (min-width: $screen-lg-min) {
          padding: 20px 25px 20px 10px;
        }

        @media screen and (min-width: $screen-xl-min) {
          padding: 40px 45px 40px 10px;
        }

        header {
          margin-left: auto;
          margin-right: auto;
          text-align: center;

          @media screen and (min-width: $screen-sm-min) {
            text-align: left;
          }
        }

        .lead-lg {
          margin-bottom: 10px;
          margin-top: 15px;

          @media screen and (min-width: $screen-sm-min) {
            margin-top: 30px;
          }

          @media screen and (min-width: $screen-md-min) {
            // margin-top: 50px;
          }
        }

        h1 {
          margin-top: 15px;
          margin-bottom: 30px;
          color: $text-color;

          @media screen and (min-width: $screen-sm-min) {
            // margin-bottom: 50px;
          }

          @media screen and (min-width: $screen-md-min) {
            // margin-bottom: 50px;
          }
        }

        hr {
          color: #fff;
        }

        .lead-alt {
          margin-bottom: 30px;

          @media screen and (min-width: $screen-sm-min) {
            // margin-bottom: 50px;
          }

          @media screen and (min-width: $screen-md-min) {
            // margin-bottom: 50px;
          }
        }

        .deco-plant {
          order: -1;
          padding: 15px 0;

          @media screen and (min-width: $screen-sm-min) {
            margin-top: 70px;
            padding: 0 30px;
          }

          @media screen and (min-width: $screen-md-min) {
            margin-top: 50px;
          }

          figure {
            position: relative;
            width: 150px;
            height: 150px;
            margin: 0 auto;
            border-radius: 100%;
            background-color:#fff;

            @media screen and (min-width: $screen-sm-min) {
              width: 180px;
              height: 180px;
            }

            @media screen and (min-width: $screen-md-min) {
              width: 220px;
              height: 220px;
            }

            img {
              position: absolute;
              top: 6px;
              width: 100%;
              height: 100%;
              object-fit: contain;
              object-position: 50% 50%;

              @media screen and (min-width: $screen-md-min) {
                width:auto;
                height:auto;
                top:40%;
                left: 50%;
                transform: translate(-50%,-50%);
              }
            }
          }
        } // deco-plant
      } // gracias-hero

      .heading-left {

        @media screen and (min-width: $screen-sm-min) {
          display: flex;
          margin-bottom: 30px;

          h2 {
            flex: 1 0 auto;
            display: flex;
            align-items: center;
            margin: 0;
            padding-right: 15px;
            border-right: 1px dotted;

            i {
              margin-right: 5px;
            }
          }

          p {
            margin: 0;
            padding-left: 15px;
          }
        }

        @media screen and (min-width: $screen-lg-min) {
          margin-bottom: 40px;
        }

        @media screen and (min-width: $screen-xl-min) {
          margin-bottom: 60px;
        }
      }

      #form {
        padding: 15px;

        @media screen and (min-width: $screen-sm-min) {
          padding: 30px;
        }
      }

      .entry-social {

        >.nc_socialPanel {
          margin-left: auto;
          margin-right: auto;
          max-width: 640px;
        }
      }

    } // .panel.colabora-gracias


    // Colabora: Footer
    .colabora-footer {

      @media screen and (min-width: $screen-sm-min) {
        padding: 30px;
      }

      @media screen and (min-width: $screen-md-min) {
        display: flex;
        justify-content: center;

        // Firefox flexbox
        > * {
          min-width: 0;
          min-height: 0;
        }
      }

      .colabora-footer-text {

        @media screen and (max-width: $screen-sm-max) {
          text-align: center;

          .break {
            display: block;
          }
        }

        @media screen and (min-width: $screen-md-min) {
          flex: 1 1 auto;
        }

        .sep {
          margin: 0 5px;
        }

        strong {
          font-weight: 900;
        }
      } // .colabora-footer-text

      .colabora-footer-badges {
        display: flex;
        justify-content: center;
        margin-top: 15px;

        // Firefox flexbox
        > * {
          min-width: 0;
          min-height: 0;
        }

        @media screen and (max-width: 340px) {
          flex-direction: column;
          align-items: center;
        }

        @media screen and (min-width: $screen-md-min) {
          flex: 0 0 297px;
          margin-top: 0;
          margin-left: 20px;
          padding-left: 20px;
          border-left: 1px solid $gris;
        }

        a {
          margin: 5px 0;
          opacity: .9;
          transition: all 200ms ease-in-out;

          @media screen and (min-width: 340px) {
            margin: 0 5px;
          }

          @media screen and (min-width: $screen-md-min) {
            margin: 0 0 0 10px;
          }

          &:hover,
          &:focus {
            opacity: 1;
          }
        }
      } //.colabora-footer-badges
    } // .colabora-footer
  } // .section-colabora
} // .page-template-template-colabora





// Fichas
.ficha {

  // Patrono
  &.ficha-patronato {
    padding-left: 15px;
    border-left: 5px solid $gris;

    &.ficha-destacado {
      border-color: $brand-primary
    }

    .ficha-nombre {
      margin: 0;
      font-size: $font-size-h5;
    }

    .ficha-cargo {
      margin: .5em 0 0;
      font-size: $font-size-h6;
      color: $gris-medio;
    }

    .ficha-descripcion {
      font-size: $font-size-medium;
      font-weight: 600;

      > p {
        margin: .5em 0 0;
      }
    }
  } // .ficha.ficha-patronato


  // Miembro
  &.ficha-equipo {
    display: flex;

    .ficha-fotografia {
      flex: 0 0 135px;

      img {
        display: block;
        max-width: 100%;
        height: auto;
        border-bottom: 5px solid $gris;
      }
    }

    .ficha-contenido {
      padding-left: 10px;
      margin-left: 10px;
      border-left: 1px solid $gris;

      .ficha-nombre {
        margin: 0;
        font-size: $font-size-h5;
      }

      .ficha-cargo {
        margin: .5em 0 0;
        font-size: $font-size-h6;
        color: $gris-medio;
      }

      .ficha-descripcion {
        font-size: $font-size-medium;
        font-weight: 600;

        > p {
          margin: .5em 0 0;
        }
      }

      > a {
        display: block;
        margin-top: .5em;
        font-size: $font-size-small;
        font-weight: 800;
        text-decoration: none;
        color: $text-color;

        i {
          color: $text-color;
        }

        .fa-linkedin-square {
          padding: 0 1.6667px;
          font-size: 1.6667em;
          line-height: 0.6667em;
          color: $color-linkedin;
        }

        .fa-external-link {
          display: none;
        }

        .fa-caret-up {
          display: inline-block;
        }

        .fa-caret-down {
          display: none;
        }

        &.collapsed {

          .fa-caret-up {
            display: none;
          }

          .fa-caret-down {
            display: inline-block;
          }
        }

        &:hover,
        &:focus {

          span {
            color: $brand-primary;
          }

          i {

            &.fa-external-link {
              display: inline-block;
            }
          }
        }
      } // a

      .ficha-nombre + a,
      .ficha-cargo + a {
        margin-top: 1em;
      }
    } // .ficha-contenido

    &.ficha-destacado {

      .ficha-fotografia {

        img {
          border-color: $brand-primary
        }
      }
    }
  } // .ficha.ficha-equipo


  // Empresa
  &.ficha-empresa {
    display: flex;
    flex-wrap: wrap;

    .ficha-logo {
      display: flex;
      align-items: center;
      flex: 0 0 135px;
      height: 135px;

      img {
        display: block;
        max-width: 100%;
        height: auto;
      }
    }

    .ficha-contenido {
      flex: 1;
      padding-left: 10px;
      margin-left: 10px;
      border-left: 1px solid $gris;
    } // .ficha-contenido

    .ficha-nombre {
      margin: 0;
      font-size: $font-size-h5;
    }

    .ficha-cargo {
      margin: .5em 0 0;
      font-size: $font-size-h6;
      color: $gris-medio;
    }

    .ficha-area {
      margin: .5em 0 0;
      font-size: $font-size-h6;
      color: $gris-medio;
    }

    .ficha-descripcion {
      flex: 1 0 100%;
      margin-top: 15px;
      font-size: $font-size-medium;
      font-weight: 600;

      > p {
        margin: .5em 0 0;

        &:first-child {
          margin-top: 0;
        }
      }
    }
  } // .ficha.ficha-empresa


  // Delegación
  &.ficha-delegacion {
    padding-left: 15px;
    border-left: 1px solid $brand-primary;

    .ficha-nombre {
      margin: 0;
      font-size: $font-size-h4;
    }

    .ficha-direccion {
      margin: 10px 0 0;
      font-size: $font-size-base;
      font-weight: 600;
      color: $gris-medio;
    }

    .ficha-info {
      margin: 10px 0 0;
      font-size: $font-size-medium;

      dt {
        float: left;
        clear: left;
        width: 90px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        margin: 0;
        font-weight: 800;
      }

      dd {
        margin: 0;
        font-weight: 700;
        word-break: break-all;
        margin-left: 100px;
      }

      // a {
      //   display: block;
      //   color: $text-color;

      //   &:hover,
      //   &:focus {
      //     text-decoration: none;
      //     color: $link-color;
      //   }
      // }
    }
  } // .ficha.ficha-delegacion
} // .ficha


// Layout fichas
.section-patronato,
.section-equipo,
.section-empresas-codespa,
.section-empresas-aliadas,
.section-delegaciones {

  .fichas {

    &.row {

      > [class^="col-"] {
        margin-bottom: 30px;
        padding-bottom: 30px;
        border-bottom: 1px dotted $gris-medio;

        // &:first-child {
        //   width: 100%;
        // }
      }
    }

    &.row-0 {

      > .col-0 {

        .ficha {

          @media screen and (min-width: $screen-sm-min) {
            padding-right: 15px;
          }
        }
      }
    }
  } // .fichas
} // section


// Primera ficha ancho completo
.section-patronato,
.section-equipo {

  .fichas {

    &.row {

      > [class^="col-"] {

        &:first-child {
          width: 100%;
        }
      }
    }
  } // .fichas
} // section


.section-empresas-colaboradoras {

  .empresas-colaboradoras {
    font-weight: 600;
    text-transform: uppercase;
    color: $gris-medio;

    @media screen and (min-width: $screen-lg-min) {
      font-size: 18px;
    }

    > span {
      margin-bottom: 0;

      .sep {
        color: $brand-primary;
        margin: 0 0.25em;
      }

      &:last-child .sep {
        display: none;
      }
    }
  }
}





// Social Warfare
.entry-social {
  margin: 15px 0;

  > .nc_socialPanel {
    height: 32px;
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    max-width: 360px;

    span.swp_share {
      font-weight: 700;
    }

    .nc_tweetContainer {
      margin-bottom: 0;
      border-radius: $border-radius-base;

      &.totes.totesalt {
        color: $text-color !important;

        // Icono
        &:before {
          margin-top: 6px;
          font-size: 24px;
        }

        .swp_count {
          margin-top: 11px;
          text-align: left;
          color: $text-color;

          .swp_label {
            margin-top: 15px;
            font-weight: 900;
            text-align: left !important;
            color: $text-color;

            html[lang="es-ES"] & {
              display: none; // js show()
            }
          }
        }
      }
    }
  }
}





// Contacto
.page.contacto,
.page.contact {

  .section-contacto-social {
    margin-bottom: 30px;
    padding-bottom: 30px;
    border-bottom: 1px dotted $gris-medio;

    .social-links {
      margin-bottom: 0;
      font-size: $font-size-large;
      @extend .social-links-color;
    }
  } // .section-contacto-social

  .section-contacto-central {
    margin-bottom: 30px;
    padding-bottom: 30px;
    border-bottom: 1px dotted $gris-medio;

    .ficha.ficha-delegacion .ficha-nombre {
      font-size: $font-size-h3;
    }
  } // .section-contacto-central

  .section-contacto-rrhh {
    margin-bottom: 30px;
  } // .section-contacto-rrhh

  .header-bordered {
    margin-bottom: 30px;
    padding-left: 15px;
    border-left: 1px solid $brand-primary;

    h1, h2, h3 {
      margin-top: 0;
      font-size: $font-size-h3;
    }
  } // .header-bordered
} // .page.contacto


// Sala de Prensa
.section-prensa-contacto {
  margin-bottom: 30px;
  padding-bottom: 30px;
  border-bottom: 1px dotted $gris-medio;
} // .section-prensa-contacto

.section-prensa-descargas {

  .list-group {

    .list-group-item {
      padding: 0;
      transition: all 200ms ease-in-out;

      > a {
        display: flex;
        padding: 15px;
        color: $text-color;

        i {
          padding-right: 15px;
        }

        h5 {
          margin: 0;
          transition: all 200ms ease-in-out;

          small {
            display: block;
            margin-top: 2px;

            + small {
              margin-top: 3px;
            }
          }
        }

        &:hover,
        &:focus {
          text-decoration: none;
          color: $text-color;

          h5 {
            color: $link-color;
          }
        }
      } // a

      &:hover,
      &:focus {
        background-color: $gris-blanco;
      }
    } // .list-group-item
  } // .list-group
} // .section-contenido-descargable

.section-prensa-responsable {
  padding-top: 33px;
} // .section-prensa-responsable




// Botones Sociales a color
.social-links-color {

  .social-link-twitter {
    color: $color-twitter;

    &:hover,
    &:focus {
      color: darken($color-twitter, 10%);
    }
  }

  .social-link-facebook {
    color: $color-facebook;

    &:hover,
    &:focus {
      color: darken($color-facebook, 10%);
    }
  }

  .social-link-linkedin {
    color: $color-linkedin;

    &:hover,
    &:focus {
      color: darken($color-linkedin, 10%);
    }
  }

  .social-link-youtube {
    color: $color-youtube;

    &:hover,
    &:focus {
      color: darken($color-youtube, 10%);
    }
  }
} // .social-links-color
