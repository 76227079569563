/*Give Form:form[id*='give-for']
    STEP1
    SUB STEP A
    A-->.give-donation-levels-wrap
    B-->.give-total-wrap
    C-->.give-recurring-donors-choice

    SUB STEP B
    D-->#give-payment-mode-select
    E-->#sociocert-wrap
    F-->·ffm_html-wrap
*/
.step1_a, .step1_b{
    display: flex;
    flex-direction: column;
}
.step1_a{
    margin-bottom:25px;
    @media screen and (min-width:  $screen-md) {
        margin-bottom:50px;
        order: 2;
        padding-left: 30px;
    }
}
.step1_b{
    margin-bottom:50px;
    @media screen and (min-width:  $screen-md) {
        margin-bottom:0;
        order: 1;
        padding-right: 30px;
    }
}
.step1_c{
    @media screen and (min-width:  $screen-md) {
        order:3;
    }
}
/*Child A lista de botones*/
.give-donation-levels-wrap {
    order: 1;
    display: block;
    width: 100%;
    h3{
        background-color: transparent;
        font-size: 18.4px;
        font-weight: 700;
        margin: 10px 0 15px;
        padding: 0 0 5px;
        border-bottom: 1px solid rgba(0,0,0,.1);
        width: 100%;
        margin-top: 0;
    }
}
#give-donation-level-button-wrap{
    &>li{
        overflow: hidden;
        width:31%;
        margin: 0 2% 2% 0;
    }
}
button.give-donation-level-btn{
    font-family: $font-family-sans-serif;
    width: 100%;
    height: 48px;
    border:none;
    border-radius: 3px;
    background-color:  #f5f5f5;
    border: #dfdfdf 1px solid;
    color: $verde;
    transition: background-color .15s linear;
    &:focus{
        background-color: #FFF;
        border: $verde 1px solid;
        outline-color: $verde;
        outline-width:0;
        color: $verde;
        transition: background-color .15s linear;
    }
    &:hover{
        background-color: #FFF;
        border: $verde 1px solid;
        outline-color: $verde;
        outline-width:0;
        color: $verde;
        transition: background-color .15s linear;
    }
}
/* Child B Field de cantidad escogida */
.give-total-wrap{
    order:2;
    text-align: center;
    width: 100%;
    margin: 0 0 20px;
}
.give-donation-amount{
    width: auto;
    display: inline-block;
}
form[id*='give-form'] .give-donation-amount #give-amount{
    font-family: $font-family-sans-serif;
    font-weight: 700;
    font-size: 35px;
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
    height: 60px;
    min-width: 200px;
    text-align: center;
    &:focus{
        background-color: #FFF;
        border: $verde 1px solid;
        outline-color: $verde;
        outline-width:1px;
    }
}
form[id*='give-form'] .give-donation-amount .give-currency-symbol.give-currency-position-after {
    background-color:$verde;
    color:  #FFF;
    border: none;
    font-size: 35px;
    height: 60px;
    line-height: 60px;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
}
/*Child C Field periodicidad **/
[id*=give-form] .give-recurring-donors-choice{
    order:3;
    width: calc(100% + 30px);
    margin-left: -17px;
    label{
        position:relative;
        font-size: 16px;
        text-align: center;
        width:100%;
        &:before{
            position: absolute;
            top: calc(50% + 12px);
            left: calc(50% + 130px);
            transform: translate(-50%,-50%);
            color: $verde;
            font-size: 22px;
            @extend .fa;
            @extend .fa-chevron-down;
            @extend .fa-sm;
            z-index: 99;
            pointer-events: none;
        }
    }
    select.give-recurring-donors-choice-period{
        position: relative;
        max-width: 360px;
        display:block;
        text-align: center;
        text-align-last: center;
        width:100%;
        height: 60px;
        line-height: 60px;
        color:$verde;
        font-size: 22px;
        border-radius: 0;
        margin: 10px auto 0;
        padding: 0 10px 0 10px;
        background-color: transparent;
        border:1px solid $verde;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        cursor: pointer;
        &:hover{
            border:1px solid $verde;
            background-color: #FFF;
        }
        option{
            text-align: left;
            border-radius: 0;
        }
    }
}
/*Donación tipo **/
#donantetipodonacion-wrap, #certificado_de_donacin-wrap{
    padding: 0;
    &>.give-label{
    background-color: transparent;
    font-size: 1.15em;
    line-height: 1.2;
    font-weight: 700;
    margin: 10px 0 15px;
    padding: 0 0 5px;
    border-bottom: 1px solid rgba(0,0,0,.1);
    width: 100%;
    }
}
/*Switch particular /empreras*/
.swichProfile{
    display:block;
    text-align:center;
    width:100%;
    max-width: 440px;
    margin: 0 auto;
    vertical-align:top;
    font-size:0;
    border-radius:3px;
    overflow:hidden;
    border:1px solid $naranja;
    margin-bottom: 35px;
    @media screen and (min-width:  $screen-sm) {
        margin-bottom: 50px;
    }
    @media screen and (min-width:  $screen-md) {
        width: 100%;
        max-width: 360px;
        margin: 0;
    }
    @media screen and (min-width:  $screen-lg-min) {
        max-width: 440px;
    }
    .option{
        vertical-align:top;
        display: inline-block;
        width:50%;
        height:50px;
        font-size:16px;
    }
    label{
        display:block;
        width:100%;
        height:100%;
        text-align:center;
        line-height:50px;
        transition: background-color .15s linear;
    }

    input[type=radio] {
        opacity: 0;
        z-index: 1;
        position: absolute;
        margin-left: -20px;
        margin-top: 4px\9;
    }
    input[type=radio]:checked ~ label{
        background-color :$naranja;
        color:#FFF;
    }
    p {
        font-size: 14px;
        max-width: 440px;
        text-align: center;
        @media screen and (min-width:  $screen-md) {
            text-align: left;
        }
    }
  }
 .wrapper_swichProfile{
     padding: 0 30px;
     @media screen and (min-width:  $screen-md) {
         padding:0 15px;
     }
 }
  p.swichProfile__text{
        font-size: 14px;
        text-align: center;
        @media screen and (min-width:  $screen-md) {
            text-align: left;
        }
  }
/*STEP 2 */
/*Cómo nos has conocido */
#form_helpout_howmet-wrap, #donante_haveknown-wrap, #socio_haveknown-wrap{
    margin-top: 26px;
    @media screen and (min-width:  $screen-md) {
        width:50%;
        margin-bottom: 30px;
    }
    .ffm-radio-field{
        label{
            font-size: 14px;
            float: left;
            width: 50%;
            padding-right: 10px;
            position: relative;
            padding-left: 22px;
            margin-bottom: 10px;
            input{
                position: absolute;
                left: 0;
                top:0;
                margin-top: 6px;
            }
        }
    }
}
#socio_haveknown_otro-wrap, #donante_haveknown_otro-wrap{
    @media screen and (min-width:  $screen-md) {
        width:50%;
        margin-top: 48px;
    }
}
/* Child C Field modalidad de pago */
form[id*='give-form'] #give-gateway-radio-list{
    &>li{
        margin-top: 15px;
        &:first-of-type{
            margin-top: 0;
        }
        label{
        font-weight: normal;
        }
    }
 }
 /* Child D Field certificado */
 #sociocert-wrap{
    margin-bottom: 12px;
    padding: 0;
    &>label{
        background-color: transparent;
        font-size: 1.15em;
        font-weight: 700;
        margin: 10px 0 15px;
        padding: 0 0 5px;
        border-bottom: 1px solid rgba(0,0,0,.1);
        width: 100%;
    }
 }
 #ffm_html-wrap{
    font-weight: normal;
    margin-bottom: 0;
 }
/*LABEL FL*/
[id*='give-form'].give-fl-form {
    .give-fl-has-focus{
        label.give-label{
            color:$verde;
        }
    }
    input.give-fl-input{
        border-radius:4px;
    }
}
/*Agradecimientos */
.give-form__summary{
    display:block;
    text-align: left;
    margin-bottom:20px;
    p:first-of-type{
        font-weight: bold;
        margin-bottom: 0;
        font-size: 2.4rem;
        @media screen and (min-width:  $screen-lg-min) {
            margin-bottom: 25px;
            text-align: center;
            font-size: 3.4rem;
        }
    }
    @media screen and (min-width:  $screen-sm) {
        display:block;
        font-size: 4rem;
        text-align: left;
    }
    @media screen and (min-width:  $screen-md) {
        font-size: 3.5rem;
    }
}
/*Resumen de cantidad total */
form[id*=give-form] #give-final-total-wrap{
    font-size:20px;
    margin: 0;
    .give-donation-total-label {
        display: none;
    }
    #recurring-modal-period-wrap{
        display: block;
        width: 100%;
        padding: 0;
    }
    .give-final-total-amount, #give-recurring-modal-period{
            font-weight: 700;
            padding: 0;
            height: auto;
            display: block;
            float: left;
            margin-bottom: 15px;
            color: #89c51d;
            background: transparent;
            border: 0;
            font-size: 38px;
            @media screen and (min-width:  $screen-sm) {
                width: 100%;
                display: block;
                text-align: center;
                font-size: 48px;
            }
            @media screen and (min-width:  $screen-lg-min) {
                margin-bottom: 20px;
            }
    }
    .give-form__summary__period{
        display: block;
        clear: both;
        margin-bottom: 15px;
        @media screen and (min-width:  $screen-sm) {
            text-align: center;
        }
        p{
            margin: 0;
        }
    }
    #give-recurring-modal-period-wrap{
        display: block;
        width: 100%;
        padding: 0;
        margin-bottom: 30px;
    }
}
#next--Payment{
        width: calc(100% - 30px);
        margin-left: 15px;
        height: 60px;
        line-height: 60px;
        padding: 0;
        &:before{
            @extend .fa;
            @extend .fa-sm;
            @extend .fa-chevron-right;
        }
}
#give-form-670-1,#give-form-671-1{
    #next--Payment{
        @media screen and (min-width:  $screen-md) {
        margin-top: -215px;
        display: block;
            position: relative;
        }
    }
}
#give-form-668-wrap {
    #next--Payment{
        @media screen and (min-width:  $screen-md) {
        top: -44px;
        display: block;
            position: relative;
        }
    }
}
#give-form-669-wrap{
    #next--Payment{
        @media screen and (min-width:  $screen-md) {
        top: -1px;
        display: block;
            position: relative;
        }
    }
}
#toValidate{
    width: 58%;
    height: 60px;
    line-height: 60px;
    padding: 0 20px;
    margin-left: 2%;
    &:before{
        @extend .fa;
        @extend .fa-sm;
        @extend .fa-chevron-right;
    }
    @media screen and (min-width:  $screen-md) {
        width: 48%;
        margin-left: 2%;
        margin-right: 0;
    }
}
.backStep1{
    width: 38%;
    height: 60px;
    line-height: 60px;
    padding: 0 10px;
    border:1px solid $naranja;
    margin-right: 2%;
    &:before{
        content: '←';
        margin-right: 10px;
    }

    @media screen and (min-width:  $screen-md) {
        padding: 0 20px;
        width: 30%;
        margin-right: 2%;
        margin-left: 18%;
    }
}
.backStep2{
    display: block;
    width: auto;
    padding: 10px;
    max-width: 189px;
    margin: 15px auto 30px;
    &:before{
        content: '←';
        margin-right: 10px;
    }
    @media screen and (min-width:  $screen-sm) {
        margin-left: 0;
    }
}
/*Terminos y condiciones */
.give_terms_links{
    display: block;
    width: 100%;
    text-align: right;
    margin-bottom: 16px;
}
form[id*=give-form] #give_terms_agreement label{
    width: calc(100% - 30px);
    float: right;
    margin-bottom: 22px;
}
#give_terms{
    height: 400px;
    overflow-y: scroll;
    @extend .capsule;
    -webkit-box-shadow: inset 1px 1px 1px 1px rgba(0,0,0,0.13);
    -moz-box-shadow: inset 1px 1px 1px 1px rgba(0,0,0,0.13);
    box-shadow: inset 1px 1px 1px 1px rgba(0,0,0,0.13);
}
/*=========
-lineSteps
============*/
.lineSteps{
    display:none;
    @media screen and (min-width:  $screen-sm) {
     display: table;
    }
    @media screen and (min-width:  $screen-sm) {
    position: relative;
    width:100%;
    height: 72px;
    margin:0 auto;
    color:#9c9c9c;
        &:before{
            content:'';
            display: block;
            width:100%;
            height: 8px;
            position: absolute;
            bottom:0;
            background-color:#fff;
        }
    }
    @media screen and (min-width:  $screen-md) {
        height: 90px;
    }
}
.lineSteps__order{
    display:table-cell;
    width:24px;
    font-size: 36px;
    vertical-align: top;
    line-height: 1;
    font-weight: bold;
}
.lineSteps__text{
    display:table-cell;
    font-size:14px;
    padding:0 15px;
    font-weight: 300;
}
.lineSteps__bar{
    position: absolute;
    bottom: 0;
    left: 0;
    width: 33%;
    height: 8px;
    background-color: $naranja;
    transition: width 2s ease-out;
    &.passed-2{
        color: $naranja;
        width: 66.66%;
    }
    &.passed-3{
        color: $naranja;
        width: 100%;
    }
}
.lineSteps__item{
    display:table-row;
    position: relative;
    padding:0;
    height: 90px;
    &:hover{
        opacity: 0.5;
    }
    &.current{
        color:$naranja;
    }
}
.wrapperlineSteps{
    @media screen and (min-width:  $screen-sm) {
        margin-bottom: 50px
    }
    @media screen and (min-width:  $screen-md) {
        margin-left:0;
        margin-right: 0;
        margin-top: 15px;
        padding-left: 15px;
        margin-bottom: 50px
    }
}
.lineSteps__triggers{
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 3;
}

#resetFormSteps span
{
    color: #9c9c9c;
    font-size: 24px;
}

.label-error
{
    height: 33px;
    display: block;
    position: absolute;
    top: -35px;
    left: 5px;
    float: left;
    background: rgb(221, 51, 51) !important;
    border-radius: 3px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    padding: 8px;
    color: white;
    text-transform: uppercase;
    font-size: 14px;

    @media screen and (max-width: 529px)
    { 
        font-size: 12px !important;
    }
    @media screen and (min-width: 530px) and (max-width: 767px)
    { 
        font-size: 10px !important;
    }   
    @media screen and (min-width: 768px) and (max-width: 991px)
    {
        font-size: 11px !important;
    }
    @media screen and (min-width: 992px) and (max-width: 1199px)
    {
        font-size: 12px !important;
    }
}

.label-error-acc-number
{
    height: 73px;
    display: block;
    position: absolute;
    top: 102px;
    float: left;
    background: rgb(221, 51, 51) !important;
    border-radius: 3px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    padding: 8px;
    color: white;
    font-size: 14px;

    @media screen and (max-width: 529px)
    { 
        font-size: 12px !important;
    }
    @media screen and (min-width: 530px) and (max-width: 767px)
    { 
        font-size: 10px !important;
    }   
    @media screen and (min-width: 768px) and (max-width: 1157px)
    {
        font-size: 11px !important;
    }
    @media screen and (min-width: 1158px) and (max-width: 1199px)
    {
        font-size: 14px !important;
    }
}

.label-error-tit-name
{
    height: 33px;
    display: block;
    position: absolute;
    top: 6px;
    float: left;
    background: rgb(221, 51, 51) !important;
    border-radius: 3px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    padding: 8px;
    color: white;
    text-transform: uppercase;
    font-size: 14px;

    @media screen and (max-width: 529px)
    { 
        font-size: 12px !important;
    }
    @media screen and (min-width: 530px) and (max-width: 767px)
    { 
        font-size: 10px !important;
    }   
    @media screen and (min-width: 768px) and (max-width: 991px)
    {
        font-size: 11px !important;
    }
    @media screen and (min-width: 992px) and (max-width: 1157px)
    {
        font-size: 12px !important;
    }
    @media screen and (min-width: 1158px) and (max-width: 1199px)
    {
        font-size: 14px !important;
    }
}

.label-error-agreeterms
{
    height: 33px;
    display: block;
    position: relative;
    float: left;
    top: -222px;
    background: rgb(221, 51, 51) !important;
    border-radius: 3px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    padding: 8px;
    color: white;
    text-transform: uppercase;
    font-size: 14px;

    @media screen and (max-width: 529px)
    { 
        font-size: 9px !important;
        top: -245px !important;
    }
    @media screen and (min-width: 530px) and (max-width: 767px)
    { 
        font-size: 10px !important;
    }   
    @media screen and (min-width: 768px) and (max-width: 991px)
    {
        font-size: 11px !important;
    }
    @media screen and (min-width: 992px) and (max-width: 1157px)
    {
        font-size: 12px !important;
    }
    @media screen and (min-width: 1158px) and (max-width: 1199px)
    {
        font-size: 14px !important;
    }
}

[id*="give-form"].give-fl-form input.give-fl-input
{
    box-shadow: none !important;
}

#give-recurring-form .form-row, form.give-form .form-row, form[id*=give-form] .form-row {
    margin: 0 0 26px;
}

#poblacin-wrap
{
    clear: both;
}

.flex-colabora-gracias
{
    @media screen and (min-width: 768px) and (max-width: 991px)
    {
        -webkit-flex-basis: 50% !important;
        -ms-flex-preferred-size: 50% !important;
        flex-basis: 50% !important;
    }
    @media screen and (min-width: 992px) and (max-width: 1157px)
    {
        -webkit-flex-basis: 60% !important;
        -ms-flex-preferred-size: 60% !important;
        flex-basis: 60% !important;
    }
    @media screen and (min-width: 1158px) and (max-width: 1199px)
    {
        -webkit-flex-basis: 66% !important;
        -ms-flex-preferred-size: 66% !important;
        flex-basis: 66% !important;
    }
    @media screen and (min-width: 1200px)
    {
        -webkit-flex-basis: 71% !important;
        -ms-flex-preferred-size: 71% !important;
        flex-basis: 71% !important;
    }
}

.give-submit-button-wrap label.mb20 
{
    margin-left: 8px !important;
}

#donante_haveknown-wrap
{
    clear: left;
}

.give-recurring-multi-level-message
{
    position: absolute;
    top: 305px;
    left: 44%;
    font-weight: 800;
    font-size: 22px;

    @media screen and (max-width: 529px)
    { 
        font-size: 0.85em;
        left: 0%;
        top: 325px;
    }
    @media screen and (min-width: 530px) and (max-width: 767px)
    { 
        left: 0%;
    }  
}

.give-recurring-multi-level-message-es
{
    position: absolute;
    top: 305px;
    left: 44%;
    font-weight: 800;
    font-size: 22px;

    @media screen and (max-width: 529px)
    { 
        font-size: 0.85em;
        left: 0%;
        top: 325px;
    }
    @media screen and (min-width: 530px) and (max-width: 767px)
    { 
        left: 0%;
    }  
}


/*========
Shame
==========*/
/*Custom específicos orden de campos*/
[id*=give-form] .give-recurring-donors-choice select.give-recurring-donors-choice-period option[value=day]{
    display: none;
}

/*Modulo pago CC */
/* CUSTOMS */

#give_purchase_form_bank_account{
    width: 100%;
    margin-bottom: 35px;
    padding-bottom: 20px;
    @media screen and (min-width:  $screen-md) {
        width: 40%;
        padding: 15px;
        position: absolute;
        top:0;
        left:0;
    }
    input{
        margin-bottom: 30px;
    }
    label{
        font-weight: normal;
    }
    input:not(#account-tit-name){
        margin-bottom: 15px;
        margin-right:2%;
        width:32%;
        &:first-of-type{
             margin-bottom:30px;
        }
        &:last-of-type{
            margin-right:0;
            width:66%;
        }
        &:nth-of-type(4){
            margin-right:0;
        }
    }
    .label-aclaration{
        display: block;
        font-weight: normal;
        margin-bottom: 10px;
        cursor: default;
    }
}
.js-prefixPhone::before{
    content: none !important;
}

.linestep-selected{
    color: #f27d00 !important;
}

.linestep-noselected{
    color: #9c9c9c !important;
}
/*Custom específicos teléfono*/
#sociophone-wrap,#donantephone-wrap{
    .js-prefixPhone{
        display: block;
        float:left;
        width:100px;
        height: 50px;
        padding: 0;
        margin: 0;
        select{
            height: 50px;
            font-weight: 800;
        }
    }
    .give-fl-wrap{
        display: block;
        width:calc(100% - 100px);
        float:left;
        margin: 0;
    }
}

/*
==========
PROYECTOS Preseccionados para donar
==========
*/

.preselect-proyect
{
    height: 100px;
    padding: 0 15px 15px;
    border-bottom: 1px solid silver;
    margin-bottom: 16px;

    @media screen and (max-width: 479px)
    { 
        height: 200px;
    }
    @media screen and (min-width: 480px) and (max-width: 767px)
    { 
        height: 200px;
    } 
}
.preselect-proyect-photo
{
    width: 160px;

    @media screen and (max-width: 479px)
    { 
        display: none;
    }
    @media screen and (min-width: 480px) and (max-width: 767px)
    { 
        display: none;
    }   
    @media screen and (min-width: 768px) and (max-width: 991px)
    {
        width: 134px;
    }
}
.preselect-proyect-photo img
{
    width: 125px !important;
    height: 82px !important;
}
.preselect-proyect-text
{
    width: 40%;
    font-size: 16px;
    padding: 8px;

    @media screen and (max-width: 479px)
    { 
        width: 100%;
    }
    @media screen and (min-width: 480px) and (max-width: 767px)
    { 
        width: 100%;
    } 
}
.preselect-proyect-text-name
{
    color: #79af19;
    font-weight: 700;
}
.preselect-proyect-data
{
    width: 25%;
    height: 56px;
    border-left: 1px solid silver;
    margin: 10px;
    padding-left: 18px;

    @media screen and (max-width: 479px)
    { 
        width: 100%;
        border-left: 0;
        margin: 0;
        padding-left: 0;
    }
    @media screen and (min-width: 480px) and (max-width: 767px)
    { 
        width: 100%;
        border-left: 0;
        margin: 0;
        padding-left: 0;
    } 
    @media screen and (min-width: 768px) and (max-width: 991px)
    {
        width: 31% !important;
        padding-left: 0 !important;
    }
}
.preselect-proyect-data:before
{
    content:" "; 
    display:inline-block; 
    height:100%; 
    vertical-align:middle;
}
.preselect-proyect-data p
{
    display:inline-block;
    margin: 0 !important;
    background-color: #fff;
    padding: 7px;
    border-radius: 7%;
}
.preselect-proyect-data-needed, .preselect-proyect-data-conseg
{
    font-weight: 800;
}
.preselect-proyect-action
{
    width: 15%;
    text-align: right;
    float: right !important;
    cursor: pointer;
    
    @media screen and (max-width: 479px)
    { 
        width: 100%;
    }
    @media screen and (min-width: 480px) and (max-width: 767px)
    { 
        width: 100%;
    }   
    @media screen and (min-width: 768px) and (max-width: 991px)
    {
        width: 6%;
        font-size: 14px;
    }
    @media screen and (min-width: 992px) and (max-width: 1199px)
    {
        width: 14%;
        font-size: 14px;
    }
}
.preselect-proyect-section
{
    float: left;
    font-family: Nunito,Helvetica Neue,Helvetica,Arial,sans-serif;
}
.preselect-proyect-action-unique
{
    font-family: Nunito,Helvetica Neue,Helvetica,Arial,sans-serif;
    width: 100%;
    text-align: right;
    cursor: pointer;
}
.preselect-proyect-action-show
{
    @media screen and (max-width: 479px)
    { 
        margin-right: 10px;
    }
    @media screen and (min-width: 480px) and (max-width: 767px)
    { 
        margin-right: 10px;
    } 
}

#give-donation-level-select-668, #give-donation-level-select-669
{
    background-color: #f5f5f5;
    color: #79af19;
    font-weight: 800;
    height: 64px;
    border: 1px solid #89c51c;
    font-size: 22px;
    width: 340px;

    @-moz-document url-prefix()
    {
        margin: 32px 0 0 !important;
    }
}

#give-form-668-1 .give-donation-amount, #give-form-669-1 .give-donation-amount
{
    text-align: left;
}

.text-title-soc-exp
{
    font-size: 1.15em;
    font-weight: 700;
    position: relative;
    top: 11px;
}