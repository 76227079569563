// @import "vars";
// $screen-xs-max:                   767px !default;
// $navbar-default-link-color:       #777 !default;
// $navbar-padding-vertical:         10px !default;
// $navbar-padding-horizontal:       15px !default;
// $navbar-default-bg:               #f8f8f8 !default;
// $navbar-inverse-bg:               #222 !default;

// Offcanvas vars
$offcanvas-width:                 300px !default;
$offcanvas-animation-time:        300ms !default;
// $offcanvas-toggle-bars-color:     #000 !default;
$offcanvas-media:                 $screen-sm-max !default;

@media screen and (max-width: $offcanvas-media) {

  .offcanvas-stop-scrolling {
    height: 100%;
    overflow: hidden;
  }

  // .navbar-default {

  //   .navbar-offcanvas {
  //     background-color: $navbar-default-bg;
  //   }
  // }

  // .navbar-inverse {

  //   .navbar-offcanvas {
  //     background-color: $navbar-inverse-bg;
  //   }
  // }

  .navbar-offcanvas {
    position: absolute; // fixed;
    width: 100%;
    height: calc(100vh - (40px + 60px));
    max-width: $offcanvas-width;
    left: -$offcanvas-width;
    top: 0;
    padding-left: $navbar-padding-horizontal;
    padding-right: $navbar-padding-horizontal;
    z-index: $zindex-navbar-fixed;
    overflow-x: hidden;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    transition: all $offcanvas-animation-time $bezier;

    .scrolled-mobile & {
      height: calc(100vh - 60px);
    }

    &.in {
      box-shadow: -1px 2px 3px rgba(#000, 0.5);
    }

    &.navbar-offcanvas-fade {
      opacity: 0;

      &.in {
        opacity: 1;
      }
    }

    &.offcanvas-transform {

      &.in {
        transform: translateX($offcanvas-width);
      }
    }

    &.offcanvas-position {

      &.in {
        left: 0;
      }
    }

    &.navbar-offcanvas-right {
      left: auto;
      right: -$offcanvas-width;

      &.offcanvas-transform.in {
        transform: translateX(-$offcanvas-width);
      }

      &.offcanvas-position.in {
        left: auto;
        right: 0;
      }
    }

    // .dropdown {
    //   &.open .caret {
    //     border-top: 0;
    //     border-bottom: 4px solid;
    //   }
    // }

    // .dropdown-menu {
    //   position: relative;
    //   width: 100%;
    //   border: inherit;
    //   box-shadow: none;
    //   transition: height $offcanvas-animation-time ease-in;

    //   &.shown {
    //     display: block;
    //     margin-bottom: 10px;
    //   }
    // }
  }
} // @media (max-width: $offcanvas-media)

// .offcanvas-toggle {

//   .icon-bar {
//     background: $offcanvas-toggle-bars-color;
//     transition: all .25s ease-in-out;
//   }

//   &.is-open {

//     .icon-bar:nth-child(1) {
//       transform: rotate(45deg) translate(5px, 4px);
//     }

//     .icon-bar:nth-child(2) {
//       opacity: 0;
//     }

//     .icon-bar:nth-child(3) {
//       transform: rotate(-45deg) translate(4px, -4px);
//     }
//   }
// }
