.odometer {

  &.odometer-auto-theme,
  &.odometer-theme-codespa {
    display: inline-block;
    vertical-align: middle;
    *vertical-align: auto;
    *zoom: 1;
    *display: inline;
    position: relative;

    .odometer-digit {
      display: inline-block;
      vertical-align: middle;
      *vertical-align: auto;
      *zoom: 1;
      *display: inline;
      position: relative;

      .odometer-digit-spacer {
        display: inline-block;
        vertical-align: middle;
        *vertical-align: auto;
        *zoom: 1;
        *display: inline;
        visibility: hidden;
      }

      .odometer-digit-inner {
        text-align: center;
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        overflow: hidden;
      }

      .odometer-ribbon {
        display: block;
      }

      .odometer-ribbon-inner {
        display: block;
        -webkit-backface-visibility: hidden;
      }

      .odometer-value {
        display: block;
        -webkit-transform: translateZ(0);
      }

      .odometer-value.odometer-last-value {
        position: absolute;
        width: 100%;
      }
    }

    &.odometer-animating-up {

      .odometer-ribbon-inner {
        transition: transform 600ms;
      }

      &.odometer-animating {

        .odometer-ribbon-inner {
          transform: translateY(-100%);
        }
      }
    }

    &.odometer-animating-down {

      .odometer-ribbon-inner {
        transform: translateY(-100%);
      }

      &.odometer-animating {

        .odometer-ribbon-inner {
          transition: transform 600ms;
          transform: translateY(0);
        }
      }
    } // .odometer-animating-down
  } // .odometer-theme-codespa
} // .odometer
