//
// Alerts
// --------------------------------------------------

.alert {

  .close {
    margin-top: -.5em;
    color: $text-color;
    text-shadow: none;
    opacity: 1;
  }

  .alert-link {
    text-decoration: underline;
    transition: all 200ms ease-in-out;
  }
}

.alert-success {

  .close {
    color: $alert-success-text;
  }

  hr {
    border-top-color: $alert-success-text;
  }

  .alert-link {
    color: $alert-success-text;

    &:hover,
    &:focus {
      color: $text-color;
    }
  }
}

.alert-info {

  .close {
    color: $alert-info-text;
  }

  hr {
    border-top-color: $alert-info-text;
  }

  .alert-link {
    color: $alert-info-text;

    &:hover,
    &:focus {
      color: $text-color;
    }
  }
}

.alert-warning {

  .close {
    color: $alert-warning-text;
  }

  hr {
    border-top-color: $alert-warning-text;
  }

  .alert-link {
    color: $alert-warning-text;

    &:hover,
    &:focus {
      color: $text-color;
    }
  }
}

.alert-danger {

  .close {
    color: $alert-danger-text;
  }

  hr {
    border-top-color: $alert-danger-text;
  }

  .alert-link {
    color: $alert-danger-text;

    &:hover,
    &:focus {
      color: $text-color;
    }
  }
}
