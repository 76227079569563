/*Breakpoint

    @media screen and (min-width: $screen-lg-min) {
      padding: 40px 0;
    }

    $screen-xs:     480px !default;
    $screen-sm:     768px !default;
    $screen-md: 992px !default;
    $screen-lg-min  1200px
    $screen-xl:     1440px !default;
*/

/*SCOPE */
.page-template-template-colabora{
    // .section-colabora {
    //     margin-top:0;
    //     @media screen and (min-width:  $screen-xs) {
    //         margin-top:15px;
    //     }
    //     @media screen and (min-width:  $screen-md) {
    //         margin-top:30px;
    //     }
    // }
    .section-colabora .panel.panel-form-give{
        margin-left: -15px;
        margin-right: -15px;
        background-color: #f5f5f5;
        border:0;
        overflow: hidden;
        @media screen and (min-width:  $screen-sm) {
            padding: 15px;
        }
        @media screen and (min-width:  $screen-md) {

            margin-left: 0;
            margin-right: 0;
        }
        @media screen and (min-width:  $screen-lg-min ){
            padding: 80px;
        }
    }
    .give-form-wrap{
        width:100%;
        position:relative;
        @media screen and (min-width:  $screen-md) {
            margin: 0 auto;
        }
    }
    .section-colabora .panel.panel-form-give .panel-body{
        padding-top: 0;
        padding-left: 15px;
        padding-right: 15px;
        position: relative;
        @media screen and (min-width:  $screen-md) {
            padding-top: 5px;
        }
    }
    .give-form{
        display: flex;
        flex-direction: column;
        max-width: 100%;
        margin:0 auto;
    }
    form[id*='give-form'],#give-recurring-form, form.give-form{
        position: relative;
        display: flex;
        flex-direction: column;
        margin:0 auto;
    }
    /*=====================
    STEP 1 cantidad aportada
    =======================*/
    .step1{
        display: flex;
        flex-direction: column;
        overflow: hidden;
        order: 1;
        position: relative;
        @media screen and (min-width:  $screen-md) {
            flex-direction: row;
            flex-wrap: wrap;
            margin: 0 -15px;
        }
    }
    /*========================
    STEP 2 datos de de contacto
    ==========================*/
    #give_purchase_form_wrap{
        position: relative;
        width:100%;
        order: 2;
        @media screen and (min-width:  $screen-md) {
            max-width: 960px;
            align-self: center;
        }
        @media screen and (min-width:  $screen-lg-min) {
            max-width: 100%;
        }
    }
    .step2{
        width: 100%;
        display: none;
        @media screen and (min-width:  $screen-md) {
            overflow: hidden;
            opacity:0;
            transition: all 1s ease;
            -webkit-transition: all 1s ease;
            -moz-transition: all 1s ease;
            &:target{
                display: block;
                opacity:1;
                transition: all 1s ease;
                -webkit-transition: all 1s ease;
                -moz-transition: all 1s ease;
            }
        }
    }
    .step3{
        width: 100%;
        overflow: hidden;
        display:none;
        @media screen and (min-width:  $screen-md) {
            padding: 0 50px;
            width:60%;
            float: right;
            &:target{
                position: relative;
                display: block;
                opacity:1;
                transition: all 1s ease;
                -webkit-transition: all 1s ease;
                -moz-transition: all 1s ease;
            }
        }
        @media screen and (min-width:   $screen-lg-min) {
            max-width: 670px;
            padding-left: 50px;
            
        }
    }
    footer.well{
        flex-direction: column;
        @media screen and (min-width:  $screen-md) {
            flex-direction: row;
        }
        .badges a {
            margin: 15px;
        }
    }
    .heroeForm{
        &>div{
            display: flex;
            flex-direction: column;
            @media screen and (min-width:  $screen-md) {
                flex-direction: row;
                margin: 0 auto;
            }
        }
    }
}/*END SCOPE */

