// footer
.content-info {

  // .content-info-xxx {
  //   @include responsive-padding();
  //   background-color: $gris-claro;
  //   border-top: 1px solid $gris-medio;
  // }


  // Formulario Newsletter
  .content-info-contacto {
    padding: 30px 0;
    font-size: 18px;
    line-height: 1.5;
    background-color: $gris-oscuro;
    color: #fff;

    @media screen and (min-width: $screen-lg-min) {
      padding: 40px 0;
    }

    section {
      margin-bottom: 30px;

      @media screen and (min-width: $screen-md-min) {
        margin-bottom: 0;
      }
    }

    h3 {
      margin-top: 0;
      color: $brand-primary;
    }

    a {
      color: #FFF;
      text-decoration: none;
      transition: all 200ms ease-in-out;

      &:hover,
      &:focus {
        color: $brand-primary;
      }

      &[rel="external"] {

        .fa-external-link {
          display: none;
        }

        &:hover,
        &:focus {

          .fa-external-link {
            display: inline-block;
          }
        }
      }
    }

    .phone {

      @media screen and (max-width: $screen-xs-max) {
        font-size: $font-size-xlarge;
      }
    }

    .address {

      @media screen and (min-width: $screen-md-min) and (max-width: $screen-md-max) {
        font-size: 14px;
        line-height: 18px * 1.5;
      }
    }

    .social-links {
      margin-top: -0.75em;
      margin-bottom: 0;

      @media screen and (min-width: $screen-sm-min) {
        margin-top: 2em;
      }

      a {
        color: #fff;

        &:hover,
        &:focus {
          opacity: 0.3;
        }
      }
    }

    #newsletter {
      margin-top: 40px;

      @media screen and (min-width: $screen-md-min) {
        margin-top: 0;
      }

      > h3 + p {
        margin-bottom: 10px;
        font-size: 20px;
        line-height: $headings-line-height;
      }
    }

    .form-group-xl {
      margin: 0;
      padding: 10px;
      background-color: #fff;
      border-radius: $border-radius-base;

      #newsletter-email {
        background-color: transparent;
        border-color: transparent;
        box-shadow: none;
        border-top-left-radius: 3px;
        border-bottom-left-radius: 3px;
      }

      // Destacar luego de scroll automático a Newsletter
      &.highlight-warning {
        animation-name: highlightWarning;
        animation-duration: 3s;
      }

      .input-group-btn {
        vertical-align: top;

        > .btn {
          border-top-left-radius: 3px;
          border-bottom-left-radius: 3px;
          margin-left: inherit;
        }
      }

      .wpcf7-not-valid-tip {
        font-size: $font-size-small;
        line-height: $line-height-base;
        color: #fff;
      }
    } // .form-group-xl

    .wpcf7-checkbox {

      .wpcf7-list-item-label {
        font-size: 18px;
        line-height: 1.25;
      }
    }

    .wpcf7-acceptance {

      .wpcf7-list-item-label {
        font-size: 14px;
        line-height: 1.25;
      }
    }
  } // .content-info-contacto


  .content-info-menu {
    display: none;
    padding: 30px 0;
    background-color: $gris-blanco;

    @media screen and (min-width: $screen-sm-min) {
      display: block;
    }

    @media screen and (min-width: $screen-lg-min) {
      padding: 40px 0;
    }

    .menu {
      position: relative;
      display: flex;
      justify-content: space-between;
      @include list-unstyled;
      margin: 0 -15px;
      font-size: $font-size-h5;

      @media screen and (min-width: $screen-md-min) {
        font-size: $font-size-h4;
      }

      @media screen and (min-width: $screen-lg-min) {
        font-size: $font-size-h3;
      }

      a {
        transition: all 200ms ease-in;
      }

      > li {
        flex: 0 1 auto;
        padding: 0 0 0 15px;

        > a {
          display: block;
          font-weight: 700;

          &:hover,
          &:focus {
            text-decoration: none;
          }
        }

        &.active {

          > a {
            color: $link-hover-color;
          }
        }

        // Proyectos, Blog
        &:not(.menu-item-has-children) {
          position: absolute;
          top: 0;
          left: 0;

          > a {
            margin-bottom: 0;
          }
        }

        .sub-menu {
          @include list-unstyled;
          margin: (1.25em / 2) 0;
          font-size: $font-size-small;

          @media screen and (min-width: $screen-md-min) {
            font-size: $font-size-medium;
          }

          @media screen and (min-width: $screen-lg-min) {
            font-size: $font-size-base;
          }

          > li {
            margin-bottom: 0.75em;

            > a {
              display: block;
              padding-left: 1em;
              font-weight: 600;
              color: #000;

              &:before {
                @extend .fa;
                content: '\f054';
                float: left;
                margin-left: -1.3334em;
                font-size: 66.6667%;
                line-height: 2;
              }

              &:hover,
              &:focus {
                color: $link-color;
                text-decoration: none;

                &:before {
                  color: #000;
                }
              }
            }

            &.active {

              > a {
                font-weight: 700;
                color: $link-hover-color;
              }
            }
          }
        } // .sub-menu
      }
    }
  } // .content-info-menu


  .content-info-legal {
    padding: 20px 0;
    background-color: $brand-primary;
    color: #000;

    ul {
      margin: 0;
      font-size: $font-size-small;
      font-weight: 600;
    }

    .sep {
      padding: 0;
    }

    a {
      color: #000;
    }
  } // .content-info-legal

} // .content-info
