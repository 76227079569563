// Headroom
.navbar-main.headroom {
  // transition: all 300ms $bezier; // ver _header.scss .navbar-main

  &.headroom--pinned {
    transform: translate(0%, 0%);

    // @media screen and (max-width: $screen-sm-max) {
    //   transform: translate(0%, 0%);
    // }

    // &.headroom--not-top {
    //   box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
    // }
  }

  &.headroom--unpinned {
    transform: translate(0%, -100%);

    // @media screen and (max-width: $screen-sm-max) {
    //   transform: translate(0%, -100%);
    // }
  }
}

#scrollUp.headroom {
  // transition: all 300ms $bezier;

  &.headroom--pinned {

    @media screen and (max-width: $screen-sm-max) {
      transform: translate(0, 0);
    }

    // &.headroom--not-top {
    //   box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
    // }
  }

  &.headroom--unpinned {

    @media screen and (max-width: $screen-sm-max) {
      transform: translate(0, 100px);
    }
  }
}

#wpadminbar{

  @media screen and (max-width: 782px) {
    position: fixed;
    top: 0;
  }
}
