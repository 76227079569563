.modal-body {
  font-size: $font-size-medium;

  b, strong {
    font-weight: 800;
  }
} // .modal-body


.modal-primary {

  .modal-header {
    background-color: $brand-primary;
    color: #FFF;
  }

  .modal-footer {
    background-color: $gray-lighter;
  }

  .modal-content {
    border: none;
  }

  .close {
    font-size: 40px;
    font-weight: 400;
    line-height: 25px;
    opacity: inherit;
    text-shadow: none;
    color: #FFF;
    transition: all 200ms ease-in-out;

    &:hover,
    &:focus {
      color: #FFF;
      text-decoration: none;
      cursor: pointer;
      opacity: 0.6;
      outline: 0;
    }
  }
} // modal-primary


// Centrar modal verticalmente
.modal-center {

  @media screen and (min-width: $screen-sm-min) {
    text-align: center;

    &:before {
      display: inline-block;
      vertical-align: middle;
      content: '';
      height: 100%;
    }

    .modal-dialog {
      display: inline-block;
      text-align: left;
      vertical-align: middle;
    }
  }
} // .modal-center
