// Scrolled margen
body {

  &.scrolled {

    @media screen and (min-width: $screen-md-min) {
      margin-top: 120px + 40px; // .navbar-main + .nav-colabora
    }

    &.home,
    &.page-template-template-campana-donacion {

      @media screen and (min-width: $screen-md-min) {
        margin-top: 120px; // .navbar-main
      }
    }

    // ie fix
    &.ie {

      @media screen and (min-width: $screen-md-min) {
        margin-top: 120px; // .navbar-main
      }
    }
  }

  &.scrolled-mobile {

    @media screen and (max-width: 419px) {
      margin-top: 40px + 60px + 30px; // .navbar-top + .navbar-main + .nav-colabora
    }

    @media screen and (min-width: 420px) and (max-width: $screen-sm-max) {
      margin-top: 40px + 60px + 40px; // .navbar-top + .navbar-main + .nav-colabora
    }
  }
}

#main-header {
  margin-bottom: 30px;

  @media screen and (min-width: 420px) {
    margin-bottom: 40px;
  }

  .home &,
  .page-template-template-campana-donacion & {

    @media screen and (min-width: $screen-md-min) {
      margin-bottom: 0;
    }
  }
}

// Contenedor fluido hasta $screen-sm-max
.navbar-top-mobile,
.navbar-main {

  > .container {

    @media screen and (min-width: $grid-float-breakpoint) and (max-width: $screen-sm-max) {
      width: auto;
    }

    > .navbar-header {

      @media screen and (min-width: $grid-float-breakpoint) and (max-width: $screen-sm-max) {
        margin-right: -$navbar-padding-horizontal;
        margin-left:  -$navbar-padding-horizontal;
      }
    }

    .navbar-brand {

      @media screen and (min-width: $grid-float-breakpoint) and (max-width: $screen-sm-max) {
        margin-left: 0;
      }
    }
  }
}


.navbar-top-mobile {
  position: relative;
  z-index: ($zindex-navbar-fixed + 1);
  background-color: #eee;

  .home &
  .page-template-template-campana-donacion & {
    background-color: #fff;
  }

  @media screen and (min-width: $screen-md-min) {
    display: none;
  }

  .scrolled-mobile & {
    display: none;
  }

  .navbar-left {
    float: left;
    margin-left: -15px;

    ~ .navbar-left {
      margin-left: 0;
    }

    .language-selector {
      margin-left: 10px;
    }
  }

  .navbar-right {
    float: right;
    margin-right: -15px;

    ~ .navbar-right {
      margin-right: 0;
    }

    .navbar-nav {
      margin-right: 10px;
    }
  }

  .navbar-nav {
    float: left;
    margin: 0;

    > li {
      float: left;
    }

    > .menu-item {

      > a {
        padding: 10px;
        font-size: 14px;
        font-weight: 900;
        text-transform: uppercase;
        color: #000;
        background-color: transparent;
        transition: background-color 200ms ease-in, color 200ms ease-in;

        &:hover,
        &:focus {
          text-decoration: none;
          background-color: transparent;
          color: $link-color;
        }

        > .active > a,
        > .current-menu-ancestor > a,
        > .current-page-parent > a,
        > .current_page_parent > a {
          &,
          &:hover,
          &:focus {
            background-color: transparent;
            color: $link-color;
            transition: none;
          }
        }
      } // > a
    } // > li.menu-item

    &.language-selector {

      > li {

        > a {
          text-transform: uppercase;
          color: $gris-medio;

          .home &
          .page-template-template-campana-donacion & {
            color: $gris;
          }
        } // > a

        &.active {

          > a {
            color: $link-color;
            // text-decoration: underline;

            &:after {
              content: '';
              position: absolute;
              width: calc(100% - 20px);
              bottom: 10px;
              left: 10px;
              border-top: 1px solid $link-color;
            }
          }
        }
      } // > li.menu-item

      > .sep {
        display: none;
      }
    } // .language-selector
  }

  // @media screen and (min-width: $screen-md-min) {
  //   display: none;
  // }
} // .navbar-top-mobile


.navbar-main {
  position: relative;
  background-color: #fff;
  border: none;
  margin-bottom: 0;
  transition: transform 300ms $bezier, background-color 200ms ease-in, box-shadow 200ms ease-in;

  @media screen and (max-width: $screen-sm-max) {
    height: 60px;
    box-shadow: $shadow-1;
  }

  @media screen and (min-width: $screen-md-min) {
    height: 120px;
    box-shadow: $shadow-1;

    .scrolled & {
      height: 60px;
    }
  }

  // Navbar semi-transparente
  .home:not(.scrolled) &,
  .error404:not(.scrolled) &,
  .page-template-template-campana-donacion:not(.scrolled) & {
    background-color: rgba(#fff, 0.75);
    box-shadow: none;

    // &:hover {
    //   background-color: rgba(#fff, 1);
    //   box-shadow: $shadow-1;
    // }
  }

  .offcanvas-stop-scrolling & {
    background-color: #fff !important;
  }

  .scrolled-mobile & {

    @media screen and (max-width: $screen-sm-max) {
      position: fixed;
    }
  }

  .scrolled & {

    @media screen and (min-width: $screen-md-min) {
      position: fixed;
    }
  }

  // Admin Bar fix
  .admin-bar.scrolled-mobile & {

    @media screen and (max-width: 782px) {
      top: 46px;
    }

    @media screen and (min-width: 783px) and (max-width: $screen-sm-max) {
      top: 32px;
    }
  }

  .admin-bar.scrolled & {

    @media screen and (min-width: $screen-md-min) {
      top: 32px;
    }
  }


  .navbar-header {

    @media screen and (min-width: $screen-sm-min) {
      float: none;
    }

    @media screen and (min-width: $screen-md-min) {
      float: left;
    }
  }

  .navbar-brand {
    position: relative;

    @media screen and (max-width: $screen-sm-max) {
      height: 60px;

      .codespa-logo-alt {
        width: 100px;
        height: 30px;
        // image-rendering: -webkit-optimize-contrast;
      }

      .codespa-logo {
        display: none;
        // image-rendering: -webkit-optimize-contrast;
      }
    }

    @media screen and (min-width: $screen-md-min) {
      width: 120px;
      height: 120px;
      padding: 10px 0;

      // &:hover,
      // &:focus {

      //   .codespa-logo {
      //     opacity: .8;
      //   }
      // }

      .codespa-logo-alt {
        position: absolute;
        left: 15px;
        bottom: 15px;
        width: 100px;
        height: 30px;
        opacity: 0;
        transition: all 200ms ease-in-out;
      }

      .codespa-logo {
        position: absolute;
        left: 15px;
        bottom: 10px;
        width: 100px;
        height: 100px;
        opacity: 1;
        transition: all 200ms ease-in-out;
      }

      // Escalar logo en hover
      // body:not(.scrolled) & {
      //   transition: transform 300ms $bezier;

      //   &:hover,
      //   &:focus {
      //     transform: scale(1.5);
      //   }
      // }
    }

    .scrolled & {

      @media screen and (min-width: $screen-md-min) {
        // width: 52.94px;
        height: 60px;
        padding: 0;

        .codespa-logo-alt {
          opacity: 1;
        }

        .codespa-logo {
          opacity: 0;
        }
      }
    }
  }

  .navbar-nav {

    @media screen and (max-width: $screen-sm-max) {
      float: none;
      margin: 0;

      > .menu-item {
        float: none;

        > a {
          position: relative;
          padding: 21px 20px 18px;
          color: #000;
          background-color: transparent;
          transition: background-color 200ms ease-in, color 200ms ease-in;

          font-size: 14px; // $font-size-base; // 16px
          line-height: 20px;
          font-weight: 900;
          text-transform: uppercase;

          border-bottom: 1px solid $gris;

          &:hover,
          &:focus {
            text-decoration: none;
            background-color: $gris;
            color: #000;
          }
        } // > a
      } // > li.menu-item

      > .active > a,
      > .current-menu-ancestor > a,
      > .current-page-parent > a,
      > .current_page_parent > a {
        &,
        &:hover,
        &:focus {
          background-color: #fff;
          color: $link-color;
          transition: none;
        }
      }

      .menu-item-has-children {
        transition: all 200ms ease-in;

        > a {
          padding-right: 50px;
        }

        .folder {
          position: relative;
          z-index: 1;
          display: block;
          width: 70px;
          height: 59px;
          padding: 15px;
          border-left: 1px solid $gris;
          float: right;
          font-size: 14px;
          line-height: 20px;
          text-align: center;
          cursor: pointer;
          background-color: #fff;
          transition: all 200ms ease-in;

          + a {
            transition: background-color 200ms ease-in;
          }

          &:hover,
          &:focus {
            background-color: $gris;

            + a {
              background-color: #fff;
            }
          }

          .fa-angle-up {
            display: none;
          }
        }

        &.open {

          .folder {
            background-color: $gris;

            .fa-angle-down {
              display: none;
            }

            .fa-angle-up {
              display: inline-block;
            }
          }

          &.active {

            .folder {

              + a {
                background-color: #fff;
              }
            }
          }
        }
      } // ..menu-item-has-children
    } // @ $screen-sm-max

    @media screen and (min-width: $screen-md-min) {
      float: none;
      margin: 0;

      .wf-loading & {
        visibility: hidden;
      }

      > .menu-item {
        float: left;

        > a {
          position: relative;
          padding: 20px;
          color: $text-color;
          background-color: transparent;
          transition: background-color 200ms ease-in, color 200ms ease-in;

          font-size: $font-size-medium; // 14px
          line-height: 20px;
          font-weight: 900;
          text-transform: uppercase;

          .wf-loading & {
            padding: 20px 15px;
          }

          i {
            color: $brand-primary;
          }

          &:hover,
          &:focus {
            color: $link-color;

            i {
              color: $text-color;
            }
          }
        } // > a

        &:hover,
        &:focus {

          .sub-menu {
            right: 50% !important;
            opacity: 1;
            transform: translate(50%, -4px);
          }
        }

        &:not(.menu-item-has-children) {

          &:hover,
          &:focus {

            > a:after {
              opacity: 1;
            }
          }
        }
      } // > li.menu-item

      > .active > a,
      > .current-menu-ancestor > a,
      > .current-page-parent > a,
      > .current_page_parent > a {
        &,
        &:hover,
        &:focus {
          // background-color: $gris;
          color: $link-color;
          // transition: none;
        }
      }

      .menu-item-has-children {

        .folder {
          display: none;
        }
      } // ..menu-item-has-children
    } // @ $screen-md-min


    @media screen and (min-width: $screen-lg-min) {
      float: none;
      margin: 0;

      > .menu-item {

        > a {
          font-size: $font-size-base; // 16px
        } // > a
      } // > li.menu-item
    } // @ $screen-lg-min
  } // .navbar-nav

  .sub-menu {

    @media screen and (max-width: $screen-sm-max) {
      display: none;
      margin: 0;
      padding: 0;
      border: none;
      list-style: none;
      text-align: left;
      font-size: 14px;
      line-height: 20px;
      background-color: transparent;
      background-clip: padding-box;

      > li > a {
        display: block;
        padding: 21px 20px 19px;
        clear: both;
        font-weight: 600;
        line-height: 20px;
        text-transform: uppercase;
        background-color: $gris;
        color: #000;
        // white-space: nowrap;
        transition: all 200ms ease-in;

        &:hover,
        &:focus {
          text-decoration: none;
          background-color: $gris-medio;
          color: #000;
        }
      }

      > .active > a {
        &,
        &:hover,
        &:focus {
          text-decoration: none;
          outline: 0;
          background-color: $gris-medio;
          color: #000;
        }
      }
    }

    @media screen and (min-width: $screen-md-min) {
      display: block;
      float: left;
      position: absolute;
      z-index: $zindex-dropdown;
      top: 100%;
      right: 9999px !important;
      min-width: 160px;
      margin: 0;
      padding: 0;
      border: none;
      list-style: none;
      text-align: left;
      font-size: 14px;
      line-height: 20px;
      background-color: transparent;
      background-clip: padding-box;
      box-shadow: $shadow-2;
      opacity: 0;
      transform: translate(50%, 30px);
      transform-origin: center top;
      transition: opacity 200ms ease-in, transform 200ms $bezier;
      // transition-delay: 200ms;

      &:before {
        @include triangle(top, $gris-oscuro, 7px);
        position: absolute;
        left: 50%;
        bottom: 100%;
        transform: translateX(-50%);
      }

      > li > a {
        display: block;
        padding: 10px 15px;
        clear: both;
        font-weight: normal;
        line-height: 20px;
        background-color: $gris-oscuro;
        color: #FFF;
        white-space: nowrap;
        transition: all 200ms ease-in;

        &:hover,
        &:focus {
          text-decoration: none;
          color: #FFF;
          background-color: darken($gris-oscuro, 5%);
        }
      }

      > .active > a {
        &,
        &:hover,
        &:focus {
          text-decoration: none;
          outline: 0;
          color: #FFF;
          background-color: darken($gris-oscuro, 5%);
        }
      }
    } // $screen-md-min
  } // .sub-menu

  // Submenú del último menu-item alineado a la derecha
  @media screen and (min-width: $screen-md-min) {
    .navbar-nav > .menu-item:last-child {

      &:hover,
      &:focus {

        .sub-menu {
          right: 100% !important;

          &:before {
            left: inherit;
            right: 30px;
            transform: unset;
          }
        }
      }
    }
  }


  // Offcanvas >Toggle
  .offcanvas-toggle {
    width: 70px;
    height: 60px;
    margin: 0;
    padding: 15px 20px;
    border: none;
    border-radius: 0;
    background-color: transparent;
    transition: background-color 200ms ease-in;

    @media (min-width: $screen-sm-min) {
      display: inherit;
    }

    @media (min-width: $screen-md-min) {
      display: none;
    }

    .icon-bar {
      background-color: #000;
      width: 30px;
      height: 6px;
      border-radius: 1px;
      transition: all 200ms ease-in;

      + .icon-bar {
        margin-top: 4px;
      }
    }

    &:hover,
    &:focus {
      // background-color: $brand-primary;

      .icon-bar {
        background-color: #000;
      }
    }

    &.is-open {
      background-color: $brand-primary;

      .icon-bar {
        width: 34px;

        &:nth-child(1) {
          transform: translate(7px, -1px) rotate(45deg);
          transform-origin:  0 0;
        }

        &:nth-child(2) {
          width: 20px;
          transform: translate(20px, 0);
          @include opacity(0);
        }

        &:nth-child(3) {
          transform: translate(7px, 1px) rotate(-45deg);
          transform-origin: 0 100%;
        }
      }
    }
  } // .offcanas-toggle


  // Ocultar menu Inicio en la home
  .home & {

    .navbar-offcanvas > .navbar-nav > .menu-item.active  {

      @media screen and (min-width: $screen-md-min) {
        display: none;
      }
    }
  }
} // .navbar-main


// Navbar-top
.navbar-top {

  @media screen and (max-width: $screen-sm-max) {
    display: none;
  }

  .navbar-nav {
    float: left;

    > li > a {

      + .tooltip {
        margin-top: -15px;
        pointer-events: none;
      }
    }

    > .menu-item {

      > a {
        text-transform: none;
        font-size: 14px;
        font-weight: 700;

        @media screen and (min-width: $screen-lg-min) {
          font-size: 15px;
        }
      } // > a
    } // > li.menu-item

    &.language-selector {

      > li {

        > a {
          padding-left: 0;
          padding-right: 0;
          text-transform: uppercase;
        } // > a

        &:first-child + li {

          > a {
            padding-left: 20px;
          } // > a
        }

        &:last-child {

          > a {
            padding-right: 20px;
          } // > a
        }
      } // > li.menu-item

      > .sep {
        padding: 20px .5ex;
        line-height: 20px;

        &:first-child {
          display: none;
        }
      }
    } // .language-selector

    // Separadores
    &:not(.language-selector) > .menu-item > a:after {
      content: '|';
      position: absolute;
      top: 20px;
      right: -1px;
      font-weight: 400;
    }
  }

  .scrolled & {

    @media screen and (min-width: $screen-md-min) {
      display: none;
    }
  }
} // .navbar-top


// Offcanvas
@media screen and (max-width: $offcanvas-media) {

  .navbar-offcanvas {
    top: 60px;
    margin: 0;
    padding: 0;
    background-color: #fff;
    color: #000;

    border-top: 1px solid $brand-primary;

    // &.in {
    //   border-top: 1px solid $brand-primary;
    // }
  }

  // Backdrop
  body {

    &:before {
      content: '';
      display: block;
      position: fixed;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: $zindex-navbar-fixed - 1;
      width: 0;
      transition: background-color 300ms ease;
    }
  }

  &.offcanvas-stop-scrolling {

    &:before {
      width: 100%;
      background-color: rgba(#000, 0.5);
    }


  }
}

@media screen and (min-width: ($offcanvas-media + 1)) {

  .navbar-offcanvas {
    clear: right;
    margin-right: -15px !important;
  }
}


// Botones Colabora
.nav-colabora {
  position: relative;
  font-size: 0;

  @media screen and (max-width: $screen-sm-max) {
    padding-left: 0;
    padding-right: 0;
  }

  > .wrap {
    display: flex;

    @media screen and (min-width: $screen-md-min) {
      display: block;
      position: absolute;
      right: 15px;
    }

    > .btn {
      float: left;
      border-radius: 0;
      // font-weight: 900;

      @media screen and (max-width: 420px) {
        // .btn-sm
        border-width: $border-width-small;

        @include button-size(
          $padding-small-vertical - $border-width-small + 3,
          $padding-small-horizontal - $border-width-small,
          $font-size-small,
          $font-size-small + 2,
          0 // $btn-border-radius-small
        );
      }

      @media screen and (max-width: $screen-sm-max) {
        flex: 1 0 auto;
      }

      &:first-child {

        @media screen and (min-width: $screen-md-min) {
          border-bottom-left-radius: 3px;
        }
      }

      &:last-child {
        border-left: 1px solid darken($brand-success, 5%);

        @media screen and (min-width: $screen-md-min) {
          border-bottom-right-radius: 3px;
        }
      }
    }
  }

  // Sólo botón de "Hazte Socio" antes de hacer scroll
  .home &,
  .page-template-template-campana-donacion & {

    @media screen and (min-width: $screen-md-min) {
      transform: translateY(0);
      transition: all 200ms ease-in-out;
    }

    > .wrap {

      > .btn {
        float: right;

        @media screen and (min-width: $screen-md-min) {
          border-left: none;
          border-right: 1px solid darken($brand-success, 5%);
        }

        &:first-child {

          @media screen and (min-width: $screen-md-min) {
            border-right: 0;
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 3px;
          }
        }

        &:last-child {

          @media screen and (min-width: $screen-md-min) {
            border-left: none;
            border-right: 1px solid darken($brand-success, 5%);
            border-bottom-right-radius: 0;
            border-bottom-left-radius: 3px;
          }
        }
      }
    }
  }

  .home:not(.scrolled) &,
  .page-template-template-campana-donacion:not(.scrolled) & {

    @media screen and (min-width: $screen-md-min) {
      transform: translateY(20px);
    }

    > .wrap {

      > .btn {

        @media screen and (min-width: $screen-md-min) {
          // .btn-lg
          border-width: $border-width-large;

          @include button-size(
            $padding-large-vertical - $border-width-large - 2,
            $padding-large-horizontal - $border-width-large,
            $font-size-large,
            $font-size-large + 4,
            $btn-border-radius-large
          );
        }

        @media screen and (min-width: $screen-lg-min) {
          // .btn-xl
          border-width: $border-width-xl;

          @include button-size(
            $padding-xl-vertical - $border-width-xl - 2,
            $padding-xl-horizontal - $border-width-xl,
            $font-size-xlarge,
            1,
            $btn-border-radius-xl
          );
        }

        &:last-child {

          @media screen and (min-width: $screen-md-min) {
            pointer-events: none;
            opacity: 0;
          }
        }
      }
    }
  }
} // .nav-colabora
