@charset "UTF-8";

// ----------------------------------------------------------------------------
// Slick Theme
// ----------------------------------------------------------------------------

// Default Variables
$slick-font-family:             "FontAwesome" !default;

// Arrows
$slick-arrow-color:             $gris-medio !default;
$slick-arrow-color-hover:       $gris-oscuro !default;
$slick-prev-character:          "\f053" !default; // fa-chevron-left // \f104 fa-angle-left
$slick-next-character:          "\f054" !default; // fa-chevron-right // \f105 fa-angle-right


.slick-dots {
  // display: block;
  position: absolute;
  bottom: 0;
  margin: 0;
  padding: 0;
  width: 100%;
  list-style: none;
  text-align: center;
  font-size: 0;

  li {
    display: inline-block;
    margin: 0;
    padding: 0;

    button {
      display: block;
      position: relative;
      height: 30px;
      width: 30px;
      padding: 5px;
      border: 0;
      outline: none;
      font-size: 0;
      line-height: 0;
      color: transparent;
      background: transparent;
      cursor: pointer;

      &:before {
        content: '';
        display: block;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        background-color: $gris-medio;
        transition: all 200ms ease-in-out;
      }

      &:hover {

        &:before {
          background-color: $gris-oscuro;
        }
      }

      &:focus {
        outline: none;
      }
    }

    // Active
    &.slick-active {

      button {

        &:before {
          background-color: $brand-primary;
        }
      }
    }
  }
} // .slick-dots




// // Arrows
// .slick-prev,
// .slick-next {
//   position: absolute;
//   display: block;
//   height: 100%;
//   width: 14px;
//   line-height: 0;
//   font-size: 0;
//   cursor: pointer;

//   top: 50%;
//   margin-top: -10px\9; /*lte IE 8*/
//   transform: translate(0, -50%);
//   padding: 0;
//   border: none;
//   outline: none;

//   z-index: 1;

//   box-sizing: content-box;
//   background-color: transparent;
//   background-repeat: no-repeat;
//   background-position: 50% 50%;
//   background-size: 14px auto;

//   @include opacity(0.5);
//   transition: opacity 200ms;

//   &:before {
//     content: '';
//     display: none;
//   }

//   &:hover,
//   &:focus {
//     outline: none;
//     @include opacity(1);
//   }
// } // .slick-prev, .slick-next




// ----------------------------------------------------------------------------
// Main variant
// ----------------------------------------------------------------------------

.section-slider-main {
  position: relative;
  height: calc(100vh - 30px);
  min-height: calc(450px - 30px);
  top: -100px;
  margin-bottom: -100px;

  @media screen and (min-width: 420px) {
    height: calc(100vh - 40px);
    min-height: calc(450px - 40px);
  }

  @media screen and (min-width: $screen-sm-min) {
    // padding-top: initial;
  }

  @media screen and (min-width: $screen-md-min) {
    height: 100vh;
    max-height: 640px;
    top: -120px;
    margin-bottom: -120px;
    // transform: translateY(-120px);
  }

  @media screen and (min-width: $screen-lg-min) {
    max-height: 768px;
  }

  // Slider video
  // Enseñar sólo si tiene clase .slider-video
  > .jarallax-video {
    display: none;
  }

  &.slider-video {
    background-color: transparent;

    // Dots overlay
    &:after {
      content: '';
      display: block;
      position: absolute;
      z-index: -1;
      top: -40px;
      left: 0px;
      width: 100%;
      height: calc(100% + 40px);

      // overlay
      background-attachment: fixed;
      background-image:url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAQAAAAECAYAAACp8Z5+AAAAG0lEQVQYV2NkYGD4z8DAwMgABXAGNgGwSgwVAFbmAgXQdISfAAAAAElFTkSuQmCC');
      // opacity: 1;

      @media screen and (min-width: $screen-md-min) {
        top: 0;
        height: 100%;
      }
    }

    > .jarallax-video-bg {
      display: block;
      position: absolute;
      z-index: -2;
      top: 0px;
      left: 0px;
      width: 100%;
      height: 100%;
      background-color: transparent;
    }

    > .jarallax-video {
      display: block;
      position: absolute;
      z-index: -1;
      top: 50%;
      left: 50%;
      min-width: 100%;
      min-height: 100%;
      width: auto;
      height: auto;
      // opacity: 0.25;
      transform: translateX(-50%) translateY(-50%);

      background-size: cover;

      iframe {

        .ie & {
          transform: none !important;
        }
      }
    } // [data-jarallax-video]

    .slider-main {

      // .slick-slide {
      //   height: calc(100vh - 40px);

      //   @media screen and (min-width: $screen-md-min) {
      //     height: 100vh;
      //   }
      // }

      .slick-slide-inner {
        background-image: none !important;
        background-color: transparent !important;
      }
    }
  } // .slider-video


  // Scroll next animation
  .scroll-notice {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: 0;
    right: 0;
    height: 60px;
    padding: 0 10px;

    @media screen and (max-height: 479px) {
      display: none;
    }

    @media screen and (min-width: $screen-sm-min) {
      right: unset;
      width: 100%;
      padding: 0;
    }

    .icon-hand-down {
      width: 30px;
      height: 40px;

      @media screen and (min-width: $screen-sm-min) {
        display: none;
      }
    }

    .icon-mouse-down {
      width: 20px;
      height: 40px;
    }

    + .tooltip {
      z-index: 1;
    }
  } // .scroll-notice


  // Custom dots container
  .dots-container {
    position: absolute;
    left: 0;
    bottom: 15px;
    width: 100%;
    pointer-events: none;

    @media screen and (max-height: 479px) {
      display: none;
    }

    @media screen and (min-width: $screen-sm-min) {
      bottom: 60px;
    }

    @media screen and (min-width: $screen-md-min) {}

    @media screen and (min-width: $screen-lg-min) {}

    @media screen and (min-width: $screen-xl-min) {}

    .slick-dots {
      position: relative;
      bottom: auto;
      text-align: center;

      @media screen and (min-width: $screen-sm-min) {
        text-align: right;
      }

      // Con border 2px
      li {
        pointer-events: all;

        button {
          padding: 4px;

          &:before {
            width: 22px;
            height: 22px;
            border: 2px solid #fff;
          }
        }
      }
    } // .slick-dots
  } // .dots-container

} // .section-slider-main


// Slider
.slider-main {
  clear: both;
  margin-bottom: 0;
  z-index: 0;

  &.slick-slider .slick-track,
  &.slick-slider .slick-list {
    transform: none;
  }

  // Slide
  .slick-slide {
    position: relative;
    overflow: hidden;
    font-size: 0;
    // z-index: inherit !important;

    &:focus {
      outline: none;
    }
  } // .slick-slide

  .slick-slide-inner {
    position: relative;
    width: 100%;
    height: calc(100vh - 40px);
    min-height: calc(450px - 40px);

    @media screen and (min-width: $screen-md-min) {
      height: 100vh;
      max-height: 640px;
    }

    @media screen and (min-width: $screen-lg-min) {
      max-height: 768px;
    }

    .slide-image {
      @include absolute-full();
      // z-index: -100;
      overflow: hidden;
      pointer-events: none;
      clip: rect(0 auto auto 0);
      clip: rect(0, auto, auto, 0);

      .__img-replace {
        position: fixed;
        z-index: -1;
        object-fit: cover;
        top: 0;
        left: 0;
        width: 100%;
        // height: auto;
        height: 100vh;
        min-height: 450px;

        .ie & {
          position: static;
        }

        // background-attachment: fixed;
        background-size: cover;
        background-position: 50% 50%;
        background-repeat: no-repeat;

        @media screen and (min-width: $screen-md-min) {
          height: 100vh;
          max-height: 640px;
        }

        @media screen and (min-width: $screen-lg-min) {
          max-height: 768px;
        }

        // Admin Bar fix
        .admin-bar & {
          top: 46px;

          @media screen and (min-width: 783px) {
            top: 32px;
          }
        }
      } // ._img-replace

      // img {
      //   position: fixed;
      //   object-fit: cover;
      //   object-position: 50% 50%;
      //   top: 0;
      //   left: 0;
      //   width: 100%;
      //   height: auto;
      //   max-width: none;
      //   overflow: hidden;
      //   pointer-events: none;
      // }
    }

    .slick-slide-link-overlay {
      @include absolute-full();
    }
  }


  // Módulos
  .slick-module {
    position: absolute;
    bottom: 60px;
    width: 100%;
    transform: translateX(-100%);
    transition: transform 600ms $bezier;
    pointer-events: none;

    @media screen and (max-height: 479px) {
      bottom: 15px;
    }

    .slick-module-content {
      position: relative;
      margin: 0 auto;
      padding: 30px;
      max-width: 480px;
      border-radius: $border-radius-base;
      background-color: rgba(#fff, 0.8);
      pointer-events: all;

      @media screen and (max-height: 568px) {
        padding: 15px;
      }

      @media screen and (min-width: $screen-sm-min) {
        margin: 0;
      }

      @media screen and (min-width: $screen-lg-min) {
        max-width: 576px;
      }

      > * {
        opacity: 0;
        transition: opacity 600ms $bezier;
      }

      .action {
        opacity: 0;
        transform: scale(0.8);
        transition: transform 600ms $bezier, opacity 600ms $bezier;
      }
    } // .slick-module-content


    // Tipo Standard
    &.slick-module-standard {

      h2 {
        margin: 0;
        @include responsive-heading($font-size-h2);
        font-weight: 800;
        line-height: 1;
        // color: #000;

        &.ellipsis {
          overflow: hidden;
          max-height: (($font-size-h2 * 0.8) * 1) * 4;
        }

        @media screen and (max-height: 568px) {
          font-size: 20px !important;

          &.ellipsis {
            max-height: ((20px * 1) * 1) * 4 !important;
          }
        }

        @media screen and (min-width: $screen-sm-min) {

          &.ellipsis {
            max-height: (($font-size-h2 * 1) * 1) * 4;
          }
        }

        @media screen and (min-width: $screen-md-min) {

          &.ellipsis {
            max-height: (($font-size-h2 * 1) * 1) * 4;
          }
        }

        @media screen and (min-width: $screen-lg-min) {

          &.ellipsis {
            max-height: (($font-size-h2 * 1.20) * 1) * 4;
          }
        }

        @media screen and (min-width: $screen-xl-min) {

          &.ellipsis {
            max-height: (($font-size-h2 * 1.20) * 1) * 4;
          }
        }
      }

      .lead-custom {
        margin: 0.5em 0 0 0;
        line-height: $line-height-base;
        font-size: 18px;
        font-weight: 600;
        // color: #000;

        &.ellipsis {
          overflow: hidden;
          max-height: ((18px * 1) * $line-height-base) * 4;
        }

        @media screen and (max-height: 568px) {
          font-size: 16px !important;

          &.ellipsis {
            max-height: ((16px * 1) * $line-height-base) * 4 !important;
          }
        }

        @media screen and (min-width: $screen-sm-min) {
          font-size: (18px * 1);

          &.ellipsis {
            max-height: ((18px * 1) * $line-height-base) * 4;
          }
        }

        @media screen and (min-width: $screen-md-min) {
          font-size: (18px * 1);

          &.ellipsis {
            max-height: ((18px * 1) * $line-height-base) * 4;
          }
        }

        @media screen and (min-width: $screen-lg-min) {
          font-size: (18px * 1.20);

          &.ellipsis {
            max-height: ((18px * 1.20) * $line-height-base) * 4;
          }
        }

        @media screen and (min-width: $screen-xl-min) {
          font-size: (18px * 1.20);

          &.ellipsis {
            max-height: ((18px * 1.20) * $line-height-base) * 4;
          }
        }
      }

      .action {
        margin-top: 30px;
        transform-origin: 0 50%;

        @media screen and (max-height: 568px) {
          margin-top: 15px;
        }
      }
    }


    // Tipo Donación
    &.slick-module-donacion {

      .slick-module-content {
        // position: relative;
        // padding: 30px;
        max-width: 320px;
        text-align: center;
        background-color: rgba(#fff, 0.85);

        @media screen and (max-height: 568px) {
          padding: 15px;
        }

        @media screen and (min-width: $screen-lg-min) {
          max-width: 320px;
        }
      } // .slick-module-content

      h2 {
        margin: 0;
        @include responsive-heading($font-size-h1);
        font-weight: 800;
        line-height: 1;
        color: $brand-success;

        &.ellipsis {
          overflow: hidden;
          max-height: (($font-size-h1 * 0.8) * 1) * 4;
        }

        @media screen and (max-height: 640px) {
          font-size: 24px !important;

          &.ellipsis {
            max-height: ((24px * 1) * 1) * 2 !important;
          }
        }

        @media screen and (max-height: 480px) {

          &.ellipsis {
            max-height: ((24px * 1) * 1) * 2;
          }
        }

        @media screen and (min-width: $screen-sm-min) {

          &.ellipsis {
            max-height: (($font-size-h1 * 1) * 1) * 4;
          }
        }

        @media screen and (min-width: $screen-md-min) {

          &.ellipsis {
            max-height: (($font-size-h1 * 1) * 1) * 4;
          }
        }

        @media screen and (min-width: $screen-lg-min) {

          &.ellipsis {
            max-height: (($font-size-h1 * 1.20) * 1) * 4;
          }
        }

        @media screen and (min-width: $screen-xl-min) {

          &.ellipsis {
            max-height: (($font-size-h1 * 1.20) * 1) * 4;
          }
        }
      }

      .lead-custom {
        margin: 0 0 30px 0;
        line-height: $line-height-base;
        font-size: 18px;
        font-weight: 900;
        // color: #000;

        &.ellipsis {
          overflow: hidden;
          max-height: ((18px * 1) * $line-height-base) * 4;
        }

        @media screen and (max-height: 640px) {
          font-size: 16px !important;
          margin-bottom: 15px;

          &.ellipsis {
            max-height: ((16px * 1) * $line-height-base) * 2 !important;
          }
        }

        @media screen and (max-height: 480px) {

          &.ellipsis {
            max-height: ((16px * 1) * $line-height-base) * 2;
          }
        }

        @media screen and (min-width: $screen-sm-min) {
          font-size: (18px * 1);

          &.ellipsis {
            max-height: ((18px * 1) * $line-height-base) * 4;
          }
        }

        @media screen and (min-width: $screen-md-min) {
          font-size: (18px * 1);

          &.ellipsis {
            max-height: ((18px * 1) * $line-height-base) * 4;
          }
        }

        @media screen and (min-width: $screen-lg-min) {
          font-size: (18px * 1.20);

          &.ellipsis {
            max-height: ((18px * 1.20) * $line-height-base) * 4;
          }
        }

        @media screen and (min-width: $screen-xl-min) {
          font-size: (18px * 1.20);

          &.ellipsis {
            max-height: ((18px * 1.20) * $line-height-base) * 4;
          }
        }
      }

      .action {
        margin-top: 30px;

        @media screen and (max-height: 640px) {
          margin-top: 15px;
        }

        .form-group {
          margin-bottom: 10px;

          .input-group-xl {

            > * {
              height: 60px;
              padding: 10px 20px;
              font-size: 30px;
              line-height: 1;
              font-weight: 800;
              color: #000;
            }

            .form-control {
              padding-right: 0;
              border-right: 0;
              text-align: right;

              &:hover,
              &:focus {
                background-color: #fff;
              }

              &:focus {

                + .input-group-addon {
                  border-color: $input-border-focus;
                }
              }
            }

            .input-group-addon {
              width: 47%;
              padding-left: 0.125em;
              line-height: 0.9;
              text-align: left;
              background-color: #fff;
              transition: all 200ms $bezier;

              &:hover,
              &:focus {
                cursor: text;
              }
            }
          } // .input-group-xl
        } // .form-group

        .btn {

          @media screen and (max-height: 640px) {
            // .btn-lg
            border-width: $border-width-large;

            @include button-size(
              $padding-large-vertical - $border-width-large - 2,
              $padding-large-horizontal - $border-width-large,
              $font-size-large,
              $font-size-large + 4,
              $btn-border-radius-large
            );
          }
        }

        .logos {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding-top: 30px;

          @media screen and (max-height: 640px) {
            justify-content: space-around;
            padding-top: 15px;
          }

          .logo {
            width: auto;
            height: 30px;

            @media screen and (max-height: 640px) {
              height: 20px;
            }

            &.logo-paypal {
              height: 24px;
              margin-right: 5px;

              @media screen and (max-height: 640px) {
                height: 16px;
              }
            }
          }
        } // .logos

      }
    }
  } // .slick-module


  // Active Slide
  .slick-active {

    .slick-module {
      transform: translateX(0);

      @media screen and (min-width: $screen-sm-min) {
        transform: translateX(0);
      }

      .slick-module-content {

        > * {
          opacity: 1;
          transition-delay: 450ms;
        }

        .action {
          opacity: 1;
          transform: scale(1);
          transition-delay: 450ms;
        }
      }
    }
  } // .slick-active


  // Arrows
  .slick-arrow {}

  .slick-prev {
    background-image: url('/app/themes/codespa/dist/images/slider-prev.svg');
    top: 50%;
    left: 0;
    width: 40px;
    background-position: 100% 50%;
    // transform: translate(-100%, 0);
  } // .slick-prev

  .slick-next {
    background-image: url('/app/themes/codespa/dist/images/slider-next.svg');
    top: 50%;
    right: 0;
    width: 40px;
    background-position: 0% 50%;
    // transform: translate(100%, 0);
  } // .slick-next

} // .slider-main




// ----------------------------------------------------------------------------
// Default variant
// ----------------------------------------------------------------------------

// .slider-default {

//   .slick-slide {

//     img {
//       width: 100%;
//     }
//   }

//   .slick-dots {
//     right: 15px;
//     bottom: 15px;
//     width: auto;

//     li {
//       height: 30px;
//       width: 30px;

//       button {
//         height: 30px;
//         width: 30px;
//         padding: 5px;

//         &:before {
//           width: 30px;
//           height: 30px;
//           font-size: 25px;
//           line-height: 30px;
//         }
//       }
//     }
//   }
// } // .slider-default








// ----------------------------------------------------------------------------
// Modules variant
// ----------------------------------------------------------------------------
.slider-modules {
  position: relative;

  .slider {
    padding-bottom: 60px;

    @media screen and (min-width: $screen-md-min) {
      padding-bottom: 90px;
    }

    // .slider-item {
    //   position: absolute;
    //   visibility: hidden;
    // }

    // .slick-initialized {

    //   .slider-item {
    //     position: relative;
    //     visibility: visible;
    //   }
    // }

    > .slick-list {

      > .slick-track {

        > .slick-slide {
          // position: relative;

          &:focus {
            outline: none;
          }
        } // .slick-slide
      }
    } // > .slick-list

    .module {
      margin-bottom: 0;
    }
  } // .slider


  // // Dots
  // + .dots-container {
  //   position: relative;
  //   padding: 15px 0;
  //   margin: -30px 0 0 0;

  //   .slick-dots {
  //     position: relative;
  //     width: auto;
  //     margin: 0 -5px;
  //     text-align: right;

  //     // li {

  //     //   &:not(.slick-active) {

  //     //     button {

  //     //       &:before {
  //     //         color: $gris-claro;
  //     //       }

  //     //       &:hover,
  //     //       &:focus {

  //     //         &:before {
  //     //           color: $gris-oscuro;
  //     //         }
  //     //       }
  //     //     }
  //     //   }
  //     // }
  //   }
  // } // .dots-container


  // Arrows
  .arrows-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 0;
    padding-bottom: (100% / 16) * 9; // 16:9
    pointer-events: none;

    @media screen and (min-width: $screen-sm-min) {
      padding-bottom: 194px;
    }

    @media screen and (min-width: $screen-md-min) {
      padding-bottom: 165px;
    }

    @media screen and (min-width: $screen-lg-min) {
      padding-bottom: 202px;
    }

    @media screen and (min-width: $screen-xl-min) {
      padding-bottom: 247px;
    }
  }

  .slider-modules-arrow {
    position: absolute;
    z-index: 1;
    bottom: 50%;
    width: 50px;
    height: 60px;
    margin: 0;
    padding: 0;
    border: none;
    outline: none;
    font-size: $font-size-large;
    line-height: 0;
    text-align: center;
    background-color: rgba(#fff, 0.5);
    transform: translateY(50%);
    transition: background-color 200ms ease-in-out;
    pointer-events: all;

    &.slick-disabled {
      visibility: hidden;
    }

    &:hover,
    &:focus {
      outline: none;
      background-color: #fff;
    }
  } // .slider-modules-arrow

  .slider-modules-prev {
    left: -15px;
  } // .slider-modules-prev

  .slider-modules-next {
    right: -15px;
  } // .slider-modules-next
} // .slider-modules






// ----------------------------------------------------------------------------
// Modulos Imagen variant
// ----------------------------------------------------------------------------
.slider-modulos-imagen {
  position: relative;

  .slider {
    padding-bottom: 90px;

    @media screen and (min-width: $screen-sm-min) {
      padding-bottom: 60px;
    }

    @media screen and (min-width: $screen-md-min) {
      padding-bottom: 0;
    }

    > .slick-list {

      @media screen and (min-width: $screen-md-min) {
        overflow: visible;
        margin: 0 -10px;
      }

      > .slick-track {
        display: flex;
        align-items: stretch;
        justify-content: center;

        > .slick-slide {
          float: none;
          height: auto;

          @media screen and (min-width: $screen-md-min) {
            padding: 0 10px;
          }

          &:focus {
            outline: none;
          }

          .panel {

            @media screen and (min-width: $screen-md-min) {
              opacity: 0.3;
              cursor: pointer;

              &:hover,
              &:focus {
                opacity: 0.7;
              } // hover
            }
          }

          &.slick-current {}

          &.slick-active {}

          &.slick-center {

            .panel {
              z-index: 1;
              opacity: 1;
              cursor: initial;
              border-bottom-left-radius: 0;
              border-bottom-right-radius: 0;

              @media screen and (min-width: $screen-md-min) {
                transform: scale(1.142857);
              }

              .__img-replace {
                opacity: 0.75;
                transform: scale(1);
              }

              .panel-body {

                .btn {
                  pointer-events: all;
                }
              }

              .panel-link {
                border-color: $brand-primary;
                pointer-events: all;
              }
            } // .panel
          }

          &.slick-uncenter {

            .panel {
              opacity: 1;
              border-bottom-left-radius: 0;
              border-bottom-right-radius: 0;

              .__img-replace {
                opacity: 0.75;
                transform: scale(1);
              }

              .panel-body {

                .btn {
                  pointer-events: all;
                }
              }

              .panel-link {
                border-color: $brand-primary;
                pointer-events: all;
              }

              @media screen and (min-width: $screen-md-min) {

                &:hover,
                &:focus {

                  .__img-replace {
                    opacity: 0.5;
                    transform: scale(1.142857);
                  }
                } // hover
              }
            } // .panel
          }
        } // .slick-slide
      }
    } // > .slick-list

    > .slick-dots {

      @media screen and (max-width: $screen-xs-max) {
        bottom: 30px;
      }
    }
  } // .slider


  // Arrows
  .arrows-container {
    position: absolute;
    top: 0;
    bottom: 90px;
    left: 0;
    width: 100%;
    pointer-events: none;

    @media screen and (min-width: $screen-sm-min) {
      bottom: 60px;
    }

    @media screen and (min-width: $screen-md-min) {
      bottom: 0;
    }
  }

  .slider-modulos-imagen-arrow {
    position: absolute;
    bottom: 10px;
    width: 65px;
    height: 50px;
    margin: 0;
    padding: 0;
    border: none;
    outline: none;
    font-size: $font-size-large;
    line-height: 0;
    text-align: center;
    background-color: rgba(#fff, 0.5);
    transition: background-color 200ms ease-in-out;
    pointer-events: all;

    @media screen and (min-width: $screen-md-min) {
      bottom: 50%;
      height: 60px;
      transform: translateY(50%);
    }

    &.slick-disabled {
      visibility: hidden;
    }

    &:hover,
    &:focus {
      outline: none;
      background-color: #fff;
    }
  } // .slider-modulos-imagen-arrow

  .slider-modulos-imagen-prev {
    left: -15px;
    padding-left: 15px;
  } // .slider-modulos-imagen-prev

  .slider-modulos-imagen-next {
    right: -15px;
    padding-right: 15px;
  } // .slider-modulos-imagen-next

} // .slider-modules








// ----------------------------------------------------------------------------
// Page  variant
// ----------------------------------------------------------------------------
.section-slider-page {
  margin-bottom: 30px;
}

.slider-page {
  position: relative;
  clear: both;
  margin: 0 -15px;

  @media screen and (min-width: $screen-sm-min) {
    margin: 0;
  }

  .slick-slide {

    &:focus {
      outline: none;
    }
  } // .slick-slide

  .slider-page-slide {
    position: relative;
    overflow: hidden;

    .__img-replace {
      height: 0;
      padding-bottom: (100% / 16) * 9; // 16:9 // padding-bottom: (100% / 4) * 3; // 4:3
      background-repeat: no-repeat;
      background-position: 50% 50%;
      background-size: cover;
      background-color: transparent;

      // @media screen and (min-width: $screen-sm-min) {
      //   padding-bottom: (100% / 16) * 9; // 16:9
      // }
    }

    .slider-page-slide-content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      position: absolute;
      left: 10px;
      right: 10px;
      bottom: 10px;
      padding: 5px 10px;
      background-color: #fff;

      @media screen and (min-width: $screen-sm-min) {
        left: auto;
        right: 0;
        bottom: 40px;
        height: 60px;
        padding: 10px 100px 10px 20px;
        max-width: 75%;
      }

      > * {
        margin: 0;
      }

      h5 {

        @media screen and (max-width: $screen-xs-max) {
          font-size: $font-size-medium;
        }
      }

      p {

        @media screen and (max-width: $screen-xs-max) {
          font-size: $font-size-small;
        }
      }
    } // .slider-page-slide-content

    .slider-page-slide-link {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fff;
      text-decoration: none;

      > i {
        opacity: 0;
        transition: all 200ms ease-in-out;
      }

      &:hover,
      &:focus {

        > i {
          opacity: 0.5;
        }
      }
    } // .slider-page-slide-link
  } // .slider-page-slide


  // Dots
  + .dots-container {
    position: relative;
    margin: 0;

    .slick-dots {
      position: relative;
      width: auto;
      margin: 0 -5px;
      padding-top: 15px;
      text-align: right;
    }
  } // .dots-container


  // Arrows
  .slider-page-arrow {
    position: absolute;
    z-index: 1;
    bottom: 50%;
    width: 50px;
    height: 60px;
    margin: 0;
    padding: 0;
    border: none;
    outline: none;
    font-size: $font-size-large;
    line-height: 0;
    text-align: center;
    background-color: rgba(#fff, 0.5);
    transform: translateY(50%);
    transition: background-color 200ms ease-in-out;

    @media screen and (min-width: $screen-sm-min) {
      bottom: 40px;
      background-color: #fff;
      transform: none;
    }

    i {
      // opacity: 1;
      transition: opacity 200ms ease-in-out;

      @media screen and (min-width: $screen-sm-min) {
        opacity: 0.5;
      }
    }

    &.slick-disabled {
      visibility: hidden;
    }

    &:hover,
    &:focus {
      outline: none;
      background-color: #fff;

      i {
        opacity: 1;
      }
    }
  } // .slider-page-arrow

  .slider-page-prev {
    left: -15px;
    // background-image: url('/app/themes/codespa/dist/images/slider-prev-chevron-shadow.svg');
    // background-position: 10px 50%;

    @media screen and (min-width: $screen-sm-min) {
      left: auto;
      right: 50px;
    }
  } // .slider-page-prev

  .slider-page-next {
    right: -15px;
    // background-image: url('/app/themes/codespa/dist/images/slider-next-chevron-shadow.svg');
    // background-position: calc(100% - 10px) 50%;

    @media screen and (min-width: $screen-sm-min) {
      right: 0;
    }
  } // .slider-page-next

} // .slider-page
