//
// Typography
// --------------------------------------------------


// Type mixins
// -------------------------

// Responsive Headings
@mixin responsive-heading($font-size: $font-size-h2) {
  font-size: ceil($font-size * 0.8);

  @media screen and (min-width: $screen-sm-min) {
    font-size: ceil($font-size * 1);
  }

  @media screen and (min-width: $screen-md-min) {
    font-size: ceil($font-size * 1);
  }

  @media screen and (min-width: $screen-lg-min) {
    font-size: ceil($font-size * 1.20);
  }

  @media screen and (min-width: $screen-xl-min) {
    font-size: ceil($font-size * 1.20);
  }
}

// Responsive Margin Top
@mixin responsive-mt($font-size: $font-size-h2) {
  margin-top: ceil((($font-size * 0.8) * $headings-line-height) * 1);

  @media screen and (min-width: $screen-sm-min) {
    margin-top: ceil((($font-size * 1) * $headings-line-height) * 1);
  }

  @media screen and (min-width: $screen-md-min) {
    margin-top: ceil((($font-size * 1) * $headings-line-height) * 1);
  }

  @media screen and (min-width: $screen-lg-min) {
    margin-top: ceil((($font-size * 1.20) * $headings-line-height) * 1);
  }

  @media screen and (min-width: $screen-xl-min) {
    margin-top: ceil((($font-size * 1.20) * $headings-line-height) * 1);
  }
}

// Responsive Margin Bottom
@mixin responsive-mb($font-size: $font-size-h2) {
  margin-bottom: ceil((($font-size * 0.8) * $headings-line-height) * 1);

  @media screen and (min-width: $screen-sm-min) {
    margin-bottom: ceil((($font-size * 1) * $headings-line-height) * 1);
  }

  @media screen and (min-width: $screen-md-min) {
    margin-bottom: ceil((($font-size * 1) * $headings-line-height) * 1);
  }

  @media screen and (min-width: $screen-lg-min) {
    margin-bottom: ceil((($font-size * 1.20) * $headings-line-height) * 1);
  }

  @media screen and (min-width: $screen-xl-min) {
    margin-bottom: ceil((($font-size * 1.20) * $headings-line-height) * 1);
  }
}

// Responsive Padding Top
@mixin responsive-pt($font-size: $font-size-h2) {
  padding-top: ceil((($font-size * 0.8) * $headings-line-height) * 1);

  @media screen and (min-width: $screen-sm-min) {
    padding-top: ceil((($font-size * 1) * $headings-line-height) * 1);
  }

  @media screen and (min-width: $screen-md-min) {
    padding-top: ceil((($font-size * 1) * $headings-line-height) * 1);
  }

  @media screen and (min-width: $screen-lg-min) {
    padding-top: ceil((($font-size * 1.20) * $headings-line-height) * 1);
  }

  @media screen and (min-width: $screen-xl-min) {
    padding-top: ceil((($font-size * 1.20) * $headings-line-height) * 1);
  }
}

// Responsive Padding Bottom
@mixin responsive-pb($font-size: $font-size-h2) {
  padding-bottom: ceil((($font-size * 0.8) * $headings-line-height) * 1);

  @media screen and (min-width: $screen-sm-min) {
    padding-bottom: ceil((($font-size * 1) * $headings-line-height) * 1);
  }

  @media screen and (min-width: $screen-md-min) {
    padding-bottom: ceil((($font-size * 1) * $headings-line-height) * 1);
  }

  @media screen and (min-width: $screen-lg-min) {
    padding-bottom: ceil((($font-size * 1.20) * $headings-line-height) * 1);
  }

  @media screen and (min-width: $screen-xl-min) {
    padding-bottom: ceil((($font-size * 1.20) * $headings-line-height) * 1);
  }
}


// Responsive padding
@mixin responsive-padding($font-size: $font-size-h2) {
  padding: ceil((($font-size * 0.8) * $headings-line-height) * 1) 0;

  @media screen and (min-width: $screen-sm-min) {
    padding: ceil((($font-size * 1) * $headings-line-height) * 1) 0;
  }

  @media screen and (min-width: $screen-md-min) {
    padding: ceil((($font-size * 1) * $headings-line-height) * 1) 0;
  }

  @media screen and (min-width: $screen-lg-min) {
    padding: ceil((($font-size * 1.20) * $headings-line-height) * 1) 0;
  }

  @media screen and (min-width: $screen-xl-min) {
    padding: ceil((($font-size * 1.20) * $headings-line-height) * 1) 0;
  }
}


// Line height
// -------------------------

.line-height-1 {
  line-height: $line-height-1; // 1
}

.line-height-xs {
  line-height: $line-height-xs; // 1.125
}

.line-height-sm {
  line-height: $line-height-sm; // 1.25
}

.line-height-md {
  line-height: $line-height-md; // 1.5
}

.line-height-lg {
  line-height: $line-height-lg; // 1.75
}

.line-height-2 {
  line-height: $line-height-2; // 2
}

.line-height-base {
  line-height: $line-height-base; // 1.5
}

.line-height-computed {
  line-height: $line-height-computed; // 24px
}

.line-height-computed-large {
  line-height: $line-height-computed-large; // 30px
}

.line-height-computed-small {
  line-height: $line-height-computed-small; // 20px
}


// Kerning
// -------------------------

.kerning-3 {
  letter-spacing: -0.0375em;
}

.kerning-2 {
  letter-spacing: -0.025em;
}

.kerning-1 {
  letter-spacing: -0.0125em;
}

.kerning-0 {
  letter-spacing: normal;
}

.kerning-a {
  letter-spacing: 0.0125em;
}

.kerning-b {
  letter-spacing: 0.025em;
}

.kerning-c {
  letter-spacing: 0.0375em;
}

.kerning-d {
  letter-spacing: 0.05em;
}


// Headings
// -------------------------

h1, .h1 { margin: ((1em * $headings-line-height) / 2 ) 0; }
h2, .h2 { margin: ((1em * $headings-line-height) / 2 ) 0; }
h3, .h3 { margin: ((1em * $headings-line-height) / 2 ) 0; }
h4, .h4 { margin: ((1em * $headings-line-height) / 2 ) 0; }
h5, .h5 { margin: ((1em * $headings-line-height) / 2 ) 0; }
h6, .h6 { margin: ((1em * $headings-line-height) / 2 ) 0; }

// h1, .h1 { margin: ($font-size-h1 * $headings-line-height) 0; }
// h2, .h2 { margin: ($font-size-h2 * $headings-line-height) 0; }
// h3, .h3 { margin: ($font-size-h3 * $headings-line-height) 0; }
// h4, .h4 { margin: ($font-size-h4 * $headings-line-height) 0; }
// h5, .h5 { margin: ($font-size-h5 * $headings-line-height) 0; }
// h6, .h6 { margin: ($font-size-h6 * $headings-line-height) 0; }

// .heading-alt {
//   font-family: $font-family-sans-alt;
//   font-weight: 800;
// }

// Body text
// -------------------------

p {
  margin: 0 0 (1em * $line-height-base);
}


// Lead
.lead {
  margin-bottom: (1em * $line-height-base);
  font-size: 18px;
  line-height: 1.5;
  font-weight: 400;

  @media screen and (min-width: $screen-sm-min) {
    font-size: ($font-size-large * 1);
  }

  @media screen and (min-width: $screen-md-min) {
    font-size: ($font-size-large * 1);
  }

  @media screen and (min-width: $screen-lg-min) {
    font-size: ($font-size-large * 1.20);
  }

  @media screen and (min-width: $screen-xl-min) {
    font-size: ($font-size-large * 1.20);
  }
}

.lead-alt {
  margin-bottom: (1em * $line-height-base);
  font-size: 18px;
  line-height: $line-height-base;
  font-weight: 600;

  @media screen and (min-width: $screen-sm-min) {
    font-size: ($font-size-large * 1);
  }

  @media screen and (min-width: $screen-md-min) {
    font-size: ($font-size-large * 1);
  }

  @media screen and (min-width: $screen-lg-min) {
    font-size: ($font-size-large * 1.20);
  }

  @media screen and (min-width: $screen-xl-min) {
    font-size: ($font-size-large * 1.20);
  }
}

.lead-lg {
  margin-bottom: (1em * $line-height-base);
  font-size: ($font-size-xlarge * 1);
  line-height: $line-height-base;
  font-weight: 400;

  @media screen and (min-width: $screen-sm-min) {
    font-size: ($font-size-xlarge * 1);
  }

  @media screen and (min-width: $screen-md-min) {
    font-size: ($font-size-xlarge * 1);
  }

  @media screen and (min-width: $screen-lg-min) {
    font-size: ($font-size-xlarge * 1.20);
  }

  @media screen and (min-width: $screen-xl-min) {
    font-size: ($font-size-xlarge * 1.20);
  }
}

// sup with icon
sup.sup-icon {
  font-size: 50%;
  top: -1em;
}


// Lists
// -------------------------

// Unordered and Ordered lists
ul,
ol {
  margin-top: (1em * $line-height-base);
  margin-bottom: (1em * $line-height-base);

  ul,
  ol {
    margin-top: (1em * $line-height-base);
    margin-bottom: (1em * $line-height-base);
  }
}





// Type Helpers
// -------------------------------------------------------

.text-xsmall {
  font-size: $font-size-xsmall !important; // 11px
}

.text-small {
  font-size: $font-size-small !important; // 13px
}

.text-medium {
  font-size: $font-size-medium !important; // 14px
}

.text-base {
  font-size: $font-size-base !important; // 16px
}

.text-large {
  font-size: $font-size-large !important; // 20px
}

.text-xlarge {
  font-size: $font-size-xlarge !important; // 24px
}





// Text-transforms
.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.text-initial {
  text-transform: initial !important;
}

.text-none {
  text-transform: none !important;
}






// Overflow
.text-overflow {
  @include text-overflow;
}

// Font weight
.text {

  &-100 {
    font-weight: 100;
  }

  &-200 {
    font-weight: 200;
  }

  &-300 {
    font-weight: 300;
  }

  &-400 {
    font-weight: 400;
  }

  &-500 {
    font-weight: 500;
  }

  &-600 {
    font-weight: 600;
  }

  &-700 {
    font-weight: 700;
  }

  &-800 {
    font-weight: 800;
  }

  &-900 {
    font-weight: 900;
  }
}




// Text underlines
.text-underline {
  text-decoration: underline !important;
}

.text-no-underline {
  text-decoration: none !important;

  a#{&} {

    &:hover,
    &:focus {
      text-decoration: none !important;
    }
  }
}

.border-underline {
  text-decoration: none;
  border-bottom: 1px solid;
}





// Sin resaltado de foco
.no-focus {
  outline: none;

  &:focus {
    outline: none;
  }
}





// // Contextual colors

// // Remove hover from text-emphasis-variant
// @mixin text-emphasis-variant-flat($parent, $color) {
//   #{$parent} {
//     color: $color;
//   }
//   a#{$parent}:hover,
//   a#{$parent}:focus {
//     color: $color;
//   }
// }

// @include text-emphasis-variant('.text-secondary', $brand-secondary);
// @include text-emphasis-variant-flat('.text-muted', $text-muted);
// @include text-emphasis-variant-flat('.text-color', $text-color);
// @include text-emphasis-variant-flat('.text-primary', $brand-primary);
// @include text-emphasis-variant-flat('.text-success', $state-success-text);
// @include text-emphasis-variant-flat('.text-info', $state-info-text);
// @include text-emphasis-variant-flat('.text-warning', $state-warning-text);
// @include text-emphasis-variant-flat('.text-danger', $state-danger-text)

// @include bg-variant('.bg-secondary', $brand-secondary);






// BG
.bg-primary {
  background-color: $brand-primary !important;
}

.bg-success {
  background-color: $brand-success !important;
}

.bg-info {
  background-color: $brand-info !important;
}

.bg-warning {
  background-color: $brand-warning !important;
}

.bg-danger {
  background-color: $brand-danger !important;
}

.bg-white {
  background-color: #fff !important;
}

.bg-gris-blanco {
  background-color: $gris-blanco !important;
}

.bg-gris-claro {
  background-color: $gris-claro !important;
}

.bg-gris {
  background-color: $gris !important;
}

.bg-gris-medio {
  background-color: $gris-medio !important;
}

.bg-gris-oscuro {
  background-color: $gris-oscuro !important;
}

.bg-gris-negro {
  background-color: $gris-negro !important;
}

.bg-white {
  background-color: #fff !important;
}

.bg-transparent {
  background-color: transparent !important;
}


// Color
.color-primary {
  color: $brand-primary !important;
}

.color-success {
  color: $brand-success !important;
}

.color-info {
  color: $brand-info !important;
}

.color-warning {
  color: $brand-warning !important;
}

.color-danger {
  color: $brand-danger !important;
}

.color-white {
  color: #fff !important;
}

.color-gris-blanco {
  color: $gris-blanco !important;
}

.color-gris-claro {
  color: $gris-claro !important;
}

.color-gris {
  color: $gris !important;
}

.color-gris-medio {
  color: $gris-medio !important;
}

.color-gris-oscuro {
  color: $gris-oscuro !important;
}

.color-gris-negro {
  color: $gris-negro !important;
}

.color-black {
  color: #000 !important;
}

.color-transparent {
  color: transparent !important;
}

.color-link {
  color: $link-color !important;
}

.color-link-hover {
  color: $link-hover-color !important;
}

.color-text {
  color: $text-color !important;
}


// SVG path fill
.fill-primary {
  fill: $brand-primary !important;
}

.fill-success {
  fill: $brand-success !important;
}

.fill-info {
  fill: $brand-info !important;
}

.fill-warning {
  fill: $brand-warning !important;
}

.fill-danger {
  fill: $brand-danger !important;
}

.fill-white {
  fill: #fff !important;
}

.fill-gris-blanco {
  fill: $gris-blanco !important;
}

.fill-gris-claro {
  fill: $gris-claro !important;
}

.fill-gris {
  fill: $gris !important;
}

.fill-gris-medio {
  fill: $gris-medio !important;
}

.fill-gris-oscuro {
  fill: $gris-oscuro !important;
}

.fill-gris-negro {
  fill: $gris-negro !important;
}

.fill-black {
  fill: #000 !important;
}

.fill-transparent {
  fill: transparent !important;
}








// Page header
// -------------------------

.page-header {
  padding-bottom: 0; // (($line-height-computed / 2) - 1);
  margin: 0 0 30px; // ($line-height-computed * 2) 0 $line-height-computed;
  // border-bottom: 1px solid $page-header-border-color;

  .page-title {
    margin: 0 0 .25em; // revisar
  }
}





// Listas
// -------------------------

// Listas con bullets y chevrons
@mixin list-custom() {

  &,
  ul {
    @include list-unstyled();
    margin: (1em * $line-height-base) 0 (1em * $line-height-base) (2em * $line-height-base);

    > li {
      margin-bottom: 0.75em;
      padding-left: 1em;

      &:before {
        @extend .fa;
        content: '\f111';
        float: left;
        margin-left: -2em;
        font-size: 50%;
        line-height: 2.5;

        color: $brand-primary;
      }

      ul {
        // @include list-unstyled();
        margin: (.5em * $line-height-base) 0;

        li {
          margin-bottom: 0.75em;
          padding-left: 1em;

          &:before {
            @extend .fa;
            content: '\f054';
            float: left;
            margin-left: -1.3334em;
            font-size: 66.6667%;
            line-height: 2;
          }
        }
      }
    }
  }
}
.list-custom {
  @include list-custom();
}





@mixin list-custom-alt() {

  &,
  ul {
    @include list-unstyled();
    margin: (1em * $line-height-base) 0 (1em * $line-height-base) 1em;

    > li {
      margin-bottom: 0.75em;
      padding-left: 1em;

      &:before {
        @extend .fa;
        content: '\f111';
        float: left;
        margin-left: -2em;
        font-size: 75%;
        line-height: 1.85;

        color: $brand-success;
      }

      ul {
        // @include list-unstyled();
        margin: (.5em * $line-height-base) 0;

        li {
          margin-bottom: 0.75em;
          padding-left: 1em;

          &:before {
            @extend .fa;
            content: '\f054';
            float: left;
            margin-left: -1.3334em;
            font-size: 66.6667%;
            line-height: 2;
          }
        }
      }
    }
  }
}
.list-custom-alt {
  @include list-custom-alt();
}





// Listas numeradas
@mixin list-numbered() {

  &,
  ol {
    @include list-unstyled();
    margin: (1em * $line-height-base) 0 (1em * $line-height-base) (2em * $line-height-base);
    list-style-type: decimal;

    li {
      padding-left: 1.5em;

      ol {
        @include list-unstyled();
        margin: (.5em * $line-height-base) 0;
        list-style-type: decimal;
      }
    }
  }
}
.list-numbered {
  @include list-numbered();
}





// Lista espaciada
@mixin list-spaced() {

  li {
    margin-bottom: $line-height-computed;
  }
}
.list-spaced {
  @include list-spaced();
}





// Blockquotes
blockquote {
  @include responsive-mt($font-size-h2);
  @include responsive-mb($font-size-h2);
  margin-right: 0;
  margin-left: 0;
  padding: 0 0 0 15px;
  border-left: 5px solid $blockquote-border-color;
  font-size: $font-size-base;

  @media screen and (min-width: $screen-sm-min) {
    padding: 0 0 0 30px;
    border-left: 10px solid $blockquote-border-color;
    font-size: $blockquote-font-size;
  }

  .quote {
    font-size: 1.67em;
    line-height: 0.5em;
    vertical-align: -50%;
    font-weight: 300;
    // color: $gris;
  }
}

.blockquote-reverse,
blockquote.pull-right {
  padding: 0 15px 0 0;
  border-right: 5px solid $blockquote-border-color;
  border-left: 0;
  text-align: right;

  @media screen and (min-width: $screen-sm-min) {
    padding: 0 30px 0 0;
    border-right: 10px solid $blockquote-border-color;
  }
}


.blockquote-simple {
  padding-left: 0;
  padding-right: 0;
  border-left: none;
  border-right: none;
}





// Figcaption
figcaption {

  p {
    font-family: $font-family-monospace;
    font-size: $font-size-xsmall;
    margin: 1em 0;
    color: $text-muted;
  }
}





// Horizontal rule
hr {

  &.divider {
    position: relative;
    overflow: visible;
    margin: 10px 0;
    // border-color: $hr-border;
    text-align: left;

    // Con 2 colores
    &.divider-accent {

      &:after {
        content: '';
        display: block;
        position: absolute;
        top: -1px;
        left: 0;
        border-top: 1px solid $brand-primary;
        width: 25%;
      }
    }

    // Anchos Fijos
    // ------------------

    // XS
    &.divider-xs {
      width: 30px;

      &.divider-accent {

        &:after {
          display: none;
        }
      }
    }

    // SM
    &.divider-sm {
      width: 50px;

      &.divider-accent {

        &:after {
          display: none;
        }
      }
    }

    // Anchos Porcentajes
    // ------------------

    // 25%
    &.divider-25 {
      width: 25%;
    }

    // 33.333%
    &.divider-33 {
      width: (100% / 3);
    }

    // 50%
    &.divider-50 {
      width: 50%;
    }

    // 66.666%
    &.divider-66 {
      width: ( (100% / 3) * 2 );
    }

    // 75%
    &.divider-75 {
      width: 75%;
    }

    // Espesores
    // ------------------

    // 2px
    &.divider-2 {
      border-top-width: 2px;

      &.divider-accent {

        &:after {
          top: -2px;
          border-top-width: 2px;
        }
      }
    }

    // 3px
    &.divider-3 {
      border-top-width: 3px;

      &.divider-accent {

        &:after {
          top: -3px;
          border-top-width: 3px;
        }
      }
    }

    // 4px
    &.divider-4 {
      border-top-width: 4px;

      &.divider-accent {

        &:after {
          top: -4px;
          border-top-width: 4px;
        }
      }
    }

    // Variantes
    // ------------------

    // Primary
    &.divider-primary {
      border-color: $brand-primary;

      &.divider-accent {

        &:after {
          border-top-color: $text-color;
        }
      }
    }

    // Light
    &.divider-light {
      border-color: $gray-light;
    }

    // Lighter
    &.divider-lighter {
      border-color: $gray-lighter;
    }
  }
}




// Links
a.text-color-link {
  text-decoration: underline;
  color: $text-color;
  transition: color 200ms ease-in-out;

  &:hover,
  &:focus {
    color: $link-color;
  }
}




// Textos en áreas principales
.entry-content,
.page-content {

  @media screen and (min-width: $screen-lg-min) {
    font-size: $font-size-large;
  }

  // Doble de margen top en headings
  .h0 { margin-top: (1em * $headings-line-height); }
  h1, .h1 { margin-top: (1em * $headings-line-height); }
  h2, .h2 { margin-top: (1em * $headings-line-height); }
  h3, .h3 { margin-top: (1em * $headings-line-height); }
  h4, .h4 { margin-top: (1em * $headings-line-height); }
  h5, .h5 { margin-top: (1em * $headings-line-height); }
  h6, .h6 { margin-top: (1em * $headings-line-height); }


  h1, h2, h3, h4,
  .h0, .h1, .h2, .h3, .h4 {
    // color: $brand-primary;
  }

  .h0 {
    @include responsive-heading($font-size-h0);
    // font-weight: 700;
  }

  h1,
  .h1 {
    @include responsive-heading($font-size-h1);
    // font-weight: 700;
  }

  h2,
  .h2 {
    @include responsive-heading($font-size-h2);
    // font-weight: 700;
  }

  h3,
  .h3 {
    @include responsive-heading($font-size-h3);
  }

  // p {
  //   margin-bottom: 1.25em;
  // }

  // Listas
  ul {
    @include list-custom();
  }

  ol {
    margin: (1em * $line-height-base) 0 (1em * $line-height-base) 0;

    @media screen and (min-width: $screen-sm-min) {
      margin: (1em * $line-height-base) 0 (1em * $line-height-base) (2em * $line-height-base);
    }

    ol {
      margin: (.5em * $line-height-base) 0;
    }
  }

  b,
  strong {
    font-weight: 800;
  }

  blockquote {

    p {
      color: $gris-medio;
    }
  }

  img {
    @extend .img-responsive;
  }

  // Captions
  .wp-caption {
    // @extend .thumbnail;
  }

  .wp-caption-text {
    font-size: $font-size-small;
  }
} // .entry-content, .page-content


// Video
.entry-content-asset {
  margin-bottom: 30px;
}


// Responsive headings
.responsive-heading-h0 {
  @include responsive-heading($font-size-h0);
}

.responsive-heading-h1 {
  @include responsive-heading($font-size-h1);
}

.responsive-heading-h2 {
  @include responsive-heading($font-size-h2);
}

.responsive-heading-h3 {
  @include responsive-heading($font-size-h3);
}



// Icono pequeño Font-Awesome
.fa-sm {
  font-size: 0.75em;
  line-height: 1.25em;
  vertical-align: 5%;
}



// Título con borde punteado y flecha
.title-bordered-arrow {
  display: flex;
  margin: 0;
  padding: 10px 0;
  font-size: $font-size-h4;
  border-top: 1px dotted $gris-oscuro;
  border-bottom: 1px dotted $gris-oscuro;

  b {
    color: $brand-primary;
    margin-right: 0.25em;
    line-height: 1;
  }

  .text-uppercase {
    position: relative;
    top: 1px;
  }
}
