/* ==========================================================================
   Base
   ========================================================================== */


html {
  -ms-overflow-style: scrollbar;
}

body {
  position: relative;
  overflow-x: hidden;
  overflow-y: scroll;

  // scrollreveal
  &.scrollreveal {

    .reveal {
      visibility: hidden;
    }
  }

  &.scrollreveal.ie {

    .reveal {
      visibility: visible;
    }
  }
}


// Browser alert
.alert-browser {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 9999;
  margin: 0;
  padding: 30px 0;
  border-radius: 0;
  text-align: center;
  font-size: $font-size-large;
  font-weight: 800;
  color: $gris-oscuro;

  strong, b {
    font-weight: 900;
  }

  a {
    color: $gris-oscuro;
    text-decoration: underline;

    &:hover,
    &:focus {
      color: #000;
    }
  }

  &.alert-warning .close {
    margin: -25px 10px 0 0;
    text-decoration: none;
    color: $gris-oscuro;

    &:hover,
    &:focus {
      color: #000;
    }
  }

  // @media screen and (max-width: $screen-xs-max) {

  //   .scrolled-mobile & {
  //     display: none;
  //   }
  // }

  // @media screen and (min-width: $screen-sm-min) {

  //   .scrolled & {
  //     display: none;
  //   }
  // }
}
