//
// Buttons
// --------------------------------------------------


// Mixins
// --------------------------------------------------

// Button variant full (no hover effect default)
@mixin button-variant-full(
  $color,
  $background,
  $border,
  $color-hover: $color,
  $background-hover: $background,
  $border-hover: $border
) {
  color: $color;
  background-color: $background;
  border-color: $border;

  &:focus,
  &.focus {
    color: $color-hover;
    background-color: $background-hover;
    border-color: $border-hover;
  }

  &:hover {
    color: $color-hover;
    background-color: $background-hover;
    border-color: $border-hover;
  }

  &:active,
  &.active,
  .open > &.dropdown-toggle {
    color: $color-hover;
    background-color: $background-hover;
    border-color: $border-hover;
    box-shadow: none;

    &:hover,
    &:focus,
    &.focus {
      color: $color-hover;
      background-color: $background-hover;
      border-color: $border-hover;
    }
  }

  &:active,
  &.active,
  .open > &.dropdown-toggle {
    background-image: none;
  }

  &.disabled,
  &[disabled],
  fieldset[disabled] & {

    &:hover,
    &:focus,
    &.focus {
      background-color: $background;
      border-color: $border;
    }
  }

  .badge {
    color: $background;
    background-color: $color;
  }
}


// No border button
@mixin btn-no-border() {
  border-color: transparent;
}
.btn-no-border {
  @include btn-no-border();
}


// Hover 50% opacity
@mixin opacity-hover($opacity: 0.5) {
  opacity: 1;
  transition: 200ms opacity ease-in-out;

  &:hover,
  &:focus {
    opacity: $opacity;
  }
}
.opacity-hover {
  @include opacity-hover();
}


// Base styles
// --------------------------------------------------

.btn {
  position: relative;
  outline: 0;
  text-transform: uppercase;
  transition: all 200ms ease-in-out;

  &:hover,
  &:focus,
  &.active,
  &:active
  &.active:focus,
  &:active:focus {
    outline: 0;
    box-shadow: none;
  }

  &::-moz-focus-inner {
    border: 0;
  }

  &.disabled,
  &[disabled],
  fieldset[disabled] & {
    @include opacity(.5);

    // no animar iconos
    &:hover,
    &:focus {

      .fa {
        transform: none;
      }
    }
  }

  &.text-left {
    text-align: left;
  }

  &.text-right {
    text-align: right;
  }

  &.text-center {
    text-align: center;
  }
}


// Multiline button
.btn-multiline {
  white-space: normal;
}


// Button Sizes
// --------------------------------------------------

.btn,
.btn-md { // 40px
  border-width: $border-width-base;                 // border width

  @include button-size(
    $padding-base-vertical - $border-width-base,    // padding vertical
    $padding-base-horizontal - $border-width-base,  // padding horizontal
    $font-size-base,                                // font-size
    $line-height-computed,                          // line-height (20px)
    $btn-border-radius-base                         // border-radius
  );
}

.btn-xs { // 20px
  border-width: $border-width-xs;

  @include button-size(
    $padding-xs-vertical - $border-width-xs + 3,
    $padding-xs-horizontal - $border-width-xs,
    $font-size-xsmall,
    $font-size-xsmall + 2,
    $btn-border-radius-small
  );
}

.btn-sm { // 30px
  border-width: $border-width-small;

  @include button-size(
    $padding-small-vertical - $border-width-small + 3,
    $padding-small-horizontal - $border-width-small,
    $font-size-small,
    $font-size-small + 2,
    $btn-border-radius-small
  );
}

.btn-lg { // 50px
  border-width: $border-width-large;

  @include button-size(
    $padding-large-vertical - $border-width-large - 2,
    $padding-large-horizontal - $border-width-large,
    $font-size-large,
    $font-size-large + 4,
    $btn-border-radius-large
  );
}

.btn-xl { // 60px
  border-width: $border-width-xl;

  @include button-size(
    $padding-xl-vertical - $border-width-xl - 2,
    $padding-xl-horizontal - $border-width-xl,
    $font-size-large,
    $font-size-large + 4,
    $btn-border-radius-xl
  );
}




// Icon styles
// Ajustes individiales de iconos en botones
// --------------------------------------------------

.btn,
.btn-md {

  // .fa-angle-right {
  //   position: relative;
  //   top: 1px;
  //   float: right;
  //   margin-left: $padding-base-horizontal;
  //   transition: all 200ms ease-in-out;
  // }
}

.btn-xs {

  // .fa-angle-right {
  //   top: 3px;
  //   margin-left: $padding-xs-horizontal * 2;
  // }
}

.btn-sm {

  // .fa-angle-right {
  //   top: 3px;
  //   margin-left: $padding-small-horizontal;
  // }
}

.btn-lg {

  // .fa-angle-right {
  //   top: -1px;
  //   margin-left: $padding-large-horizontal;
  // }
}

.btn-xl {

  // .fa-angle-right {
  //   top: 1px;
  //   margin-left: $padding-xl-horizontal;
  // }
}

.btn {

  &:hover,
  &:focus {

    // .fa-angle-right {
    //   transform: translateX(100%);
    // }
  }
}


// Responsive button
.btn-responsive-xl {

  @media screen and (min-width: $screen-sm-min) {
    border-width: $border-width-large;

    @include button-size(
      $padding-large-vertical - $border-width-large,
      $padding-large-horizontal - $border-width-large,
      $font-size-large,
      $line-height-computed,
      $btn-border-radius-large
    );
  }

  @media screen and (min-width: $screen-md-min) {
    // @
  }

  @media screen and (min-width: $screen-lg-min) {
    border-width: $border-width-xl;

    @include button-size(
      $padding-xl-vertical - $border-width-xl - 2,
      $padding-xl-horizontal - $border-width-xl,
      $font-size-large,
      $font-size-large + 4,
      $btn-border-radius-xl
    );
  }

  @media screen and (min-width: $screen-xl-min) {
    // @
  }
}




// Botones personalizados
// --------------------------------------------------

// .btn-default {
//   @include button-variant-full(
//     $btn-default-color,
//     $btn-default-bg,
//     $btn-default-border
//   );
// }

// .btn-primary {
//   @include button-variant-full(
//     $btn-primary-color,
//     $btn-primary-bg,
//     $btn-primary-border
//   );
// }

// .btn-success {
//   @include button-variant-full(
//     $btn-success-color,
//     $btn-success-bg,
//     $btn-success-border
//   );
// }

// .btn-info {
//   @include button-variant-full(
//     $btn-info-color,
//     $btn-info-bg,
//     $btn-info-border
//   );
// }

// .btn-warning {
//   @include button-variant-full(
//     $btn-warning-color,
//     $btn-warning-bg,
//     $btn-warning-border
//   );
// }

// .btn-danger {
//   @include button-variant-full(
//     $btn-danger-color,
//     $btn-danger-bg,
//     $btn-danger-border
//   );
// }

.btn-gray {
  @include button-variant-full(
    $text-color,
    $gris-claro,
    $gris-claro,
    #000,
    $gris,
    $gris
  );
}

.btn-search {
  @include button-variant-full(
    $text-color,
    $gris-claro,
    $gris-borde,
    #fff,
    $gray-dark,
    $gray-dark
  );
}

.btn-primary-white {
  @include button-variant-full(
    $btn-primary-color,  // color
    $btn-primary-bg,     // background-color
    $btn-primary-border, // border-color
    #000,              // hover color
    #FFF,              // hover background-color
    $brand-primary       // hover border-color
  );
}

.btn-primary-white-darker {
  @include button-variant-full(
    $btn-primary-color,
    darken($btn-primary-bg, 5%),
    darken($btn-primary-border, 5%),
    #000,
    #FFF,
    $brand-primary
  );
}

.btn-white-primary {
  @include button-variant-full(
    #000,
    #FFF,
    #FFF,
    $btn-primary-color,
    $btn-primary-bg,
    $btn-primary-border
  );
}

.btn-success-white {
  @include button-variant-full(
    $btn-success-color,
    $btn-success-bg,
    $btn-success-border,
    $brand-success,
    #FFF,
    $brand-success
  );
}

.btn-file-upload {
  @include button-variant-full(
    $gris-medio,
    #fff,
    $gris-borde,
    #fff,
    $gris-oscuro,
    $gris-oscuro
  );
}
